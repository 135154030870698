import * as actions from "../../../store/actions";
import ErrorAlert from '../../common/ErrorAlert';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { Button, ButtonGroup, Spinner} from 'reactstrap';
import { connect } from "react-redux";
import { isEmpty } from 'lodash';
import { MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentSatisfied } from 'react-icons/md';

class Rate extends Component {

  state = {
    rating: null,
    hover: false
  };

  componentDidMount() {
    this.props.submissionsRatingsList(this.props.submissionId);
  }

  ratingChange = (rating) => {
    this.props.submissionsRatingsPost(this.props.submissionId, rating, this.props.successCallback);
  };

  render() {

    let items = null;

    if (this.props.list.loading === true || this.props.post.loading === true) {
      items = <Spinner size="sm" color="primary"/>
    } else if (this.props.list.error || this.props.post.error) {
      items = <ErrorAlert error={this.props.list.error}/>;
    } else {
      const rating = isEmpty(this.props.list.data) ? 0 : this.props.list.data[0].attributes.rating;

      items = (
        <ButtonGroup>
          <Button color="success" outline onClick={() => this.ratingChange(3)}
                  active={rating === 3}><MdSentimentSatisfied/></Button>
          <Button color="warning" outline  onClick={() => this.ratingChange(2)}
                  active={rating === 2}><MdSentimentNeutral/></Button>
          <Button color="info" outline  onClick={() => this.ratingChange(4)}
                  active={rating === 4}><MdSentimentNeutral/></Button>
          <Button color="danger" outline  onClick={() => this.ratingChange(1)}
                  active={rating === 1}><MdSentimentDissatisfied/></Button>
        </ButtonGroup>
      );

    }

    return (
      <section>
        {items}
      </section>
    );
  }
}

Rate.propTypes = {
  submissionId: PropTypes.number.isRequired,
  successCallback: PropTypes.func
};

const mapStateToProps = state => {
  return {
    list: {
      data: state.submissionsRatingsList.data,
      error: state.submissionsRatingsList.error,
      loading: state.submissionsRatingsList.loading
    },
    post: {
      data: state.submissionsRatingsPost.data,
      error: state.submissionsRatingsPost.error,
      loading: state.submissionsRatingsPost.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submissionsRatingsList: (submissionId) => dispatch(actions.submissionsRatingsList(submissionId, {userId: -1})),
    submissionsRatingsPost: (submissionId, rating, successCallback) => dispatch(actions.submissionsRatingsPost(submissionId, {rating: rating}, {}, successCallback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rate);


import { orderBy as lodashOrderBy } from 'lodash';

export const slugify = (string) => {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with ‘and’
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
};

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
};

export const orderBy = (items, orderByFields, orderByDirection) => {

  // if field does not start with "attributes." then add it.
  orderByFields = orderByFields.map(field => field.substr(0,11) !== 'attributes.' ? `attributes.${field}` : field);
  // we create an array of directions with the same length as the array of order by fields.
  // we don't care about having different directions per field, at least not yet.
  const orderByDirections = orderByFields.map(() => orderByDirection);

  const fields = orderByFields.map((field, index) => {
    // 'a.b.etc'.split('.').reduce((o,i)=>o[i], obj)
    // convert "attributes.user.firstName" syntax to actual object
    // essentially we are creating this: (item) => item.attributes.user.firstName.toLowerCase();
    return (item) => {
      let sortBy = '';
      try {
        sortBy = field.split('.').reduce((o, i) => o[i], item);
      } catch (e) {
        sortBy = 'unknown';
      }
      if (typeof sortBy === 'string') {
        sortBy = sortBy.toLowerCase();
      }
      return sortBy;
    }
  });
  //console.log('Directions: ', orderByDirections, ' Sort By: ', orderByFields);
  items = lodashOrderBy(items, fields, orderByDirections);
  return items;
};

export const filter = (items, filterBy) => {
  return items.filter((item) => {
    if (item === null) {
      return true;
    }
    // since most of our data is stored in an attributes object...
    if (typeof item.attributes === 'undefined') {
      item = JSON.stringify(item);
    } else {
      item = JSON.stringify(item.attributes);
    }
    //console.log(String(item).toLowerCase().indexOf(filterBy));
    return String(item).toLowerCase().indexOf(filterBy) !== -1;
  });
};

export const dataTypes = {
  'tag': 'Structured Tag',
  'company-profile-address-autocomplete': 'Company Profile - Address (Autocomplete)',
  'company-profile-name': 'Company Profile - Name (Autocomplete)',
  'company-profile': 'Company Profile - Other',
  'company-meta': 'Company Meta Data',
  'person-profile': 'Person Profile',
  'traction-employment': 'Traction - Employment',
  'traction-investment': 'Traction - Investment',
  'traction-ip': 'Traction - IP',
  'traction-revenue': 'Traction - Revenue',
  'traction-stage': 'Traction - Stage',
  'cdmn': 'CDMN',
  'passportProgram': 'Passport Program'
};


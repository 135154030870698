import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Spinner, Button } from 'reactstrap';
import * as actions from '../../store/actions';
import ConfirmModal from '../common/ConfirmModal';
import BucketProfileEdit from './BucketProfileEdit';
import ErrorAlert from '../common/ErrorAlert';
import BucketProfileListItem from './BucketProfileListItem';

class BucketProfileList extends Component
{
  state = {
    showEditBucketProfileModal: false,
    showDeleteBucketProfileModal: false,
    bucketProfileToDelete: {},
    bucketProfileToEdit: {},
    buckets: {}
  };

  componentDidMount() {
    this.props.bucketProfilesList();
    this.props.getBucketList();
  }

  toggleEditBucketProfileModal = (bucketProfile) => {
    if (typeof bucketProfile === 'undefined') {
      bucketProfile = {};
    }
    //console.log('BucketProfile',bucket);
    this.setState((prevState) => ({
      showEditBucketProfileModal: !prevState.showEditBucketProfileModal,
      bucketProfileToEdit: bucketProfile
    }));
  };

  toggleDeleteBucketProfileModal = (bucketProfile) => {
    //this.props.bucketProfilesDelete();
    this.setState((prevState) => ({
      showDeleteBucketProfileModal: !prevState.showDeleteBucketProfileModal,
      bucketProfileToDelete: bucketProfile
    }));
  };

  deleteBucketProfileConfirmHandler = () => {
    this.props.bucketProfilesDelete(this.state.bucketProfileToDelete.id, () => this.toggleDeleteBucketProfileModal());
  };

  saveHandler = (data, bucketProfileId) => {
    data = {
      label: data.label.value,
      bucketIds: data.bucketIds.value
    };
    if (bucketProfileId) {
      this.props.bucketProfilesPatch(bucketProfileId, data, () => this.toggleEditBucketProfileModal());
    } else {
      this.props.bucketProfilesPost(data, () => this.toggleEditBucketProfileModal());
    }
  };

  render() {

    let items = null;
    let buckets = [];

    // handle loading state
    if (this.props.list.loading === true) {
      items = <tr><td colSpan={7} className="text-center"><Spinner size="sm" color="primary"/></td></tr>;

    // handle error
    } else if (this.props.list.error) {
      items = <tr><td colSpan={7} className="text-center"><ErrorAlert error={this.props.error}/></td></tr>;

    // when we have data
    } else if (this.props.list.data.length > 0 && this.props.bucketList.data.length > 0) {

      Object.keys(this.props.bucketList.data).map((key, index) => {
        buckets['bucket-' + this.props.bucketList.data[key].id] = this.props.bucketList.data[key].attributes.labelPastTense;
        return null;
      });

      items = Object.keys(this.props.list.data).map((key, index) => {
          const item = this.props.list.data[key].attributes;
          return (
            <BucketProfileListItem buckets={buckets}
                                   bucketIds={item.bucketIds}
                                   created={item.created}
                                   key={item.id}
                                   label={item.label}
                                   id={item.id}
                                   index={index}
                                   modified={item.modified}
                                   toggleDeleteModalHandler={this.toggleDeleteBucketProfileModal}
                                   toggleEditModalHandler={this.toggleEditBucketProfileModal}
                                   user={item.user}/>
          );
        });

      if (items.length === 0) {
        items = <tr><td colSpan={7} className="text-center">No items found</td></tr>;
      }
    }

    return (
      <section>
        
        <h2>Bucket Profiles</h2>

        <div className="mt-3 mb-3">
          <Button color="primary"
                  onClick={() => this.toggleEditBucketProfileModal()}>Add Bucket Profile</Button>{' '}
        </div>

        <Table striped responsive>
          <thead>
            <tr>
              <th>Label</th>
              <th>Buckets</th>
              <th>Created</th>
              <th>Modified</th>
              <th>User</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {items}
          </tbody>
        </Table>
        {
          this.state.showEditBucketProfileModal
            ? <BucketProfileEdit bucketProfile={this.state.bucketProfileToEdit}
                          buckets={buckets}
                          closeHandler={this.toggleEditBucketProfileModal}
                          saveHandler={this.saveHandler}/>
            : null
        }
        {this.state.showDeleteBucketProfileModal ?
          <ConfirmModal title="Delete bucket?"
                        buttonConfirmLabel="Delete"
                        buttonConfirmColor="danger"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteBucketProfileConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteBucketProfileModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
            <span>
              Are you sure you want to delete the bucket profile <strong>{this.state.bucketProfileToDelete.label}</strong>?
            </span>
          </ConfirmModal> : null
        }

      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    bucketList: {
      data: state.bucketsList.data,
      error: state.bucketsList.error,
      loading: state.bucketsList.loading
    },
    delete: {
      data: state.bucketProfilesDelete.data,
      error: state.bucketProfilesDelete.error,
      loading: state.bucketProfilesDelete.loading
    },
    list: {
      data: state.bucketProfilesList.data,
      error: state.bucketProfilesList.error,
      loading: state.bucketProfilesList.loading
    },
    post: {
      data: state.bucketProfilesPost.data,
      error: state.bucketProfilesPost.error,
      loading: state.bucketProfilesPost.loading
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBucketList: () => dispatch(actions.bucketsList()),
    bucketProfilesList: () => dispatch(actions.bucketProfilesList()),
    bucketProfilesDelete: (bucketProfileId, successCallback) => dispatch(actions.bucketProfilesDelete(bucketProfileId, successCallback)),
    bucketProfilesPost: (data, successCallback) => dispatch(actions.bucketProfilesPost(data, successCallback)),
    bucketProfilesPatch: (bucketProfileId, data, successCallback) => dispatch(actions.bucketProfilesPatch(bucketProfileId, data, successCallback))
    //bucketProfilesDeleteClear: () => dispatch(actions.bucketsDeleteClear())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BucketProfileList);


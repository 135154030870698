import React, { Component } from 'react';
import Editable from '../../common/Editable';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import { Button, InputGroup, InputGroupAddon, Input, Badge, UncontrolledTooltip} from 'reactstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment';
import { isEmpty } from 'lodash';

class SurveyInfo extends Component
{
  state = {
    loading: {},
    survey: {}
  };

  componentDidMount() {
    if (isEmpty(this.props.lists.data)) {
      this.props.listsList();
    }
    this.props.getBucketProfileList();
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {...state};
    let loading = {};
    if (isEmpty(state.loading)) {
      Object.keys(props.survey).map(key => loading[key] = false);
      newState['loading'] = loading;
    }
    if (!isEmpty(props.patch.data)) {
      loading = {...state.loading};
      Object.keys(props.patch.request).map(key => loading[key] = false);
      newState['loading'] = loading;
    }
    newState['survey'] = props.survey;
    return newState;
  }

  save = (key, value) => {
    // update field in state
    const survey = {...this.state.survey};
    survey[key] = value;
    const loading = {...this.state.loading};
    loading[key] = true;

    this.setState({
      loading: loading,
      survey: survey
    });

    // send value to server
    this.props.patchSurvey(this.props.survey.id, {[key]: value})
    //console.log(key, value);
  };

  render() {

    const surveyOnly = this.state.survey.type === 'survey' ? (
      <React.Fragment>
        <dt className="col-md-3">Period Start</dt>
        <dd className="col-md-9">
          <Editable helpText="The period that you want your portfolio companies to report on"
                    onSave={(value) => this.save('periodStart', value)}
                    loading={this.state.loading['periodStart']}
                    type="date"
                    value={this.state.survey.periodStart !== null ? this.state.survey.periodStart : moment().format('YYYY-MM-DD')}/>
        </dd>
        <dt className="col-md-3">Period End</dt>
        <dd className="col-md-9">
          <Editable helpText="The period that you want your portfolio companies to report on"
                    onSave={(value) => this.save('periodEnd', value)}
                    loading={this.state.loading['periodEnd']}
                    type="date"
                    value={this.state.survey.periodEnd !== null ? this.state.survey.periodEnd : moment().format('YYYY-MM-DD')}/>
        </dd>
        <dt className="col-md-3">Sent Status</dt>
        {
          this.state.survey.sentFlag === true
            ? (
              <dd className="col-md-9">
                <Badge color="success">Sent</Badge>{' '}
                <span id="sentDate">{moment.utc(this.state.survey.sentDate).local().fromNow()}</span>
                <UncontrolledTooltip placement="right" target="sentDate">
                  {moment.utc(this.state.survey.sentDate).local().format('lll')}
                </UncontrolledTooltip>
              </dd>
            )
            : <dd className="col-md-9"><Badge>Not Sent</Badge></dd>
        }
        {/*<dt className="col-md-3">From Email Address</dt>*/}
        {/*<dd className="col-md-9">*/}
        {/*  <Editable helpText="The email address of the person this survey should appear to be sent by"*/}
        {/*            onSave={(value) => this.save('fromEmail', value)}*/}
        {/*            loading={this.state.loading['fromEmail']}*/}
        {/*            type="text"*/}
        {/*            value={this.state.survey.fromEmail}/>*/}
        {/*</dd>*/}
        {/*<dt className="col-md-3">From Name</dt>*/}
        {/*<dd className="col-md-9">*/}
        {/*  <Editable helpText="The name of the person this survey should appear to be sent by"*/}
        {/*            onSave={(value) => this.save('fromName', value)}*/}
        {/*            loading={this.state.loading['fromName']}*/}
        {/*            type="text"*/}
        {/*            value={this.state.survey.fromName}/>*/}
        {/*</dd>*/}
      </React.Fragment>
    ) : null;

    const bucketProfilesList = isEmpty(this.props.bucketProfiles.data) ? {} : this.props.bucketProfiles.data;
    let bucketProfiles = {};

    Object.keys(bucketProfilesList).map(key => {
      bucketProfiles[bucketProfilesList[key].id] = bucketProfilesList[key].attributes.label;
      return null;
    });

    const programList = isEmpty(this.props.lists.data) ? {} : this.props.lists.data.attributes.program;
    let programs = {};
    // filter program list if funding bucket is set to CDMN
    if (this.state.survey.fundingBucket === 'CDMN') {
      // filter out all programs that are not CDMN programs
      programs = Object.keys(programList)
        .filter(key => key === 'Soft Landing Program' || key === 'Passport Program')
        .reduce((res, key) => {
          res[key] = programList[key];
          return res;
        }, {});
    } else {
      // filter out CDMN programs
      programs = Object.keys(programList)
        .filter(key => key !== 'Soft Landing Program' && key !== 'Passport Program')
        .reduce((res, key) => {
          res[key] = programList[key];
          return res;
        }, {});
    }

    const intakeOnly = this.state.survey.type !== 'survey' ? (
      <React.Fragment>
        <dt className="col-md-3">Funding Bucket</dt>
        <dd className="col-md-9">
          <Editable helpText=""
                    onSave={(value) => this.save('fundingBucket', value)}
                    type="select"
                    options={isEmpty(this.props.lists.data) ? {} : this.props.lists.data.attributes.fundingBucket}
                    loading={this.state.loading['fundingBucket']}
                    value={this.state.survey.fundingBucket}/>
        </dd>
        <dt className="col-md-3">Primary Program</dt>
        <dd className="col-md-9">
          <Editable helpText=""
                    onSave={(value) => this.save('primaryProgram', value)}
                    type="select"
                    options={programs}
                    loading={this.state.loading['primaryProgram']}
                    value={this.state.survey.primaryProgram}/>
        </dd>
        <dt className="col-md-3">Cohort (CDMN Only)</dt>
        <dd className="col-md-9">
          <Editable
            helpText="Currently this is a CDMN only field"
            onSave={(value) => this.save('cohort', value)}
            loading={this.state.loading['cohort']}
            type="text"
            value={this.state.survey.cohort}/>
        </dd>
        <dt className="col-md-3">URL</dt>
        <dd className="col-md-9">
          <InputGroup>
            <Input defaultValue={this.state.survey.url} readOnly />
            <InputGroupAddon addonType="append">
              <CopyToClipboard text={this.state.survey.url}>
                <Button onClick={this.copyTextToClipboard} outline size="sm">Copy</Button>
              </CopyToClipboard>
            </InputGroupAddon>
          </InputGroup>
        </dd>
        <dt className="col-md-3">Status</dt>
        {
          this.state.survey.sentFlag === true
            ? (
              <dd className="col-md-9">
                <Badge color="success">Published</Badge>{' '}
                <span id="sentDate">{moment.utc(this.state.survey.sentDate).local().fromNow()}</span>
                <UncontrolledTooltip placement="right" target="sentDate">
                  {moment.utc(this.state.survey.sentDate).local().format('lll')}
                </UncontrolledTooltip>
              </dd>
            )
            : <dd className="col-md-9"><Badge>Not published (private)</Badge></dd>
        }
      </React.Fragment>
    ) : null;

    let surveyType;

    switch (this.state.survey.type) {
      case 'survey':
        surveyType = 'Company Survey';
        break;
      case 'intake':
        surveyType = 'Intake Survey';
        break;
      case 'request':
        surveyType = 'Request Form';
        break;
      default:
        surveyType = 'Unknown'
    }

    return (
      <section className="survey-info">
        <h2>Properties</h2>

        <dl className="row">
          <dt className="col-md-3">Name</dt>
          <dd className="col-md-9">
            <Editable
              helpText="What is your survey called? Users will see this at the top of the survey."
              onSave={(value) => this.save('name', value)}
              loading={this.state.loading['name']}
              type="text"
              value={this.state.survey.name}/>
          </dd>
          <dt className="col-md-3">Branding</dt>
          <dd className="col-md-9">
            <Editable helpText=""
                      onSave={(value) => this.save('branding', value)}
                      type="select"
                      options={{communitech: 'Communitech', cdmn: 'CDMN'}}
                      loading={this.state.loading['branding']}
                      value={this.state.survey.branding}/>
          </dd>
          <dt className="col-md-3">Bucket Profile</dt>
          <dd className="col-md-9">
            <Editable helpText=""
                      onSave={(value) => this.save('bucketProfileId', value)}
                      type="select"
                      options={bucketProfiles}
                      loading={this.state.loading['bucketProfileId']}
                      value={this.state.survey.bucketProfileId.toString()}/>
          </dd>
          <dt className="col-md-3">Expires</dt>
          <dd className="col-md-9">
            <Editable helpText="The survey will be unavailable after this date"
                      onSave={(value) => this.save('expires', value)}
                      loading={this.state.loading['expires']}
                      type="datetime"
                      value={this.state.survey.expires}/>
          </dd>
          {surveyOnly}
          {intakeOnly}
          <dt className="col-md-3">Message</dt>
          <dd className="col-md-9">

            <Editable
              displayValueIfEmpty="None"
              helpText=""
              onSave={(value) => this.save('message', value)}
              loading={this.state.loading['message']}
              type="wysiwyg"
              value={this.state.survey.message}
            />
            {this.state.survey.message ? <small className="text-muted">(text only version, click edit to see HTML preview)</small> : null}
          </dd>
          <dt className="col-md-3">Thank You Message</dt>
          <dd className="col-md-9">
            <Editable
              displayValueIfEmpty="None"
              helpText="This message will be displayed to a user upon a successful survey form submission."
              onSave={(value) => this.save('thankYouMessage', value)}
              loading={this.state.loading['thankYouMessage']}
              type="wysiwyg"
              value={this.state.survey.thankYouMessage}
            />
            {this.state.survey.thankYouMessage ? <small className="text-muted">(text only version, click edit to see HTML preview)</small> : null}
          </dd>
          <dt className="col-md-3">Notify</dt>
          <dd className="col-md-9">
            <Editable helpText="Comma separated list of email recipients who should be notified for every submission to this survey"
                      onSave={(value) => this.save('notify', value)}
                      loading={this.state.loading['notify']}
                      displayValueIfEmpty="None"
                      value={this.state.survey.notify}/>
          </dd>
          <dt className="col-md-3">Type</dt>
          <dd className="col-md-9">{surveyType}</dd>
          <dt className="col-md-3">Created by</dt>
          <dd className="col-md-9">{this.state.survey.user.firstName} {this.state.survey.user.lastName}</dd>
          <dt className="col-md-3">Last modified by</dt>
          <dd className="col-md-9">{this.state.survey.userModified.firstName} {this.state.survey.userModified.lastName}</dd>
          <dt className="col-md-3">Created</dt>
          <dd className="col-md-9">
            <span id="created">{moment.utc(this.state.survey.created).local().fromNow()}</span>
            <UncontrolledTooltip placement="right" target="created">
              {moment.utc(this.state.survey.created).local().format('lll')}
            </UncontrolledTooltip>
          </dd>
          <dt className="col-md-3">Modified</dt>
          <dd className="col-md-9">
            <span id="modified">{moment.utc(this.state.survey.modified).local().fromNow()}</span>
            <UncontrolledTooltip placement="right" target="modified">
              {moment.utc(this.state.survey.modified).local().format('lll')}
            </UncontrolledTooltip>
          </dd>
        </dl>

      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    bucketProfiles: {
      data: state.bucketProfilesList.data,
      error: state.bucketProfilesList.error,
      loading: state.bucketProfilesList.loading
    },
    lists: {
      data: state.listsList.data,
      error: state.listsList.error,
      loading: state.listsList.loading
    },
    patch: {
      data: state.surveysPatch.data,
      request: state.surveysPatch.request,
      error: state.surveysPatch.error,
      loading: state.surveysPatch.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    patchSurvey: (surveyId, data) => dispatch(actions.surveysPatch(surveyId, data)),
    listsList: () => dispatch(actions.listsList()),
    getBucketProfileList: () => dispatch(actions.bucketProfilesList()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SurveyInfo);

import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (authenticated, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        authenticated: authenticated,
        userId: userId
    };
};

export const authFailure = (error) => {
    return {
        type: actionTypes.AUTH_FAILURE,
        error: 'Invalid email or password'
    };
};

export const authSignOut = () => {
    // this needs to make an api call
    return {
        type: actionTypes.AUTH_SIGN_OUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(authSignOut());
        }, expirationTime * 1000);
    };
};

export const auth = (data) => {
    return dispatch => {
        dispatch(authStart());

        axios.post('/auth', {
            data: {
                attributes: {
                    email: data.email,
                    password: data.password,
                }
            }
        })
            .then(response => {
                dispatch(authSuccess(
                  response.data.data.attributes.authenticated,
                  response.data.data.attributes.userId
                ));
                //dispatch(checkAuthTimeout(response.data.expiresIn));
            })
            .catch(error => {

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);

                dispatch(authFailure(error));
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(authSignOut());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(authSignOut());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    };
};

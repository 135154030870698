import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const usersDelete = (userId) => {
  return dispatch => {
    dispatch(usersDeleteStart());

    axios.delete('/users/' + userId)
      .then(response => {
        dispatch(usersDeleteSuccess(response.data.data));
        // since patch returns a complete users object we may as well update the ui
        dispatch(usersList());
      })
      .catch(error => {
        console.error('usersDeleteFailure', error);
        dispatch(usersDeleteFailure(error));
      });
  };
};

export const usersDeleteStart = () => {
  return {
    type: actionTypes.USERS_DELETE_START
  };
};

export const usersDeleteSuccess = (data) => {
  return {
    type: actionTypes.USERS_DELETE_SUCCESS,
    data: data
  };
};

export const usersDeleteFailure = (error) => {
  return {
    type: actionTypes.USERS_DELETE_FAILURE,
    error: error
  };
};

export const usersList = () => {
  return dispatch => {
    dispatch(usersListStart());

    axios.get('/users')
      .then(response => {
        dispatch(usersListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('usersListFailure', error);
        dispatch(usersListFailure(error));
      });
  };
};

export const usersListStart = () => {
  return {
    type: actionTypes.USERS_LIST_START
  };
};

export const usersListSuccess = (data) => {
  return {
    type: actionTypes.USERS_LIST_SUCCESS,
    data: data
  };
};

export const usersListFailure = (error) => {
  return {
    type: actionTypes.USERS_LIST_FAILURE,
    error: error
  };
};

export const usersPost = (data) => {
  return dispatch => {
    dispatch(usersPostStart());
    
    // put data into correct format
    data = {
      data: {
        type: 'user',
        id: null,
        attributes: data
      }
    };
    
    axios.post('/users', data)
      .then(response => {
        dispatch(usersPostSuccess(response.data.data));
        // since patch returns a complete users object we may as well update the ui
        dispatch(usersList());
      })
      .catch(error => {
        console.error('usersPostFailure', error);
        dispatch(usersPostFailure(error));
      });
  };
};

export const usersPostStart = () => {
  return {
    type: actionTypes.USERS_POST_START
  };
};

export const usersPostSuccess = (data) => {
  return {
    type: actionTypes.USERS_POST_SUCCESS,
    data: data
  };
};

export const usersPostFailure = (error) => {
  return {
    type: actionTypes.USERS_POST_FAILURE,
    error: error
  };
};


import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    console.log('User is' + (rest.isAuthenticated ? '' : ' NOT' ) + ' authenticated with manage-api.');
    return (
      rest.isAuthenticated === true
        ? <Component {...props} />
        : <Redirect
            to={{
              pathname: "/auth",
              state: { from: props.location }
            }}
          />
      )
  }} />
);

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.authenticated
  };
};

export default connect(mapStateToProps)(PrivateRoute);

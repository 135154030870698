import React from 'react';
import moment from 'moment';
import { Container } from 'reactstrap';

const footer = (props) => {
  return (
    <footer>
      <br/>
      <Container className="mt-3">
        <p className="text-muted text-center">
          <small>&copy; {moment().format('YYYY')} Communitech Corporation. All rights reserved.</small>
          <br/><small>version: {process.env.REACT_APP_CURRENT_GIT_SHA}</small>
        </p>
      </Container>
    </footer>
  );
};

export default footer;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketProfilesPatchStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketProfilesPatchSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketProfilesPatchFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKET_PROFILES_PATCH_START: return bucketProfilesPatchStart(state, action);
    case actionTypes.BUCKET_PROFILES_PATCH_SUCCESS: return bucketProfilesPatchSuccess(state, action);
    case actionTypes.BUCKET_PROFILES_PATCH_FAILURE: return bucketProfilesPatchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import {Button, ButtonGroup, Spinner } from 'reactstrap';
import ErrorAlert from '../../common/ErrorAlert';
import { isEmpty } from 'lodash';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdSentimentDissatisfied, MdSentimentNeutral, MdSentimentSatisfied } from 'react-icons/md';

class Rating extends Component {

  state = {
    hideRatings: true
  };

  componentDidMount() {
    //console.log('RATINGS', this.props.ratings);
    // if we did receive ratings via a property then DO NOT query API
    if (this.props.ratings !== null) {
      return;
    }

    // if the submission data holder is empty or the submission id is different then the submissionId
    // property, query API
    if (isEmpty(this.props.submission.data) || this.props.submission.data.id !== this.props.submissionId) {
      this.props.submissionsGet(this.props.submissionId);
    }
  }

  toggleRatings = () => {
    this.setState((prevState) => ({
      hideRatings: !prevState.hideRatings
    }));
  };

  render() {

    let items = null;

    if (this.props.submission.loading === true) {
      items = <Spinner size="sm" color="primary"/>
    } else if (this.props.submission.error) {
      items = <ErrorAlert error={this.props.submission.error}/>;
    // do we have rating data via a property or via an api call?
    } else if (this.props.ratings !== null || !isEmpty(this.props.submission.data)) {

      // pull rating data from correct source
      const ratings = this.props.ratings === null ? this.props.submission.data.attributes.ratings : this.props.ratings;

      if ((this.props.allowToggle && this.state.hideRatings === false) || this.props.allowToggle === false) {
        items = (
          <div>
            <ButtonGroup>
              <Button disabled={true} color="success">{this.props.minimal ? null : <MdSentimentSatisfied/>} {ratings.level3}</Button>
              <Button disabled={true} color="warning">{this.props.minimal ? null : <MdSentimentNeutral/>} {ratings.level2}</Button>
              <Button disabled={true} color="info">{this.props.minimal ? null : <MdSentimentNeutral/>} {ratings.level4}</Button>
              <Button disabled={true} color="danger">{this.props.minimal ? null : <MdSentimentDissatisfied/>} {ratings.level1}</Button>
            </ButtonGroup>{' '}
            {this.props.allowToggle ? <Button size="sm" color="secondary" outline onClick={this.toggleRatings}>Hide</Button> : null}
          </div>
        );
      } else if (this.props.allowToggle && this.state.hideRatings === true) {
        items = <Button size="sm" color="secondary" outline onClick={this.toggleRatings}>Show</Button>
      }
    }

    return (
      <section>
        {items}
      </section>
    );
  }
}

Rating.propTypes = {
  allowToggle: PropTypes.bool,
  submissionId: PropTypes.number.isRequired,
  ratings: PropTypes.object,
  minimal: PropTypes.bool

};

Rating.defaultProps = {
  allowToggle: true,
  ratings: null,
  minimal: false
};

const mapStateToProps = state => {
  return {
    submission: {
      data: state.submissionsGet.data,
      error: state.submissionsGet.error,
      loading: state.submissionsGet.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submissionsGet: (submissionId) => dispatch(actions.submissionsGet(submissionId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rating);

import React, { Component } from 'react';
import { Spinner, Table } from 'reactstrap';
import BucketRow from './BucketRow';
import ErrorAlert from '../../common/ErrorAlert';
import { isEmpty, cloneDeep, isEqual } from 'lodash';
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { orderBy, filter } from '../../../utility';
import TableHeadingColumn from '../../common/TableHeadingColumn';

class Bucket extends Component {

  state = {
    orderBy: ['created'],
    orderByDirection: 'desc'
  };

  componentDidMount() {
    this.props.submissionsList(this.props.surveyId, this.props.bucket);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.bucket !== prevProps.bucket) {
      this.props.submissionsList(this.props.surveyId, this.props.bucket);
    }
  }

  orderBy = (fields) => {
    // if we are already sorting on this field
    if (isEqual(this.state.orderBy, fields)) {
      // reverse direction
      const newDirection = this.state.orderByDirection === 'asc' ? 'desc' : 'asc';
      this.setState({orderByDirection: newDirection});
    } else {
      // new sort fields
      this.setState({orderBy: fields});
    }
  };



  render() {

    let items = null;

    if (this.props.list.loading === true) {
      items = <tr><td colSpan={6} className="text-center"><Spinner size="sm" color="primary"/></td></tr>;

      // handle error
    } else if (this.props.list.error) {
      items = <tr><td colSpan={6}><ErrorAlert error={this.props.list.error}/></td></tr>;

      // when we have data
    } else if (!isEmpty(this.props.list.data)) {
      items = cloneDeep(this.props.list.data);
      items = filter(items, this.props.filter);
      items = orderBy(items, this.state.orderBy, this.state.orderByDirection);
      items = Object.keys(items).map(key => {
        return <BucketRow bulkSelection={this.props.bulkSelection}
                          bulkSelectChangeHandler={this.props.bulkSelectChangeHandler}
                          key={key}
                          hideRatings={this.props.hideRatings}
                          submission={items[key].attributes}
                          surveyId={this.props.surveyId}/>;
      });
      if (items.length === 0) {
        items = <tr><td colSpan={5} className="text-center">No items found</td></tr>;
      }
    } else if (isEmpty(this.props.list.data)) {
      items = <tr><td colSpan={5} className="text-center">This submission bucket is empty</td></tr>
    }


    return (
      <Table striped responsive>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <TableHeadingColumn orderBy={['communitechCompany.name']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Company Name</TableHeadingColumn>
            <TableHeadingColumn orderBy={['created']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Submission Date</TableHeadingColumn>
            <TableHeadingColumn orderBy={['url']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>URL</TableHeadingColumn>
            {
              this.props.hideRatings
              ? null
              : <TableHeadingColumn orderBy={['ratings.level3','ratings.level2','ratings.level1','ratings.level0']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Rating</TableHeadingColumn>
            }
            <TableHeadingColumn orderBy={['decisionDate']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Decision Date</TableHeadingColumn>
            <TableHeadingColumn orderBy={['decisionReason']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Reason</TableHeadingColumn>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    );
  }
}

Bucket.propTypes = {
  surveyId: PropTypes.number.isRequired,
  bucket: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  filterHandler: PropTypes.func.isRequired,
  hideRatings: PropTypes.bool
};

Bucket.defaultProps = {
  hideRatings: true
};

const mapStateToProps = state => {
  return {
    list: {
      data: state.submissionsList.data,
      error: state.submissionsList.error,
      loading: state.submissionsList.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submissionsList: (surveyId, bucket) => dispatch(actions.submissionsList({
      surveyId: surveyId,
      bucket: bucket
    }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bucket);


import React from 'react';
import PropTypes from 'prop-types';
import OrderByIcon from './OrderByIcon';

const tableHeadingColumn = (props) => {
  return (
    <th style={{whiteSpace: 'nowrap'}} className="sortable" onClick={() => props.orderByHandler(props.orderBy)}>
      {props.children}{' '}
      <OrderByIcon orderBy={props.orderBy}
                   currentOrderBy={props.currentOrderBy}
                   currentOrderByDirection={props.currentOrderByDirection}/>
    </th>
  );
};

tableHeadingColumn.propTypes = {
  currentOrderBy: PropTypes.array.isRequired,
  currentOrderByDirection: PropTypes.string.isRequired,
  orderBy: PropTypes.array.isRequired,
  orderByHandler: PropTypes.func.isRequired
};

export default tableHeadingColumn;
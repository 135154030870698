import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';

const mergeTagsHelp = props => {
  // const id  = (new Date()).getTime();
  const id  = uniqueId('merge-tags-help-text-');
  return (
    <React.Fragment>
      <span id={id} style={{textDecoration: "underline", color:"blue"}}>We support merge tags.</span>
      <UncontrolledTooltip target={id}>
        You can use the following codes to automatically insert some survey data into your forms.<br/>
        [SURVEY_PERIOD_START], [SURVEY_PERIOD_END]<br/>
        When the form is displayed the codes will be replaced with the appropriate value.
      </UncontrolledTooltip>
    </React.Fragment>
  )
};

export default mergeTagsHelp;
import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const backButton = (props) => {
  return (
    <Button outline className="mb-3" onClick={props.goBack}>Back</Button>
  )
};

backButton.propTypes = {
  goBack: PropTypes.func.isRequired
};

export default backButton;
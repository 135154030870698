import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const submissionsResponsesList = (submissionId, params) => {
  return dispatch => {
    dispatch(submissionsResponsesListStart());

    axios.get(`/submissions/${submissionId}/responses`, {params: params})
      .then(response => {
        dispatch(submissionsResponsesListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('submissionsResponsesListFailure', error);
        dispatch(submissionsResponsesListFailure(error));
      });
  };
};

export const submissionsResponsesListStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_RESPONSES_LIST_START
  };
};

export const submissionsResponsesListSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_RESPONSES_LIST_SUCCESS,
    data: data
  };
};

export const submissionsResponsesListFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_RESPONSES_LIST_FAILURE,
    error: error
  };
};



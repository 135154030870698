import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Spinner, Button } from 'reactstrap';
import * as actions from '../../store/actions';
import ConfirmModal from '../common/ConfirmModal';
import BucketEdit from './BucketEdit';
import ErrorAlert from '../common/ErrorAlert';
import {DragDropContext} from 'react-beautiful-dnd';
import Column from './Column';

class BucketList extends Component
{
  state = {
    showEditBucketModal: false,
    showDeleteBucketModal: false,
    bucketToDelete: {},
    bucketToEdit: {},
    bucketsOrder: [],
    buckets: {}
  };

  componentDidMount() {
    this.props.bucketsList();
  }

  onDragEnd = result => {
    const { source, destination, draggableId } = result;

    // abort if no destination
    if (!destination) {
      return;
    }

    // did the draggable actually move?
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    //console.log(result);

    const bucketsOrder = [...this.state.bucketsOrder];
    //console.log('FIELDS OLD ORDER', this.state.fieldsOrder);

    bucketsOrder.splice(source.index, 1);
    bucketsOrder.splice(destination.index, 0, draggableId);
    //console.log('FIELDS NEW ORDER', fieldsOrder);

    this.props.bucketsActionsPost({
      action: 'reorder',
      bucketsOrder: bucketsOrder
    });

    this.setState({bucketsOrder: bucketsOrder});

  };

  /**
   * Props are immutable, we don't want to modify props so we assign to state
   * @param props
   * @param state
   * @returns {null|{buckets: (...*[]|*)}}
   */
  static getDerivedStateFromProps(props, state) {
    //console.log('FieldList.getDerivedStateFromProps()');
    // if there are no buckets set in state and we have received bucket data then
    // populate state.fields array with props.field
    if (props.list.data.length > 0) {
      //console.log('getDerivedStateFromProps');
      // change field array to be an object indexed by id

      let bucketsOrder = [...state.bucketsOrder];
      // create a bucketsOrder state if one does not exist or has changed
      if (state.bucketsOrder.length === 0 || state.bucketsOrder.length !== props.list.data.length) {
        bucketsOrder = props.list.data.map(bucket => 'bucket-' + bucket.id);
      }

      // convert array to hash map object
      const buckets = props.list.data.reduce(function(map, obj) {
        map['bucket-' + obj.id] = obj;
        return map;
      }, {});

      return {
        buckets: buckets,
        bucketsOrder: bucketsOrder
      };
    }
    return null;
  }

  toggleEditBucketModal = (bucket) => {
    if (typeof bucket === 'undefined') {
      bucket = {};
    }
    //console.log('Bucket',bucket);
    this.setState((prevState) => ({
      showEditBucketModal: !prevState.showEditBucketModal,
      bucketToEdit: bucket
    }));
  };

  toggleDeleteBucketModal = (bucket) => {
    this.props.bucketsDeleteClear();
    this.setState((prevState) => ({
      showDeleteBucketModal: !prevState.showDeleteBucketModal,
      bucketToDelete: bucket
    }));
  };

  deleteBucketConfirmHandler = () => {
    this.props.bucketsDelete(this.state.bucketToDelete.id, () => this.toggleDeleteBucketModal());
  };

  saveHandler = (data, bucketId) => {

    data = {
      description: data.description.value,
      name: data.name.value,
      labelPresentTense: data.labelPresentTense.value,
      labelPastTense: data.labelPastTense.value
    };
    if (bucketId) {
      this.props.bucketsPatch(bucketId, data, () => this.toggleEditBucketModal());
    } else {
      this.props.bucketsPost(data, () => this.toggleEditBucketModal());
    }
  };

  render() {

    let items = null;

    // handle loading state
    if (this.props.list.loading === true) {
      items = <tbody><tr><td colSpan={7} className="text-center"><Spinner size="sm" color="primary"/></td></tr></tbody>;

    // handle error
    } else if (this.props.list.error) {
      items = <tbody><tr><td colSpan={7} className="text-center"><ErrorAlert error={this.props.error}/></td></tr></tbody>;

    // when we have data
    } else if (this.props.list.data.length > 0) {
      //items = cloneDeep(this.props.list.data);
      //items[0].attributes.bucket.firstName = 'Jim';

      items = <DragDropContext onDragEnd={this.onDragEnd}>
                <Column buckets={this.state.buckets}
                        bucketsOrder={this.state.bucketsOrder}
                        toggleDeleteModalHandler={this.toggleDeleteBucketModal}
                        toggleEditModalHandler={this.toggleEditBucketModal}/>
              </DragDropContext>;

      if (items.length === 0) {
        items = <tbody><tr><td colSpan={7} className="text-center">No items found</td></tr></tbody>;
      }
    }

    return (
      <section>
        
        <h2>Bucket List</h2>
        <p>
          Below is a list of buckets that survey submissions can be assigned.
        </p>

        <div className="mt-3 mb-3">
          <Button color="primary"
                  onClick={() => this.toggleEditBucketModal()}
                  disabled={this.props.list.data.length >= 20}>Add Bucket</Button>{' '}
          <div className="float-right">
            You have {this.props.list.data.length} buckets. You can add {20-this.props.list.data.length} more.
          </div>
        </div>

        <Table striped responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Label Present Tense</th>
              <th>Label Past Tense</th>
              <th>Created</th>
              <th>Modified</th>
              <th>User</th>
              <th>Actions</th>
            </tr>
          </thead>
          {items}
        </Table>
        {
          this.state.showEditBucketModal
            ? <BucketEdit bucket={this.state.bucketToEdit}
                          closeHandler={this.toggleEditBucketModal}
                          saveHandler={this.saveHandler}/>
            : null
        }
        {this.state.showDeleteBucketModal ?
          <ConfirmModal title="Delete bucket?"
                        buttonConfirmLabel="Delete"
                        buttonConfirmColor="danger"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteBucketConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteBucketModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
            <span>
              Are you sure you want to delete the bucket <strong>{this.state.bucketToDelete.name}</strong>?
              <br/><br/>
              Note: You can only delete empty buckets.
            </span>
          </ConfirmModal> : null
        }

      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    delete: {
      data: state.bucketsDelete.data,
      error: state.bucketsDelete.error,
      loading: state.bucketsDelete.loading
    },
    list: {
      data: state.bucketsList.data,
      error: state.bucketsList.error,
      loading: state.bucketsList.loading
    },
    post: {
      data: state.bucketsPost.data,
      error: state.bucketsPost.error,
      loading: state.bucketsPost.loading
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    bucketsActionsPost: (data) => dispatch(actions.bucketsActionsPost(data)),
    bucketsList: () => dispatch(actions.bucketsList()),
    bucketsDelete: (bucketId, successCallback) => dispatch(actions.bucketsDelete(bucketId, successCallback)),
    bucketsPost: (data, successCallback) => dispatch(actions.bucketsPost(data, successCallback)),
    bucketsPatch: (bucketId, data, successCallback) => dispatch(actions.bucketsPatch(bucketId, data, successCallback)),
    bucketsDeleteClear: () => dispatch(actions.bucketsDeleteClear())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BucketList);


import React from 'react';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';

const preQuestionLabel = (props) => {
  return (
    <FormGroup>
      <Label for="preQuestionLabel">Pre-question <span className="text-muted">(optional)</span></Label>
      <Input id="preQuestionLabel"
             invalid={props.field.preQuestionLabel.touched && !props.field.preQuestionLabel.valid}
             onChange={(e) => props.changeHandler('preQuestionLabel', e.target.value)}
             placeholder="Do you have a website"
             type="text"
             valid={props.field.preQuestionLabel.touched && props.field.preQuestionLabel.valid}
             value={props.field.preQuestionLabel.value === null ? '' : props.field.preQuestionLabel.value}/>
      <FormText color="muted">
        Since this field is not required, you can use this field to ask the user a question before
        displaying the actual form field. This can help ensure users do not enter bad data in optional fields.
      </FormText>
    </FormGroup>
  );
};

preQuestionLabel.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired
};

export default preQuestionLabel;
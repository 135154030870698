import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const bucketsDelete = (bucketId, successCallback, params) => {
  return dispatch => {
    dispatch(bucketsDeleteStart());

    axios.delete(`/buckets/${bucketId}`, {params: params})
      .then(response => {
        dispatch(bucketsDeleteSuccess(response.data.data));
        dispatch(bucketsList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketsDeleteFailure', error);
        dispatch(bucketsDeleteFailure(error));
      });
  };
};

export const bucketsDeleteClear = () => {
  return {
    type: actionTypes.BUCKETS_DELETE_CLEAR
  };
};

export const bucketsDeleteStart = () => {
  return {
    type: actionTypes.BUCKETS_DELETE_START
  };
};

export const bucketsDeleteSuccess = (data) => {
  return {
    type: actionTypes.BUCKETS_DELETE_SUCCESS,
    data: data
  };
};

export const bucketsDeleteFailure = (error) => {
  return {
    type: actionTypes.BUCKETS_DELETE_FAILURE,
    error: error
  };
};


export const bucketsList = (params, successCallback) => {
  return dispatch => {
    dispatch(bucketsListStart());

    axios.get('/buckets', {params: params})
      .then(response => {
        dispatch(bucketsListSuccess(response.data.data));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketsListFailure', error);
        dispatch(bucketsListFailure(error));
      });
  };
};

export const bucketsListStart = () => {
  return {
    type: actionTypes.BUCKETS_LIST_START
  };
};

export const bucketsListSuccess = (data) => {
  return {
    type: actionTypes.BUCKETS_LIST_SUCCESS,
    data: data
  };
};

export const bucketsListFailure = (error) => {
  return {
    type: actionTypes.BUCKETS_LIST_FAILURE,
    error: error
  };
};

export const bucketsPatch = (bucketId, data, successCallback) => {
  return dispatch => {
    dispatch(bucketsPatchStart());
    
    data = {
      data: {
        attributes: data
      }
    };
    
    axios.patch(`/buckets/${bucketId}`, data)
      .then(response => {
        dispatch(bucketsPatchSuccess(response.data.data));
        dispatch(bucketsList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketsPatchFailure', error);
        dispatch(bucketsPatchFailure(error));
      });
  };
};

export const bucketsPatchStart = () => {
  return {
    type: actionTypes.BUCKETS_PATCH_START
  };
};

export const bucketsPatchSuccess = (data) => {
  return {
    type: actionTypes.BUCKETS_PATCH_SUCCESS,
    data: data
  };
};

export const bucketsPatchFailure = (error) => {
  return {
    type: actionTypes.BUCKETS_PATCH_FAILURE,
    error: error
  };
};

export const bucketsPost = (data, successCallback) => {
  return dispatch => {
    dispatch(bucketsPostStart());
    
    data = {
      data: {
        attributes: data
      }
    };
    
    axios.post(`/buckets`, data)
      .then(response => {
        dispatch(bucketsPostSuccess(response.data.data));
        dispatch(bucketsList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketsPatchFailure', error);
        dispatch(bucketsPostFailure(error));
      });
  };
};

export const bucketsPostStart = () => {
  return {
    type: actionTypes.BUCKETS_POST_START
  };
};

export const bucketsPostSuccess = (data) => {
  return {
    type: actionTypes.BUCKETS_POST_SUCCESS,
    data: data
  };
};

export const bucketsPostFailure = (error) => {
  return {
    type: actionTypes.BUCKETS_POST_FAILURE,
    error: error
  };
};



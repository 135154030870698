import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysGetStart = ( state, action ) => {
  // note: we also clear existing data
  return updateObject( state, { error: null, loading: true, data: {} } );
};

const surveysGetSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysGetFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_GET_START: return surveysGetStart(state, action);
    case actionTypes.SURVEYS_GET_SUCCESS: return surveysGetSuccess(state, action);
    case actionTypes.SURVEYS_GET_FAILURE: return surveysGetFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
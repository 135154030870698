import React, { Component } from 'react';
import Navigation from '../common/Navigation';
import { connect } from 'react-redux';
import { Container, Table, Spinner, Button, Badge, Input } from 'reactstrap';
import UserListItem from './UserListItem';
import * as actions from '../../store/actions';
import ConfirmModal from '../common/ConfirmModal';
import UserCreate from './UserCreate';
import ErrorAlert from '../common/ErrorAlert';
import { cloneDeep, isEqual } from 'lodash';
import TableHeadingColumn from '../common/TableHeadingColumn';
import { orderBy, filter } from '../../utility';
import Footer from '../common/Footer';

class UserList extends Component
{
  state = {
    filter: '',
    orderBy: ['lastName', 'firstName'],
    orderByDirection: 'asc',
    showCreateUserModal: false,
    showDeleteUserModal: false,
    userToDelete: {id: null, email: null},
    deleting: false,
    posting: false
  };

  componentDidMount() {
    this.props.usersList();
  }

  orderBy = (fields) => {
    // if we are already sorting on this field
    if (isEqual(this.state.orderBy, fields)) {
      // reverse direction
      const newDirection = this.state.orderByDirection === 'asc' ? 'desc' : 'asc';
      this.setState({orderByDirection: newDirection});
    } else {
      // new sort fields
      this.setState({orderBy: fields});
    }
  };

  toggleCreateUserModal = () => {
    this.setState((prevState) => ({
      showCreateUserModal: !prevState.showCreateUserModal
    }));
  };

  toggleDeleteUserModal = () => {
    this.setState((prevState) => ({
      showDeleteUserModal: !prevState.showDeleteUserModal
    }));
  };

  deleteUserHandler = (user) => {
    this.setState({
      userToDelete: user
    }, () => this.toggleDeleteUserModal());
  };

  deleteUserConfirmHandler = () => {
    this.props.usersDelete(this.state.userToDelete.id);
    this.setState({deleting: true});
    
  };

  saveHandler = (data) => {
    data = {email: data.email.value};
    this.props.usersPost(data);
    this.setState({posting: true});
  };

  static getDerivedStateFromProps(props, state) {

    // if we are deleting a record and we are no longer loading and no error then close modal
    if (state.deleting === true && props.delete.loading === false && props.delete.error === null) {
      const newState = {...state};
      newState.deleting = false;
      newState.showDeleteUserModal = false;
      return newState;
    }

    if (state.posting === true && props.post.loading === false && props.post.error === null) {
      const newState = {...state};
      newState.posting = false;
      newState.showCreateUserModal = false;
      return newState;
    }

    return null;
  }

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  render() {

    let items = null;

    // handle loading state
    if (this.props.list.loading === true) {
      items = <tr><td colSpan="7" className="text-center"><Spinner size="sm" color="primary"/></td></tr>;

    // handle error
    } else if (this.props.list.error) {
      items = <tr><td colSpan="7" className="text-center"><ErrorAlert error={this.props.error}/></td></tr>;

    // when we have data
    } else if (this.props.list.data.length > 0) {
      items = cloneDeep(this.props.list.data);
      //items[0].attributes.user.firstName = 'Jim';
      items = filter(items, this.state.filter);
      items = orderBy(items, this.state.orderBy, this.state.orderByDirection);

      items = items.map(item => {
        item = item.attributes;
        return (
          <UserListItem confirmDeleteHandler={this.deleteUserHandler}
                        created={item.created}
                        email={item.email}
                        firstName={item.firstName}
                        googleUserId={item.googleUserId}
                        id={item.id}
                        key={item.id}
                        lastName={item.lastName}
                        modified={item.modified}
                        user={item.user}/>
        )
      });

      if (items.length === 0) {
        items = <tr><td colSpan="7" className="text-center">No items found</td></tr>;
      }
    }

    return (
      <section>
        <Navigation/>
        <Container fluid>
          <h1>User List</h1>
          <p>
            Below is a list of users that are authorized to manage Communitech Forms.
            <br/>
            The <Badge color="success" size="sm">Linked</Badge> badge indicates whether or not a user has signed in
            to Communitech Forms with their Communitech Google Account.
          </p>

          <div className="mt-3 mb-3">
            <Button color="primary" onClick={this.toggleCreateUserModal}>Add User</Button>
            <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
          </div>
          
          <Table striped responsive>
            <thead>
              <tr>
                <TableHeadingColumn orderBy={['lastName','firstName']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Name</TableHeadingColumn>
                <TableHeadingColumn orderBy={['email']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Email</TableHeadingColumn>
                <TableHeadingColumn orderBy={['status']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Status</TableHeadingColumn>
                <TableHeadingColumn orderBy={['created']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Created</TableHeadingColumn>
                <TableHeadingColumn orderBy={['modified']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Modified</TableHeadingColumn>
                <TableHeadingColumn orderBy={['user.lastName', 'user.firstName']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>User</TableHeadingColumn>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
          {this.state.showCreateUserModal ? <UserCreate closeHandler={this.toggleCreateUserModal} saveHandler={this.saveHandler}/> : null}
          {this.state.showDeleteUserModal ?
            <ConfirmModal title="Delete user?"
                          buttonConfirmLabel="Delete"
                          buttonConfirmColor="danger"
                          buttonCancelLabel="Cancel"
                          buttonConfirmHandler={this.deleteUserConfirmHandler}
                          buttonCancelHandler={this.toggleDeleteUserModal}
                          loading={this.props.delete.loading}
                          error={this.props.delete.error}>
              <span>
                Are you sure you want to delete the user <strong>{this.state.userToDelete.email}</strong>?
                <br/><br/>
                Note: This will not delete any data for this user.
              </span>
            </ConfirmModal> : null
          }
        </Container>
        <Footer/>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    delete: {
      data: state.usersDelete.data,
      error: state.usersDelete.error,
      loading: state.usersDelete.loading
    },
    list: {
      data: state.usersList.data,
      error: state.usersList.error,
      loading: state.usersList.loading
    },
    post: {
      data: state.usersPost.data,
      error: state.usersPost.error,
      loading: state.usersPost.loading
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    usersList: () => dispatch(actions.usersList()),
    usersDelete: (userId) => dispatch(actions.usersDelete(userId)),
    usersPost: (data) => dispatch(actions.usersPost(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);


import React, {Component} from 'react';
import { Input } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import PropTypes from 'prop-types';
import moment from 'moment';

class CustomInput extends Component {
  render () {
    return (
      <Input invalid={this.props.invalid}
             onClick={this.props.onClick}
             onChange={() => {}}
             placeholder="Select a date and time"
             type="text"
             valid={this.props.valid}
             value={this.props.value}/>
    )
  }
}

class DateTimePicker extends Component {
  render() {
    const customInput = <CustomInput date={moment.utc(this.props.date.value).toDate()}
                                     valid={this.props.date.touched && this.props.date.valid}
                                     invalid={this.props.date.touched && !this.props.date.valid}/>;
    return (
      <ReactDatePicker customInput={customInput}
                       id={this.props.id}
                       onChange={this.props.onChange}
                       selected={moment.utc(this.props.selected).toDate()}
                       dateFormat="yyyy-MM-dd h:mm a"
                       showTimeSelect
                       timeFormat="h:mm a"
                       timeIntervals={15}
                       timeCaption="Select Time"/>
    );
  }
}

DateTimePicker.propTypes = {
  date: PropTypes.object.isRequired, //input field with value property which should be a string
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired
};

export default DateTimePicker;
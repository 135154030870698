import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketProfilesListStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketProfilesListSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketProfilesListFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKET_PROFILES_LIST_START: return bucketProfilesListStart(state, action);
    case actionTypes.BUCKET_PROFILES_LIST_SUCCESS: return bucketProfilesListSuccess(state, action);
    case actionTypes.BUCKET_PROFILES_LIST_FAILURE: return bucketProfilesListFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col
} from 'reactstrap';
import ErrorAlert from '../../common/ErrorAlert';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';

class EmailBucket extends Component
{
  state = {
    dataLoaded: false,
    formData: {
      body: {value: '', touched: false, valid: false},
      emailFieldKey: {value: '', touched: false, valid: false},
      firstNameFieldKey: {value: '', touched: false, valid: false},
      lastNameFieldKey: {value: '', touched: false, valid: false},
      subject: {value: '', touched: false, valid: false}
    },
    formValid: false,
    originalData: {}
  };

  componentDidMount() {
    if (this.props.messageId) {
      this.props.getMessage(this.props.messageId);
    }
    this.props.getSurveyFields(this.props.surveyId);
  }

  changeHandler = (key, value) => {
    const formData = cloneDeep(this.state.formData);
    formData[key].value = value;
    formData[key].touched = true;
    // if (key !== 'private' ) {
    formData[key].valid = value.length > 0;
    // } else {
    //   formData[key].valid = true;
    // }

    this.setState({
      formData: formData,
      formValid: this.validateForm(formData)
    });
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {...state};
    //console.log('getDerived', state.formData.fieldLabel.value);
    // if we have received the message item from the api
    if (!isEmpty(props.get.data.attributes) && state.dataLoaded === false && props.messageId !== null) {
      newState.originalData = props.get.data.attributes;
//console.log('getDerived');

      // clear formData in new state, we'll rebuild it using previous state and new data
      newState.formData = {};

      // loop over each formData element we will set, we use existing state formData so we don't
      // have to map id or created fields
      Object.keys(state.formData).map(key => {
          if (newState.originalData[key] === 'undefined') {
            return null;
          }

          // normal value
          newState.formData[key] = {
            touched: false,
            valid: false,
            value: newState.originalData[key]
          };

        return null;
      });
      newState.dataLoaded = true;
      const instance = new EmailBucket({});
      newState.formValid = instance.validateForm(newState.formData);
      
    }

    return newState;
  }

  validateForm = (formData) => {
    if (formData.body.value.length <= 0) {
      return false;
    }
    if (formData.subject.value.length <= 0) {
      return false;
    }
    if (formData.firstNameFieldKey.value.length <= 0) {
      return false;
    }
    if (formData.emailFieldKey.value.length <= 0) {
      return false;
    }
    return true;
  };

  submit = (event) => {
    event.preventDefault();
    this.props.saveHandler(this.state.formData);
  };

  sendHandler = () => {
    const data = {...this.state.formData};
    data.status = {value: 'queued', touched: true, valid: true};
    this.props.saveHandler(data, this.props.messageId);
  };

  render() {
    let loading = null;
    let error = null;
    let item = null;
    let fieldOptions = [];

    // options
    if (!isEmpty(this.props.surveyFields.data)) {
      fieldOptions = this.props.surveyFields.data.map(item => {
        return <option key={item.id} value={item.attributes.fieldKey}>{item.attributes.fieldLabel}</option>;
      });

    }


    //console.log(this.props.get.data, this.props.messageId);
    if (!isEmpty(this.props.get.data) || this.props.messageId === null) {
      item = <Form onSubmit={this.submit}>
        <FormGroup>
          <Label>Bucket</Label>
          <Input type="text" readOnly={true} value={this.props.bucket}/>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md={4}>
              <Label for="firstNameFieldKey">First Name</Label>
              <Input id="firstNameFieldKey"
                     onChange={e => this.changeHandler('firstNameFieldKey', e.target.value)}
                     type="select"
                     invalid={this.state.formData.firstNameFieldKey.touched && !this.state.formData.firstNameFieldKey.valid}
                     valid={this.state.formData.firstNameFieldKey.touched && this.state.formData.firstNameFieldKey.valid}
                     value={this.state.formData.firstNameFieldKey.value}>
                <option value="">Select a survey field</option>
                {fieldOptions}
              </Input>
            </Col>
            <Col md={4}>
              <Label for="lastNameFieldKey">Last Name</Label>
              <Input id="lastNameFieldKey"
                     onChange={e => this.changeHandler('lastNameFieldKey', e.target.value)}
                     type="select"
                     invalid={this.state.formData.lastNameFieldKey.touched && !this.state.formData.lastNameFieldKey.valid}
                     valid={this.state.formData.lastNameFieldKey.touched && this.state.formData.lastNameFieldKey.valid}
                     value={this.state.formData.lastNameFieldKey.value}>
                <option value="">Select a survey field</option>
                {fieldOptions}
              </Input>
            </Col>
            <Col md={4}>
              <Label for="emailFieldKey">Email</Label>
              <Input id="emailFieldKey"
                     onChange={e => this.changeHandler('emailFieldKey', e.target.value)}
                     type="select"
                     invalid={this.state.formData.emailFieldKey.touched && !this.state.formData.emailFieldKey.valid}
                     options={{}}
                     valid={this.state.formData.emailFieldKey.touched && this.state.formData.emailFieldKey.valid}
                     value={this.state.formData.emailFieldKey.value}>
                <option value="">Select a survey field</option>
                {fieldOptions}
              </Input>
            </Col>
          </Row>
          <FormText>
            We need to know which survey fields contain recipient information. Please select the survey field that
            contains the recipients email address and name. If your survey does not have a separate first and last
            name field then leave last name blank and put the full name in the first name field.
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label>Subject</Label>
          <Input id="subject"
                 onChange={e => this.changeHandler('subject', e.target.value)}
                 type="text"
                 invalid={this.state.formData.subject.touched && !this.state.formData.subject.valid}
                 placeholder="Thanks for applying!"
                 valid={this.state.formData.subject.touched && this.state.formData.subject.valid}
                 value={this.state.formData.subject.value}/>
        </FormGroup>
        <FormGroup>
           <Label for="body">Message</Label>
           <Input id="body"
                 onChange={e => this.changeHandler('body', e.target.value)}
                 type="textarea"
                 style={{height: '150px'}}
                 invalid={this.state.formData.body.touched && !this.state.formData.body.valid}
                 placeholder=""
                 valid={this.state.formData.body.touched && this.state.formData.body.valid}
                 value={this.state.formData.body.value}/>
          {/*<Label check for="body">Feedback</Label>*/}
          {/*<FormText color="muted">*/}
          {/*  This survey will NOT be sent to anyone automatically.*/}
          {/*</FormText>*/}
        </FormGroup>
      </Form>;
    } else if (this.props.get.loading) {
      item = <Spinner size="sm" color="primary"/>;
    } else if (this.props.get.error) {
      item = <ErrorAlert error={this.props.get.error}/>;
    }

    if (this.props.post.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;
    } else if (this.props.post.error) {
      error = <ErrorAlert error={this.props.post.error}/>;
    }

    if (this.props.patch.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;
    } else if (this.props.patch.error) {
      error = <ErrorAlert error={this.props.patch.error}/>;
    }

    return (
      <div>
        <Modal isOpen={true} toggle={this.props.closeHandler} className={this.props.className} size="lg">
          <ModalHeader toggle={this.props.closeHandler}>{this.props.messageId ? 'Edit' : 'Create'} Message</ModalHeader>
          <ModalBody>
            {error}
            {item}
          </ModalBody>
          <ModalFooter>
            {loading}
            <Button color="secondary" onClick={this.props.closeHandler}>Cancel</Button>
            <Button color="warning"
                    disabled={this.props.patch.loading || this.props.post.loading || !this.state.formValid}
                    onClick={() => this.props.saveHandler(this.state.formData, this.props.messageId)}>Save Draft</Button>{' '}
            <Button color="primary"
                    disabled={this.props.patch.loading || this.props.post.loading || !this.state.formValid}
                    onClick={this.sendHandler}>Send</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    get: {
      data: state.messagesGet.data,
      error: state.messagesGet.error,
      loading: state.messagesGet.loading
    },
    post: {
      data: state.messagesPost.data,
      error: state.messagesPost.error,
      loading: state.messagesPost.loading
    },
    patch: {
      data: state.messagesPatch.data,
      error: state.messagesPatch.error,
      loading: state.messagesPatch.loading
    },
    surveyFields: {
      data: state.surveysFieldsList.data,
      error: state.surveysFieldsList.error,
      loading: state.surveysFieldsList.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMessage: (surveyId, messageId) => dispatch(actions.messagesGet(surveyId, messageId)),
    getSurveyFields: (surveyId) => dispatch(actions.surveysFieldsList(surveyId))
  };
};

EmailBucket.defaultProps = {
  messageId: null
};

EmailBucket.propTypes = {
  bucket: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  messageId: PropTypes.number,
  saveHandler: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailBucket);


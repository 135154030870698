import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const submissionsGet = (submissionId, params) => {
  return dispatch => {
    dispatch(submissionsGetStart());

    axios.get(`/submissions/${submissionId}`, {params: params})
      .then(response => {
        dispatch(submissionsGetSuccess(response.data.data));
      })
      .catch(error => {
        console.error('submissionsGetFailure', error);
        dispatch(submissionsGetFailure(error));
      });
  };
};

export const submissionsGetStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_GET_START
  };
};

export const submissionsGetSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_GET_SUCCESS,
    data: data
  };
};

export const submissionsGetFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_GET_FAILURE,
    error: error
  };
};


export const submissionsList = (params) => {
  return dispatch => {
    dispatch(submissionsListStart());

    axios.get('/submissions', {params: params})
      .then(response => {
        dispatch(submissionsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('submissionsListFailure', error);
        dispatch(submissionsListFailure(error));
      });
  };
};

export const submissionsListStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_LIST_START
  };
};

export const submissionsListSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_LIST_SUCCESS,
    data: data
  };
};

export const submissionsListFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_LIST_FAILURE,
    error: error
  };
};

export const submissionsPatch = (submissionId, data, successCallback) => {
  return dispatch => {
    dispatch(submissionsPatchStart());
    
    data = {
      data: {
        attributes: data
      }
    };
    
    axios.patch(`/submissions/${submissionId}`, data)
      .then(response => {
        dispatch(submissionsPatchSuccess(response.data.data));
        dispatch(submissionsGetSuccess(response.data.data));
        successCallback()
      })
      .catch(error => {
        console.error('submissionsPatchFailure', error);
        dispatch(submissionsPatchFailure(error));
      });
  };
};

export const submissionsPatchStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_PATCH_START
  };
};

export const submissionsPatchSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_PATCH_SUCCESS,
    data: data
  };
};

export const submissionsPatchFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_PATCH_FAILURE,
    error: error
  };
};



import axios from '../../axios';
import { submissionsList } from './submissions';
import * as actionTypes from './actionTypes';

export const submissionsActionsPost = (action, submissionIds, destinationBucket, listParams, successCallback) => {
  return dispatch => {
    dispatch(submissionsActionsPostStart());

    const data = {
      data: {
        attributes: {
          action: action,
          submissionIds: submissionIds,
          destinationBucket: destinationBucket
        }
      }
    };

    axios.post(`/submissions/actions`, data)
      .then(response => {
        dispatch(submissionsActionsPostSuccess(response.data.data));
        dispatch(submissionsList(listParams));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('submissionsActionsPostFailure', error);
        dispatch(submissionsActionsPostFailure(error));
      });
  };
};

export const submissionsActionsPostStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_ACTIONS_POST_START
  };
};

export const submissionsActionsPostSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_ACTIONS_POST_SUCCESS,
    data: data
  };
};

export const submissionsActionsPostFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_ACTIONS_POST_FAILURE,
    error: error
  };
};



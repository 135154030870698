import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const messagesDelete = (messageId, successCallback) => {
  return dispatch => {
    dispatch(messagesDeleteStart());

    axios.delete('/messages/' + messageId)
      .then(response => {
        dispatch(messagesDeleteSuccess(response.data.data));
        dispatch(messagesList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('messagesDeleteFailure', error);
        dispatch(messagesDeleteFailure(error));
      });
  };
};

export const messagesDeleteStart = () => {
  return {
    type: actionTypes.MESSAGES_DELETE_START
  };
};

export const messagesDeleteSuccess = (data) => {
  return {
    type: actionTypes.MESSAGES_DELETE_SUCCESS,
    data: data
  };
};

export const messagesDeleteFailure = (error) => {
  return {
    type: actionTypes.MESSAGES_DELETE_FAILURE,
    error: error
  };
};

export const messagesList = (surveyId, bucket) => {
  return dispatch => {
    dispatch(messagesListStart());

    axios.get('/messages', {params: {
      surveyId: surveyId,
      bucket: bucket
    }})
      .then(response => {
        dispatch(messagesListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('messagesListFailure', error);
        dispatch(messagesListFailure(error));
      });
  };
};

export const messagesListStart = () => {
  return {
    type: actionTypes.MESSAGES_LIST_START
  };
};

export const messagesListSuccess = (data) => {
  return {
    type: actionTypes.MESSAGES_LIST_SUCCESS,
    data: data
  };
};

export const messagesListFailure = (error) => {
  return {
    type: actionTypes.MESSAGES_LIST_FAILURE,
    error: error
  };
};

export const messagesGet = (surveyId) => {
  return dispatch => {
    dispatch(messagesGetStart());

    axios.get('/messages/' + surveyId)
      .then(response => {
        dispatch(messagesGetSuccess(response.data.data));
      })
      .catch(error => {
        console.error('messagesGetFailure', error);
        dispatch(messagesGetFailure(error));
      });
  };
};

export const messagesGetStart = () => {
  return {
    type: actionTypes.MESSAGES_GET_START
  };
};

export const messagesGetSuccess = (data) => {
  return {
    type: actionTypes.MESSAGES_GET_SUCCESS,
    data: data
  };
};

export const messagesGetFailure = (error) => {
  return {
    type: actionTypes.MESSAGES_GET_FAILURE,
    error: error
  };
};

export const messagesPatch = (messageId, data, successCallback) => {
  return dispatch => {
    dispatch(messagesPatchStart());
    
    // put data into correct format
    data = {
      data: {
        type: 'message',
        id: messageId,
        attributes: data
      }
    };
    
    axios.patch('/messages/' + messageId, data)
      .then(response => {
        dispatch(messagesPatchSuccess(response.data.data, data.data.attributes));
        // since patch returns a complete survey object we may as well update the ui
        dispatch(messagesGetSuccess(response.data.data));

        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('messagesPatchFailure', error);
        dispatch(messagesPatchFailure(error, data.data.attributes));
      });
  };
};

export const messagesPatchStart = () => {
  return {
    type: actionTypes.MESSAGES_PATCH_START
  };
};

export const messagesPatchSuccess = (data, request) => {
  return {
    type: actionTypes.MESSAGES_PATCH_SUCCESS,
    data: data,
    request: request
  };
};

export const messagesPatchFailure = (error, request) => {
  return {
    type: actionTypes.MESSAGES_PATCH_FAILURE,
    error: error,
    request: request
  };
};

export const messagesPost = (data, surveyId, bucket, successCallback) => {
  return dispatch => {
    dispatch(messagesPostStart());

    // put data into correct format
    data = {
      data: {
        type: 'survey',
        id: null,
        attributes: data
      }
    };

    axios.post('/messages', data)
      .then(response => {
        dispatch(messagesPostSuccess(response.data.data));
        // since post returns a complete survey object we may as well update the ui
        dispatch(messagesGetSuccess(response.data.data));
        dispatch(messagesList(surveyId, bucket));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('messagesPostFailure', error);
        dispatch(messagesPostFailure(error));
      });
  };
};

export const messagesPostStart = () => {
  return {
    type: actionTypes.MESSAGES_POST_START
  };
};

export const messagesPostSuccess = (data) => {
  return {
    type: actionTypes.MESSAGES_POST_SUCCESS,
    data: data
  };
};

export const messagesPostFailure = (error) => {
  return {
    type: actionTypes.MESSAGES_POST_FAILURE,
    error: error
  };
};

export const fieldTypes = {
  'person-profile': {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    role: 'Role',
    phoneNumber: 'Phone Number',
  },
  'company-profile': {
    businessNumber: 'Business Number',
    bio: 'Bio',
    websiteUrl: 'Website URL',
    angelListUrl: 'Angel List URL',
    linkedInUrl: 'LinkedIn URL',
    twitterName: 'Twitter Name',
    incDate: 'Incorporated Date',
    incCountry: 'Incorporated Country',
    incState: 'Incorporated State',
    phone: 'Phone Number'
    // street: 'Street',
    // street2: 'Street 2',
    // city: 'City',
    // province: 'Province',
    // country: 'Country',
    // postalCode: 'Postal Code',
    // phone: 'Phone'
  },
  'company-profile-name': {
    'company-profile-name': 'Company Name'
  },
  'company-meta': {
    industry: 'Industry',
    focus: 'Focus',
    offering: 'Offering',
    numberFounders: 'Number of Founders',
    numberFoundersYouth: 'Number of Youth Founders',
    numberFoundersFirstVenture: 'Number of Founders - First Venture',
    numberFoundersFemale: 'Number of Female Founders',
  },
  cdmn: {
    softLandingProgramName: 'Soft Landing Program Name',
    connectingAccount: 'Connecting Account',
    //recordOwner: 'Record Owner', // - will be manually updated.
    //Application Date: please use the submission date from the app.
    //stage: 'Verbal commitment for those companies in the approved bucket OR closed-lost for companies in a declined bucket',
    decisionMakerName: 'Decision Maker Name',
    decisionMakerEmail: 'Decision Maker Email',
    firstChoiceCity: 'First Choice City',
    firstChoiceCountry: 'First Choice Country',
    secondChoiceCity: 'Second Choice City',
    secondChoiceCountry: 'Second Choice Country',
    thirdChoiceCity: 'Third choice city',
    thirdChoiceCountry: 'Third choice country'
  },
  passportProgram: {
    //Passport Name is the same name as the cohort field in the properties area.
    //Traveling Account (company that submitted the application) = already have this.
    startDate: 'Start Date', // = should be a Salesforce field
    endDate: 'End Date',  //= should be a Salesforce field
    reasonForRequest: 'Reason for request', //= should be a Salesforce field - free form text
    homeHub: 'Home hub', //- same as Soft Landing Connecting Account
    destinationHub: 'Destination hub' //- similar to  Soft Landing Connecting Account, but it will be pulled from a different list
  }
};

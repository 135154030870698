import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import {Spinner, UncontrolledTooltip} from 'reactstrap';
import ErrorAlert from './ErrorAlert';
import {isEmpty} from 'lodash';
import moment from 'moment';

class SurveyInfoBasic extends Component
{
  componentDidMount() {
    if (isEmpty(this.props.data) || this.props.data.id !== this.props.surveyId) {
      this.props.surveysGet(this.props.surveyId);
    }
  }

  render() {
    let surveyInfo = null;
    if (this.props.loading) {
      surveyInfo = <Spinner size="sm" color="primary"/>;
    } else if (this.props.error) {
      surveyInfo = <ErrorAlert error={this.props.error}/>;
    } else if (!isEmpty(this.props.data)) {
      let surveyType;

      switch (this.props.data.attributes.type) {
        case 'survey':
          surveyType = 'Company Survey';
          break;
        case 'intake':
          surveyType = 'Intake Survey';
          break;
        case 'request':
          surveyType = 'Request Form';
          break;
        default:
          surveyType = 'Unknown'
      }

      surveyInfo = (
        <dl className="row mb-0">
          <dt className="col-md-4">Name</dt>
          <dd className="col-md-8">{this.props.data.attributes.name}</dd>
          <dt className="col-md-4">Type</dt>
          <dd
            className="col-md-8">{surveyType}</dd>
          {
            this.props.data.attributes.type === 'survey'
              ? <React.Fragment>
                <dt className="col-md-4">Reporting Period</dt>
                <dd className="col-md-8">
                  {moment(this.props.data.attributes.periodStart).format('ll')} to{' '}
                  {moment(this.props.data.attributes.periodEnd).format('ll')}
                </dd>
              </React.Fragment>
              : null
          }
          <dt className="col-md-4">Created</dt>
          <dd className="col-md-8">
            <span
              id="survey-info-basic-created">{moment.utc(this.props.data.attributes.created).local().fromNow()}</span>{' '}
            <UncontrolledTooltip placement="right" target="survey-info-basic-created">
              {moment.utc(this.props.data.attributes.created).local().format('lll')}
            </UncontrolledTooltip>
            by {this.props.data.attributes.user.firstName} {this.props.data.attributes.user.lastName}
          </dd>
          <dt className="col-md-4">Last modified</dt>
          <dd className="col-md-8">
            <span
              id="survey-info-basic-modified">{moment.utc(this.props.data.attributes.modified).local().fromNow()}</span>{' '}
            <UncontrolledTooltip placement="right" target="survey-info-basic-modified">
              {moment.utc(this.props.data.attributes.modified).local().format('lll')}
            </UncontrolledTooltip>
            by {this.props.data.attributes.userModified.firstName} {this.props.data.attributes.userModified.lastName}
          </dd>
        </dl>
      );
    }

    return (
      <section>
        {surveyInfo}
      </section>
    );
  }
}

SurveyInfoBasic.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    data: state.surveysGet.data,
    error: state.surveysGet.error,
    loading: state.surveysGet.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    surveysGet: (surveyId, bucket) => dispatch(actions.surveysGet(surveyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyInfoBasic);


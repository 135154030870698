import React, { Component } from 'react';
import { Table, Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './Column';
import FieldEdit from './FieldEdit';
import * as actions from '../../../store/actions';
import ErrorAlert from '../../common/ErrorAlert';
import ConfirmModal from '../../common/ConfirmModal';

class FieldList extends Component
{
  state = {
    fieldKeys: [],
    fields: {},
    fieldsOrder: [],
    fieldToDelete: {},
    fieldToEdit: {},
    showCreateFieldModal: false,
    showDeleteFieldModal: false,
    showEditFieldModal: false
  };

  componentDidMount() {
    this.props.listSurveyFields(this.props.surveyId);
  }

  onDragEnd = result => {
    const { source, destination, draggableId } = result;

    // abort if no destination
    if (!destination) {
      return;
    }

    // did the draggable actually move?
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    //console.log(result);

    const fieldsOrder = [...this.state.fieldsOrder];
    //console.log('FIELDS OLD ORDER', this.state.fieldsOrder);

    fieldsOrder.splice(source.index, 1);
    fieldsOrder.splice(destination.index, 0, draggableId);
    //console.log('FIELDS NEW ORDER', fieldsOrder);

    this.props.surveysActionsPost(this.props.surveyId, {
      action: 'reorder-fields',
      fieldsOrder: fieldsOrder
    });
    
    this.setState({fieldsOrder: fieldsOrder});
    
  };

  /**
   * Props are immutable, we don't want to modify props so we assign to state
   * @param props
   * @param state
   * @returns {null|{fields: (...*[]|*)}}
   */
  static getDerivedStateFromProps(props, state) {
    //console.log('FieldList.getDerivedStateFromProps()');
    // if there are no fields set in state and we have received field data then
    // populate state.fields array with props.field
    if (props.list.data.length > 0) {
      //console.log('getDerivedStateFromProps');
      // change field array to be an object indexed by id

      let fieldsOrder = [...state.fieldsOrder];
      // create a fieldOrder state if one does not exist or has changed
      if (state.fieldsOrder.length === 0 || state.fieldsOrder.length !== props.list.data.length) {
        fieldsOrder = props.list.data.map(field => 'field-' + field.id);
      }

      // convert array to hash map object
      const fields = props.list.data.reduce(function(map, obj) {
        map['field-' + obj.id] = obj;
        return map;
      }, {});

      // we need this to make sure we don't duplicate any keys
      const fieldKeys = props.list.data.map(field => {
        return field.attributes.fieldKey;
      });
      //console.log('FIELD KEYS', fieldKeys);
      
      return {
        fields: fields,
        fieldsOrder: fieldsOrder,
        fieldKeys: fieldKeys
      };
    }
    return null;
  }

  showCreateFieldModal = () => {
    //console.log('FieldList.showCreateFieldModal()');
    this.setState((prevState) => ({
      showCreateFieldModal: !prevState.showCreateFieldModal
    }));
  };

  toggleDeleteFieldModal = () => {
    this.setState((prevState) => ({
      showDeleteFieldModal: !prevState.showDeleteFieldModal
    }));
  };

  deleteFieldConfirmHandler = () => {
    this.props.surveysFieldsDelete(this.props.surveyId, this.state.fieldToDelete.id, () => {
      this.toggleDeleteFieldModal();
      this.setState({fieldToDelete: {}});
    });
  };

  deleteFieldHandler = (field) => {
    this.setState({
      fieldToDelete: field
    }, () => this.toggleDeleteFieldModal());
  };

  toggleEditFieldModal = () => {
    this.setState((prevState) => ({
      showEditFieldModal: !prevState.showEditFieldModal
    }));
  };

  editFieldHandler = (field) => {
    this.setState({
      fieldToEdit: field
    }, () => this.toggleEditFieldModal());
  };

  render() {

    let tbody = null;
    if (this.props.list.loading) {
      tbody = <tbody>
                <tr>
                  <td colSpan={5} className="text-center"><Spinner size="sm" color="primary"/></td>
                </tr>
              </tbody>;
    } else if (this.props.list.error) {
      tbody = <tbody>
                <tr>
                  <td colSpan={5} className="text-center"><ErrorAlert error={this.props.list.error}/></td>
                </tr>
              </tbody>;
    } else if (this.props.list.data.length > 0) {
      tbody = <DragDropContext onDragEnd={this.onDragEnd}>
                <Column confirmDeleteHandler={this.deleteFieldHandler}
                        editFieldHandler={this.editFieldHandler}
                        fieldKeys={this.state.fieldKeys}
                        fields={this.state.fields}
                        fieldsOrder={this.state.fieldsOrder}
                        surveyId={this.props.surveyId}/>
              </DragDropContext>;
    } else if (this.props.list.data.length === 0 && this.props.list.loading === false) {
      tbody = <tbody>
                <tr>
                  <td colSpan={5} className="text-center">Nothing here yet. Why not add a new field?</td>
                </tr>
              </tbody>;
    }

    return (
      <section className="field-list">
        <h2>Fields</h2>
        <p><Button color="primary" onClick={this.showCreateFieldModal}>Add new field</Button></p>
        {this.state.showCreateFieldModal === true ? <FieldEdit closeHandler={this.showCreateFieldModal}
                                                               fieldKeys={this.state.fieldKeys}
                                                               surveyId={this.props.surveyId}
                                                               surveyType={this.props.surveyType}/> : null}
        <Table striped responsive>
          <thead>
            <tr>
              <th>Field</th>
              <th>Storage Type</th>
              <th>Salesforce Object Mapping</th>
              <th>Required</th>
              <th>Actions</th>
            </tr>
          </thead>
          {tbody}
        </Table>
        {this.state.showDeleteFieldModal ?
          <ConfirmModal title="Delete this field?"
                        buttonConfirmColor="danger"
                        buttonConfirmLabel="Delete"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteFieldConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteFieldModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
            <span>
              Are you absolutely 100% positively sure you want to delete the field
              {' '}<strong>{this.state.fieldToDelete.fieldLabel}</strong>?
              <br/><br/>
              Deleting a field is permanent. The field will be gone forever.
            </span>
          </ConfirmModal> : null
        }
        {
          this.state.showEditFieldModal === true
            ? <FieldEdit fieldId={this.state.fieldToEdit.id}
                         fieldKeys={this.state.fieldKeys}
                         closeHandler={this.toggleEditFieldModal}
                         surveyId={this.props.surveyId}
                         surveyType={this.props.surveyType}
                         surveyUseSalesforce={this.props.surveyUseSalesforce}/>
            : null
        }
      </section>
    );
  }
}

FieldList.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    list: {
      data: state.surveysFieldsList.data,
      error: state.surveysFieldsList.error,
      loading: state.surveysFieldsList.loading
    },
    delete: {
      data: state.surveysFieldsDelete.data,
      error: state.surveysFieldsDelete.error,
      loading: state.surveysFieldsDelete.loading
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listSurveyFields: (surveyId) => dispatch(actions.surveysFieldsList(surveyId)),
    surveysActionsPost: (surveyId, data, success) => dispatch(actions.surveysActionsPost(surveyId, data, success)),
    surveysFieldsDelete: (surveyId, fieldId, success) => dispatch(actions.surveysFieldsDelete(surveyId, fieldId, success))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldList);


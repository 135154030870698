import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketsDeleteClear = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: false,
    data: []
  });
};

const bucketsDeleteStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketsDeleteSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketsDeleteFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKETS_DELETE_CLEAR: return bucketsDeleteClear(state, action);
    case actionTypes.BUCKETS_DELETE_START: return bucketsDeleteStart(state, action);
    case actionTypes.BUCKETS_DELETE_SUCCESS: return bucketsDeleteSuccess(state, action);
    case actionTypes.BUCKETS_DELETE_FAILURE: return bucketsDeleteFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketProfilesPostStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketProfilesPostSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketProfilesPostFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKET_PROFILES_POST_START: return bucketProfilesPostStart(state, action);
    case actionTypes.BUCKET_PROFILES_POST_SUCCESS: return bucketProfilesPostSuccess(state, action);
    case actionTypes.BUCKET_PROFILES_POST_FAILURE: return bucketProfilesPostFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false,
  request: {}
};

const surveysDeleteStart = (state, action) => {
  return updateObject(state, {
    data: {},
    error: null,
    loading: true
  });
};

const surveysDeleteSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false,
    request: action.request
  } );
};

const surveysDeleteFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false,
    request: action.request
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_DELETE_START: return surveysDeleteStart(state, action);
    case actionTypes.SURVEYS_DELETE_SUCCESS: return surveysDeleteSuccess(state, action);
    case actionTypes.SURVEYS_DELETE_FAILURE: return surveysDeleteFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import Auth from './components/auth/Auth';
import Dashboard from './components/dashboard/Dashboard';
import SurveyList from './components/surveys/SurveyList';
import SurveyManage from './components/surveys/SurveyManage';
import UserList from './components/users/UserList';
import PrivateRoute from './components/PrivateRoute';
import SurveySubmissions from './components/surveys/SurveySubmissions';
import SubmissionResponses from './components/surveys/SurveySubmissions/SubmissionResponses';
import SurveyRecipients from './components/surveys/SurveyRecipients';
import Buckets from './components/buckets/Buckets';

class App extends Component {
    
  render() {
    return (
      <div className="app">
        <Switch>
          <Route path="/auth" component={Auth}/>
          <PrivateRoute path="/buckets" exact component={Buckets} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/surveys" exact component={SurveyList} />
          <PrivateRoute path="/surveys/:surveyId/manage" component={SurveyManage} />
          <PrivateRoute path="/surveys/:surveyId/submissions" exact component={SurveySubmissions} />
          <PrivateRoute path="/surveys/:surveyId/submissions/:bucket" exact component={SurveySubmissions} />
          <PrivateRoute path="/surveys/:surveyId/submissions/:submissionId/responses" component={SubmissionResponses} />
          <PrivateRoute path="/surveys/:surveyId/recipients" component={SurveyRecipients} />
          <PrivateRoute path="/users" component={UserList} />
          <Redirect from="/" exact to="/surveys"/>
        </Switch>
      </div>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MdMoreVert } from 'react-icons/md';

class Navigation extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <nav className="mb-3">
        <Navbar color="dark" dark expand="md">
          <NavbarBrand tag={Link} to="/surveys">Communitech Forms</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/*<NavItem>*/}
              {/*  <NavLink tag={Link} to="/dashboard">Dashboard</NavLink>*/}
              {/*</NavItem>*/}
              <NavItem>
                <NavLink tag={Link} to="/surveys">Surveys</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/users">Users</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/buckets">Buckets</NavLink>
              </NavItem>
              
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <MdMoreVert/>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="https://www.google.com/accounts/Logout">Sign out of Google</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </nav>
    );
  }
}

export default Navigation;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysFieldsDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const surveysFieldsDeleteSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysFieldsDeleteFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_FIELDS_DELETE_START: return surveysFieldsDeleteStart(state, action);
    case actionTypes.SURVEYS_FIELDS_DELETE_SUCCESS: return surveysFieldsDeleteSuccess(state, action);
    case actionTypes.SURVEYS_FIELDS_DELETE_FAILURE: return surveysFieldsDeleteFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
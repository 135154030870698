import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    authenticated: false,
    userId: null,
    error: null,
    loading: false
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        authenticated: action.authenticated,
        userId: action.userId,
        error: null,
        loading: false
     } );
};

const authFailure = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authSignOut = (state, action) => {
  return updateObject(state, { authenticated: false, userId: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAILURE: return authFailure(state, action);
    case actionTypes.AUTH_SIGN_OUT: return authSignOut(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import { Table, Spinner, UncontrolledTooltip, ModalHeader, ModalBody, ModalFooter, Button, Modal } from 'reactstrap';
import ErrorAlert from '../common/ErrorAlert';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { MdComputer, MdPhoneIphone } from 'react-icons/md';
import { FaApple, FaWindows, FaLinux, FaChrome, FaFirefox, FaEdge, FaInternetExplorer, FaOpera } from 'react-icons/fa';

class MailEvents extends Component {

  state = {
    filter: 'completed', //default bucket
  };

  componentDidMount() {
    this.props.mailEventsList({
      messageId: this.props.messageId
    });
  }

  render() {
    let items = null;
    if (this.props.list.loading) {
      items = <tr><td colSpan={9} className="text-center"><Spinner size="sm"/></td></tr>
    } else if (this.props.list.error) {
      items = <tr><td colSpan={9} className="text-center"><ErrorAlert error={this.props.list.error}/></td></tr>
    } else if (!isEmpty(this.props.list.data)) {
      items = Object.keys(this.props.list.data.attributes).map(key => {
        const item = this.props.list.data.attributes[key];

        let deviceType = null;
        if (item.client) {
          switch(item.client['device-type']) {
            case 'desktop':
              deviceType = <MdComputer/>;
              break;
            case 'mobile':
              deviceType = <MdPhoneIphone/>;
              break;
            default:
              deviceType = '';
          }
        }

        let os = null;
        if (item.client) {
          switch(item.client['client-os']) {
            case 'Linux':
              os = <FaLinux/>;
              break;
            case 'Windows':
              os = <FaWindows/>;
              break;
            case 'Macintosh':
              os = <FaApple/>;
              break;
            default:
              os = '';
          }
        }

        let browser = null;
        if (item.client) {
          switch(item.client['client-name']) {
            case 'Chrome':
              browser = <FaChrome/>;
              break;
            case 'Firefox':
              browser = <FaFirefox/>;
              break;
            case 'Opera':
              browser = <FaOpera/>;
              break;
            case 'Edge':
              browser = <FaEdge/>;
              break;
            case 'IE':
              browser = <FaInternetExplorer/>;
              break;
            default:
              browser = '';
          }
        }

        return (
          <tr key={key}>
            <td>{item.event}</td>
            <td style={{whiteSpace: 'nowrap'}}>
              {item.client ? deviceType : null}
              {item.client ? os : null}
              {item.client ? browser : null}
            </td>
            <td>{item.failureMessage} {item.failureReason} {item.failureSeverity}</td>
            <td>
              {
                item.location
                ? <React.Fragment>
                    {item.location.city}, {item.location.region}, {item.location.country}
                    <br/><small className="text-muted">{item.ip}</small>
                  </React.Fragment>
                : null
              }
            </td>
            <td>
              <span id={`created-${key}`}>{moment.utc(item.created).local().fromNow()}</span>
              <UncontrolledTooltip placement="right" target={`created-${key}`}>
                {moment.utc(item.created).local().format('lll')}
              </UncontrolledTooltip>
            </td>
          </tr>
        );
      });
    }

    return (
      <Modal isOpen={true} size="lg" backdrop="static" toggle={this.props.closeHandler} className={this.props.className}>
        <ModalHeader toggle={this.props.closeHandler}>Mail Events</ModalHeader>
        <ModalBody>
          <p><strong>Message ID:</strong> {this.props.messageId}</p>
          <Table>
            <thead>
              <tr>
                <th>Event</th>
                <th>Client</th>
                <th>Failure</th>
                <th>Location</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {items}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.props.closeHandler} color="primary">Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

MailEvents.propTypes = {
  //surveyId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    list: {
      data: state.mailEventsList.data,
      error: state.mailEventsList.error,
      loading: state.mailEventsList.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mailEventsList: (filter) => dispatch(actions.mailEventsList(filter))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailEvents);

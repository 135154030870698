import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false,
  request: {}
};

const surveysPatchStart = (state, action) => {
  return updateObject(state, {
    data: {},
    error: null,
    loading: true
  });
};

const surveysPatchSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false,
    request: action.request
  } );
};

const surveysPatchFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false,
    request: action.request
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_PATCH_START: return surveysPatchStart(state, action);
    case actionTypes.SURVEYS_PATCH_SUCCESS: return surveysPatchSuccess(state, action);
    case actionTypes.SURVEYS_PATCH_FAILURE: return surveysPatchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Spinner } from 'reactstrap';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorAlert from '../../common/ErrorAlert';
import FormSalesforce from "./FieldEdit/FormSalesforce";
import FormSurvey from "./FieldEdit/FormSurvey";
import FormDisplay from "./FieldEdit/FormDisplay";
import { cloneDeep, isObject, isEmpty } from 'lodash';
import { slugify } from '../../../utility';
import { fieldTypes } from '../../../types/fieldTypes';

const validationRules = {
  fieldLabel: {
    required: true
  },
  fieldKey: {
    required: true
  },
  fieldType: {
    required: true
  },
  dataType: {
    required: true
  },
  helpText: {
    required: false
  },
  preQuestion: {
    required: false
  },
  preQuestionHelpText: {
    required: false
  },
  preQuestionLabel: {
    required: false
  },
  storageType: {
    required: true
  },
  options: {
    required: false
  },
  validation: {
    required: {
      required: false
    }
  }
};


class FieldEdit extends Component
{
  state = {
    field: {},    // original form data we are editing (this would be empty for new fields)
    formData: {}, // data the form mutates,
    formValid: false,
    formDataLoaded: false
  };

  constructor(props) {
    super(props);
    this.state.formData = this.getEmptyFormData();
    // this is probably bad but data needs to be empty
    props.fieldGet.data = {};
  }

  componentDidMount() {
    if (this.props.fieldId) {
      this.props.getSurveyField(this.props.surveyId, this.props.fieldId, () => {
        this.validateForm();
      });
    }
    if (isEmpty(this.props.lists.data)) {
      this.props.listsList();
    }
    // else {
    //   // default data for new field
    //   const formData = this.getEmptyFormData();
    //   console.log('formData new');
    //   formData['storageType']['value'] = 'display';
    //   this.setState({formData: formData});
    // }
  }

  componentWillUnmount() {
  }

  /**
   *
   * @returns {{preQuestionHelpText: {valid: boolean, touched: boolean, value: string}, helpText: {valid: boolean, touched: boolean, value: string}, fieldKey: {valid: boolean, touched: boolean, value: string}, fieldLabel: {valid: boolean, touched: boolean, value: string}, dataType: {valid: boolean, touched: boolean, value: string}, storageType: string, preQuestionLabel: {valid: boolean, touched: boolean, value: string}, fieldType: {valid: boolean, touched: boolean, value: string}, validation: {required: {valid: boolean, touched: boolean, value: string}}}}
   */
  getEmptyFormData = () => {

    const fieldStateValueFalse = {
      valid: false,
      touched: false,
      value: false
    };

    const fieldStateValueEmpty = {
      valid: false,
      touched: false,
      value: ''
    };

    return {
      dataType: {...fieldStateValueEmpty},
      fieldKey: {...fieldStateValueEmpty},
      fieldLabel: {...fieldStateValueEmpty},
      fieldType: {...fieldStateValueEmpty},
      helpText: {...fieldStateValueEmpty},
      options: {},
      preQuestionHelpText: {...fieldStateValueEmpty},
      preQuestionLabel: {...fieldStateValueEmpty},
      storageType: {
        valid: false,
        touched: false,
        value: 'display'
      },
      validation: {
        required: {...fieldStateValueFalse}
      }
    }
  };

  // put the field data we received from the api into this local state
  static getDerivedStateFromProps(props, state) {
    const newState = {...state};
    //console.log('getDerived', state.formData.fieldLabel.value);
    // if we have received the field data from the API then
    if (!isEmpty(props.fieldGet.data.attributes) && state.formDataLoaded === false) {
      newState.field = props.fieldGet.data.attributes;


      // clear formData in new state, we'll rebuild it using previous state and new data
      newState.formData = {};

      // loop over each formData element we will set, we use existing state formData so we don't
      // have to map id or created fields
      Object.keys(state.formData).map(key => {
        if (newState.field[key] === 'undefined') {
          return null;
        }

        // likely options or validation fields
        if (isObject(newState.field[key])) {
          // reset them
          newState.formData[key] = {};
          // loop over each key
          Object.keys(newState.field[key]).map(childKey => {
            newState.formData[key][childKey] = {
              touched: false,
              valid: false,
              value: newState.field[key][childKey]
            };
            return null
          });
        } else {
          // normal value
          newState.formData[key] = {
            touched: false,
            valid: false,
            value: newState.field[key]
          };
        }
        return null;
      });
      newState.formDataLoaded = true;


    }
    fieldTypes['tag'] = props.lists.data.attributes.structuredTags;

    return newState;
  }

  submitHandler = (e) => {
    e.preventDefault();
    this.save();
  };

  save = () => {

    const formData = cloneDeep(this.state.formData);

    const data = {};
    Object.keys(formData).map(key => {
      if (typeof formData[key].value !== 'undefined') {
        data[key] = formData[key].value;
      } else {
        data[key] = {};
        Object.keys(formData[key]).map(childKey => {
          if (typeof formData[key][childKey].value !== 'undefined') {
            data[key][childKey] = formData[key][childKey].value;
          }
          return null;
        });
      }
      return null;
    });

    //console.log('SAVE', data);
    //console.log('FORM VALID', this.state.formValid);
    // are we updating a field or creating a new one?
    if (this.props.fieldId) {
      this.props.putSurveyField(this.props.surveyId, this.props.fieldId, data, this.props.closeHandler);
    } else {
      this.props.postSurveyField(this.props.surveyId, data, this.props.closeHandler);
    }
    
  };

  validate = (value, key, childKey) => {
    let rule = {};

    if (typeof childKey === 'undefined' || key === 'options') {
      rule = validationRules[key];
      //value = this.state.formData[key].value;
    } else {
      rule = validationRules[key][childKey];
      //value = this.state.formData[key][childKey].value;
    }
    //console.log('KEY:', key, 'CHILDKEY:', childKey, 'VALUE:', value, 'RULES:', rule);

    // if no rules are defined then field is valid
    if (typeof rule === 'undefined') {
      return true;
    }

    if (key === 'fieldKey') {
      // if we are editing a field and the field key is not unique and the field key is different than the original key
      if (this.props.fieldId && !this.isFieldKeyUnique(value) && value !== this.state.field.fieldKey) {
        return false;
      }
      if (!this.isFieldKeyUnique(value) && value !== this.state.field.fieldKey) {
        return false;
      }
    }

    // if required rule is not set then default to not required
    const required = typeof rule.required === 'undefined' ? false : rule.required;

    if (required && isEmpty(value)) {
      //console.log('NOT VALID');
      return false;
    }
    //console.log('VALID');
    return true;

  };
  
  changeHandler = (key, value) => {

    let field = cloneDeep(this.state.formData);
    //console.log('CHANGE key: ', key, ' value: ', value);


    // if key has a dot then do the sub fields
    const dotIndex = key.indexOf('.');
    if (dotIndex > -1) {
      const parentKey = key.substr(0, dotIndex);
      const childKey = key.substr(dotIndex + 1);
      //console.log('PARENT: ', parentKey, ' CHILD: ', childKey, ' VALUE: ', value);

      //delete field[parentKey].value[childKey];

      // if we don't have a field id yet then this is a new field so lets make nice looking keys
      // if (!this.props.fieldId) {
      //   const newKey = slugify(value);
      //   field[parentKey].value[newKey] = {
      //     touched: true,
      //     value: value,
      //     valid: true //this.validate(value, parentKey, childKey);
      //   };
      // } else {
        //console.log('Aaaaaa');
        field[parentKey][childKey] = {
          touched: true,
          value: value,
          valid: true //this.validate(value, parentKey, childKey);
        };
      //}

    } else if (key.substr(-1, 2) === '[]') {
      // key = key.substr(0, -2);
      // field[key].value[slugify(value)] = value;
      // field[key].touched = true;
      // field[key].valid = true; //this.validate(value, key);
    } else {
      field[key].value = value;
      field[key].touched = true;
      field[key].valid = this.validate(value, key);
    }

    // if we are changing storage type then reset the form data
    if (key === 'storageType' && this.state.formData.storageType.value !== field.storageType.value) {
      field = this.getEmptyFormData();
      field.storageType.value = value;
    }

    switch (field.storageType.value) {
      case 'display':
        // generate fieldKey automatically
        field.fieldKey.value = slugify(field.fieldLabel.value);
        while (!this.isFieldKeyUnique(field.fieldKey.value)) {
          field.fieldKey.value = field.fieldKey.value + Math.floor(Math.random() * (9 - 1)) + 1; // random number between 1 and 9
        }
        field.fieldType.value = field.dataType.value;
        break;
      case 'survey':
        // data type is always survey-data-only
        field.dataType.value = 'survey-data-only';
        if (!field.fieldKey.touched) {
          field.fieldKey.value = slugify(field.fieldLabel.value);
        }
        break;
      case 'salesforce':
        if (key === 'dataType') {
          field.fieldType.value = '';
        }

        //fieldTypes['tag'] = this.props.lists.data.attributes.structuredTags;

        if (typeof fieldTypes[field.dataType.value] === 'undefined') {
          field.fieldType.value = field.dataType.value;
        }

        field.fieldKey.value = field.fieldType.value;
        break;
      default:
        throw Error('Invalid storageType: ' + field.storageType);
    }

    // validate form
    // let valid = true;
    // Object.keys(field).map(key => {
    //   if (typeof field[key].value !== 'undefined') {
    //     valid = valid && this.validate(field[key].value, key);
    //   } else {
    //     Object.keys(field[key]).map(childKey => {
    //       if (typeof field[key][childKey].value !== 'undefined') {
    //         valid = valid && this.validate(field[key][childKey].value, key, childKey);
    //       }
    //       return null;
    //     });
    //   }
    //   return null;
    // });

//    console.log('FORM VALID', valid);
    //console.log('FORMDATA: ', field);
    //console.log('FieldEdit.onChange.fieldLabel: ', field.fieldLabel.value);

    this.setState({
      formData: field
    }, () => {
      // wait for formData to be set because we will also set in when validating entire form
      this.validateForm();
    });
  };

  validateForm = () => {
    //console.log('VALIDATE FORM');

    let valid = true;
    let field = cloneDeep(this.state.formData);
    Object.keys(field).map(key => {
      if (typeof field[key].value !== 'undefined') {
        field[key].valid = this.validate(field[key].value, key);
        valid = valid && field[key].valid;
      } else {
        Object.keys(field[key]).map(childKey => {
          if (typeof field[key][childKey].value !== 'undefined') {
            field[key][childKey].valid = this.validate(field[key][childKey].value, key, childKey);
            valid = valid && field[key][childKey].valid;
          }
          return null;
        });
      }
      return null;
    });
    this.setState({formData: field,formValid: valid});
    //console.log(this.state.formData);
    return valid;
  }

  addOptionHandler = (key, value) => {
    const formData = cloneDeep(this.state.formData);
    const numbers = Object.keys(formData.options).map(key => key.substr(4));

    const number = numbers.length > 0 ? parseInt(numbers[numbers.length - 1]) + 1 : 1;
    formData.options[`opt-${number}`] = {valid: false, touched: false, value: ''};
    this.setState({formData: formData});
    //this.changeHandler(key, options.value);
  };

  deleteOptionHandler = (key) => {
    const formData = cloneDeep(this.state.formData);
    delete formData.options[key];
    this.setState({formData: formData});
    //this.changeHandler(key, options.value);
  };


  isFieldKeyUnique = (fieldKey) => {
    return this.props.fieldKeys.indexOf(fieldKey) === -1;
  };

  render() {
    //console.log('FieldEdit state', this.state);
    //console.log('fieldKeys', this.props.fieldKeys);
    //console.log('FieldEdit', this.state.formData.fieldLabel.value);
    let form = null;
    if (this.props.fieldGet.loading) {
      form = <Spinner size="sm" color="primary"/>;
    } else if (this.props.fieldGet.error) {
      form = <ErrorAlert error={this.props.fieldGet.error}/>;
    // if we have received data from api OR if the default form data has been set and there is no field id
    // } else if (!isEmpty(this.state.field) || (!isEmpty(this.state.formData) && this.props.fieldId === null)) {
    } else if (!isEmpty(this.props.fieldGet.data) || this.props.fieldId === null) {
      if (!isEmpty(this.props.lists.data)) {
          //console.log(this.state.formData);
        form = (
          <form onSubmit={this.submitHandler}>
            {this.state.formData.storageType.value === 'salesforce'
              ? <FormSalesforce deleteOptionHandler={this.deleteOptionHandler}
                                field={this.state.formData}
                                changeHandler={this.changeHandler}
                                lists={this.props.lists.data.attributes}
                                addOptionHandler={this.addOptionHandler}
                                surveyType={this.props.surveyType}/> : null}
            {this.state.formData.storageType.value === 'survey'
              ? <FormSurvey deleteOptionHandler={this.deleteOptionHandler}
                            field={this.state.formData}
                            changeHandler={this.changeHandler}
                            addOptionHandler={this.addOptionHandler}
                            surveyType={this.props.surveyType}/> : null}
            {this.state.formData.storageType.value === 'display'
              ? <FormDisplay field={this.state.formData}
                             changeHandler={this.changeHandler}
                             surveyType={this.props.surveyType}/> : null}
          </form>
        );
      } else {
        form = <Spinner size="sm" color="primary"/>;
      }
    }

    let alert = null;
    let loading = null;

    if (this.props.fieldPut.loading) {
      loading = <Spinner size="sm" color="primary"/>;
    } else if (this.props.fieldPut.error) {
      alert = <ErrorAlert error={this.props.fieldPut.error}/>;
    }

    if (this.props.fieldPost.loading) {
      loading = <Spinner size="sm" color="primary"/>;
    } else if (this.props.fieldPost.error) {
      alert = <ErrorAlert error={this.props.fieldPost.error}/>;
    }



    return (
      <Modal isOpen={true} size="lg" toggle={this.props.closeHandler}>
        <ModalHeader toggle={this.props.closeHandler}>{this.props.fieldId ? 'Edit' : 'New'} Field</ModalHeader>
        <ModalBody>
          {alert}
          {form}
        </ModalBody>
        <ModalFooter>
          {loading}
          <Button color="secondary"
                  onClick={this.props.closeHandler}
                  disabled={this.props.fieldPut.loading || this.props.fieldPost.loading}>Cancel</Button>
          <Button color="primary"
                  onClick={this.save}
                  disabled={this.props.fieldPut.loading || this.props.fieldPost.loading || this.state.formValid === false}
          >Save</Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }
}

FieldEdit.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired,
  surveyType: PropTypes.string.isRequired,
  fieldId: PropTypes.number
};

FieldEdit.defaultProps = {
  surveyId: null,
  fieldId: null
};

const mapStateToProps = state => {
  return {
    fieldGet: {
      data: state.surveysFieldsGet.data,
      error: state.surveysFieldsGet.error,
      loading: state.surveysFieldsGet.loading
    },
    fieldPut: {
      data: state.surveysFieldsPut.data,
      error: state.surveysFieldsPut.error,
      loading: state.surveysFieldsPut.loading
    },
    fieldPost: {
      data: state.surveysFieldsPost.data,
      error: state.surveysFieldsPost.error,
      loading: state.surveysFieldsPost.loading
    },
    lists: {
      data: state.listsList.data,
      error: state.listsList.error,
      loading: state.listsList.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSurveyField: (surveyId, fieldId, successCallback) => dispatch(actions.surveysFieldsGet(surveyId, fieldId, successCallback)),
    postSurveyField: (surveyId, data, success) => dispatch(actions.surveysFieldsPost(surveyId, data, success)),
    putSurveyField: (surveyId, fieldId, data, success) => dispatch(actions.surveysFieldsPut(surveyId, fieldId, data, success))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldEdit);


import React from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { MdMoreVert } from 'react-icons/md';

const messagesListItem = (props) => {

  const createdId  = uniqueId('created-');
  const modifiedId  = uniqueId('modified-');

  return (
    <tr>
      <td>
        {props.item.subject}
      </td>
      <td>{props.item.status}</td>
      <td>
        <span id={createdId}>{moment.utc(props.item.created).local().fromNow()}</span>.{' '}
        <UncontrolledTooltip placement="right" target={createdId}>
          {moment.utc(props.item.created).local().format('lll')}
        </UncontrolledTooltip>
      </td>
      <td>
        <span id={modifiedId}>{moment.utc(props.item.modified).local().fromNow()}</span>
        <UncontrolledTooltip placement="right" target={modifiedId}>
          {moment.utc(props.item.modified).local().format('lll')}
        </UncontrolledTooltip>
      </td>
      <td>
        {props.item.user.firstName} {props.item.user.lastName}
      </td>
      <td>
        {
          props.item.status === 'draft' ?
          <span className="float-right align-middle">
            <UncontrolledDropdown size="sm">
              <DropdownToggle outline>
                <MdMoreVert/>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={() => props.editHandler(props.item.id)}>Edit</DropdownItem>
                <DropdownItem onClick={() => props.deleteHandler(props.item)}>Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </span>
          : null
        }
      </td>
    </tr>
  );
};

messagesListItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default messagesListItem;
import React, { Component } from 'react';
import Navigation from '../common/Navigation';
import {
  Container,
  Card,
  NavItem,
  NavLink,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  Spinner, UncontrolledTooltip, Input, Button
} from 'reactstrap';
import classNames from 'classnames';
import SurveyInfoBasic from '../common/SurveyInfoBasic';
import recipientStatusTypes from '../../types/recipientStatusTypes';
import Footer from '../common/Footer';
import ErrorAlert from '../common/ErrorAlert';
import BackButton from '../common/BackButton';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import MailEvents from '../common/MailEvents';
import { orderBy, filter } from '../../utility';
import TableHeadingColumn from '../common/TableHeadingColumn';
import ConfirmModal from '../common/ConfirmModal';

class SurveyRecipients extends Component {

  state = {
    activeMessageId: null,
    filterBy: '',
    filter: 'pending',
    showMailEventsModal: false,
    orderBy: ['created'],
    orderByDirection: 'desc',
    recipientToDelete: null,
    showDeleteRecipientModal: false
  };

  componentDidMount() {
    this.props.surveysRecipientsList(this.props.match.params.surveyId, {status: this.state.filter});
  }

  orderBy = (fields) => {
    // if we are already sorting on this field
    if (isEqual(this.state.orderBy, fields)) {
      // reverse direction
      const newDirection = this.state.orderByDirection === 'asc' ? 'desc' : 'asc';
      this.setState({orderByDirection: newDirection});
    } else {
      // new sort fields
      this.setState({orderBy: fields});
    }
  };

  filterHandler = (e) => {
    this.setState({filterBy: e.target.value.toLowerCase()});
  };

  toggle(filter) {
    if (this.state.filter !== filter) {
      this.setState({
        filter: filter
      },() => this.props.surveysRecipientsList(this.props.match.params.surveyId, {status: this.state.filter}));
    }
  }

  toggleMailEventsModal = (messageId) => {
    this.setState((prevState) => ({
      showMailEventsModal: !prevState.showMailEventsModal,
      activeMessageId: messageId
    }));
  };

  toggleDeleteRecipientModal = () => {
    this.setState((prevState) => ({
      showDeleteRecipientModal: !prevState.showDeleteRecipientModal
    }));
  };

  deleteRecipientHandler = (recipient) => {
    this.setState({
      recipientToDelete: recipient
    }, () => this.toggleDeleteRecipientModal());
  };

  deleteRecipientConfirmHandler = () => {
    this.props.surveysRecipientsDelete(
      parseInt(this.props.match.params.surveyId),
      this.state.recipientToDelete.id,
      () => this.toggleDeleteRecipientModal()
    );
  };

  render() {
    const surveyId = parseInt(this.props.match.params.surveyId);
    let items = null;
    if (this.props.list.loading) {
      items = <tr><td colSpan={6} className="text-center"><Spinner size="sm"/></td></tr>
    } else if (this.props.list.error) {
      items = <tr><td colSpan={6} className="text-center"><ErrorAlert error={this.props.list.error}/></td></tr>
    } else if (!isEmpty(this.props.list.data)) {
      items = cloneDeep(this.props.list.data);
      items = orderBy(items, this.state.orderBy, this.state.orderByDirection);
      items = filter(items, this.state.filterBy);
      items = Object.keys(items).map(key => {
        const item = items[key].attributes;
        let actions = <Button outline size="sm" disabled>Actions</Button>;

        if (item.status === 'queued' || item.status === 'pending' || item.status === 'completed' || item.messageId) {
          actions = <UncontrolledDropdown size="sm">
                      <DropdownToggle caret outline>
                        Actions
                      </DropdownToggle>
                      <DropdownMenu right>
                        {item.status === 'completed' ?
                          <DropdownItem tag={Link} to={`/surveys/${surveyId}/submissions/${item.submissionId}/responses`}>View Submission</DropdownItem> : null}
                        {item.messageId ? <DropdownItem onClick={() => this.toggleMailEventsModal(item.messageId)}>View Mail Events</DropdownItem> : null}
                        {item.status === 'queued' || item.status === 'pending'
                          ? <DropdownItem onClick={() => this.deleteRecipientHandler(item)}>Delete Recipient</DropdownItem>
                          : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>;
        }
        return (
          <tr key={key}>
            <td>{item.communitechCompany.name}</td>
            <td>{item.firstName} {item.lastName}<br/>
              <small>{item.email}</small></td>
            <td>
              {recipientStatusTypes[item.status].title}
            </td>
            <td>
              {item.mailEvents.length > 0 ? item.mailEvents[0].event : 'None'}
            </td>
            <td>
              <span id={`created-${key}`}>{moment.utc(item.created).local().fromNow()}</span>
              <UncontrolledTooltip placement="right" target={`created-${key}`}>
                {moment.utc(item.created).local().format('lll')}
              </UncontrolledTooltip>
            </td>
            <td>
              <span id={`modified-${key}`}>{moment.utc(item.modified).local().fromNow()}</span>
              <UncontrolledTooltip placement="right" target={`modified-${key}`}>
                {moment.utc(item.modified).local().format('lll')}
              </UncontrolledTooltip>
            </td>
            <td>
              {actions}
            </td>
          </tr>
        );
      });
      if (items.length === 0) {
        items = <tr><td colSpan={6} className="text-center">No items found</td></tr>;
      }
    } else if (isEmpty(this.props.list.data)) {
      items = <tr><td colSpan={6} className="text-center">No recipients</td></tr>
    }

    const navItems = Object.keys(recipientStatusTypes).map(key => {
      return (
        <NavItem key={key}>
          <NavLink
            className={classNames({active: this.state.filter === key})}
            href="#"
            onClick={() => {
              this.toggle(key);
            }}
          >
            <span id={`nav-status-${key}`}>{recipientStatusTypes[key].title}</span>
            <UncontrolledTooltip placement="right" target={`nav-status-${key}`}>
              {recipientStatusTypes[key].helpText}
            </UncontrolledTooltip>
          </NavLink>
        </NavItem>
      );
    });

    return (
      <section>
        <Navigation/>
        <Container fluid>
          <BackButton goBack={this.props.history.goBack}/>
          <h1>Survey Recipients</h1>

          <Card body>
            <SurveyInfoBasic surveyId={parseInt(this.props.match.params.surveyId)}/>
          </Card>

          <Nav tabs className="mb-3 mt-3">
            {navItems}
          </Nav>

          <Input className="col-md-6 float-right mb-3" type="text" onChange={this.filterHandler} placeholder="Filter"/>

          <Table className="mt-3">
            <thead>
              <tr>
                <TableHeadingColumn orderBy={['communitechCompany.name']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Company</TableHeadingColumn>
                <TableHeadingColumn orderBy={['lastName', 'firstName']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Name</TableHeadingColumn>
                <TableHeadingColumn orderBy={['status']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Status</TableHeadingColumn>
                <TableHeadingColumn orderBy={['mailEvents.event']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Recent Mail Event</TableHeadingColumn>
                <TableHeadingColumn orderBy={['created']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Created</TableHeadingColumn>
                <TableHeadingColumn orderBy={['modified']}
                                    orderByHandler={this.orderBy}
                                    currentOrderBy={this.state.orderBy}
                                    currentOrderByDirection={this.state.orderByDirection}>Modified</TableHeadingColumn>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items}
            </tbody>
          </Table>


        </Container>
        <Footer/>
        {
          this.state.showMailEventsModal
          ? <MailEvents messageId={this.state.activeMessageId} closeHandler={this.toggleMailEventsModal}/>
          : null
        }
        {this.state.showDeleteRecipientModal ?
          <ConfirmModal title="Delete recipient?"
                        buttonConfirmLabel="Delete"
                        buttonConfirmColor="danger"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteRecipientConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteRecipientModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
              <span>
                Are you sure you want to delete the recipient <strong>{this.state.recipientToDelete.email}</strong>?
                <br/><br/>
                Note: You can re-add this recipient later if needed.
              </span>
          </ConfirmModal> : null
        }
      </section>
    );
  }
}

SurveyRecipients.propTypes = {
  //surveyId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    list: {
      data: state.surveysRecipientsList.data,
      error: state.surveysRecipientsList.error,
      loading: state.surveysRecipientsList.loading
    },
    delete: {
      data: state.surveysRecipientsDelete.data,
      error: state.surveysRecipientsDelete.error,
      loading: state.surveysRecipientsDelete.loading
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    surveysRecipientsList: (surveyId, filter) => dispatch(actions.surveysRecipientsList(surveyId, filter)),
    surveysRecipientsDelete: (surveyId, recipientId, successCallback) => dispatch(actions.surveysRecipientsDelete(surveyId, recipientId, successCallback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyRecipients);

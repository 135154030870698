import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const submissionsFeedbackDelete = (submissionId, feedbackId, successCallback) => {
  return dispatch => {
    dispatch(submissionsFeedbackDeleteStart());

    axios.delete(`/submissions/${submissionId}/feedback/${feedbackId}`)
      .then(response => {
        dispatch(submissionsFeedbackDeleteSuccess(response.data.data));
        dispatch(submissionsFeedbackList(submissionId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('submissionsFeedbackDeleteFailure', error);
        dispatch(submissionsFeedbackDeleteFailure(error));
      });
  };
};

export const submissionsFeedbackDeleteStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_DELETE_START
  };
};

export const submissionsFeedbackDeleteSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_DELETE_SUCCESS,
    data: data
  };
};

export const submissionsFeedbackDeleteFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_DELETE_FAILURE,
    error: error
  };
};

export const submissionsFeedbackGet = (submissionId, feedbackId, params) => {
  return dispatch => {
    dispatch(submissionsFeedbackGetStart());

    axios.get(`/submissions/${submissionId}/feedback/${feedbackId}`, {params: params})
      .then(response => {
        dispatch(submissionsFeedbackGetSuccess(response.data.data));
      })
      .catch(error => {
        console.error('submissionsFeedbackGetFailure', error);
        dispatch(submissionsFeedbackGetFailure(error));
      });
  };
};

export const submissionsFeedbackGetStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_GET_START
  };
};

export const submissionsFeedbackGetSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_GET_SUCCESS,
    data: data
  };
};

export const submissionsFeedbackGetFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_GET_FAILURE,
    error: error
  };
};

export const submissionsFeedbackList = (submissionId, params) => {
  return dispatch => {
    dispatch(submissionsFeedbackListStart());

    axios.get(`/submissions/${submissionId}/feedback`, {params: params})
      .then(response => {
        dispatch(submissionsFeedbackListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('submissionsFeedbackListFailure', error);
        dispatch(submissionsFeedbackListFailure(error));
      });
  };
};

export const submissionsFeedbackListStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_LIST_START
  };
};

export const submissionsFeedbackListSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_LIST_SUCCESS,
    data: data
  };
};

export const submissionsFeedbackListFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_LIST_FAILURE,
    error: error
  };
};

export const submissionsFeedbackPatch = (submissionId, feedbackId, data, successCallback) => {
  return dispatch => {
    dispatch(submissionsFeedbackPatchStart());
    data = {
      data: {
        id: feedbackId,
        type: 'submissionFeedback',
        attributes: data
      }
    };
    axios.patch(`/submissions/${submissionId}/feedback/${feedbackId}`, data)
      .then(response => {
        dispatch(submissionsFeedbackPatchSuccess(response.data.data));
        dispatch(submissionsFeedbackList(submissionId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('submissionsFeedbackPatchFailure', error);
        dispatch(submissionsFeedbackPatchFailure(error));
      });
  };
};

export const submissionsFeedbackPatchStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_PATCH_START
  };
};

export const submissionsFeedbackPatchSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_PATCH_SUCCESS,
    data: data
  };
};

export const submissionsFeedbackPatchFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_PATCH_FAILURE,
    error: error
  };
};

export const submissionsFeedbackPost = (submissionId, data, successCallback) => {
  return dispatch => {
    dispatch(submissionsFeedbackPostStart());
    data = {
      data: {
        type: 'submissionFeedback',
        id: null,
        attributes: data
      }
    };
    axios.post(`/submissions/${submissionId}/feedback`, data)
      .then(response => {
        dispatch(submissionsFeedbackPostSuccess(response.data.data));
        dispatch(submissionsFeedbackList(submissionId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('submissionsFeedbackPostFailure', error);
        dispatch(submissionsFeedbackPostFailure(error));
      });
  };
};

export const submissionsFeedbackPostStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_POST_START
  };
};

export const submissionsFeedbackPostSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_POST_SUCCESS,
    data: data
  };
};

export const submissionsFeedbackPostFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_FEEDBACK_POST_FAILURE,
    error: error
  };
};



import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const bucketProfilesDelete = (bucketProfileId, successCallback, params) => {
  return dispatch => {
    dispatch(bucketProfilesDeleteStart());

    axios.delete(`/bucket-profiles/${bucketProfileId}`, {params: params})
      .then(response => {
        dispatch(bucketProfilesDeleteSuccess(response.data.data));
        dispatch(bucketProfilesList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketProfilesDeleteFailure', error);
        dispatch(bucketProfilesDeleteFailure(error));
      });
  };
};

export const bucketProfilesDeleteClear = () => {
  return {
    type: actionTypes.BUCKET_PROFILES_DELETE_CLEAR
  };
};

export const bucketProfilesDeleteStart = () => {
  return {
    type: actionTypes.BUCKET_PROFILES_DELETE_START
  };
};

export const bucketProfilesDeleteSuccess = (data) => {
  return {
    type: actionTypes.BUCKET_PROFILES_DELETE_SUCCESS,
    data: data
  };
};

export const bucketProfilesDeleteFailure = (error) => {
  return {
    type: actionTypes.BUCKET_PROFILES_DELETE_FAILURE,
    error: error
  };
};


export const bucketProfilesList = (params) => {
  return dispatch => {
    dispatch(bucketProfilesListStart());

    axios.get('/bucket-profiles', {params: params})
      .then(response => {
        dispatch(bucketProfilesListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('bucketProfilesListFailure', error);
        dispatch(bucketProfilesListFailure(error));
      });
  };
};

export const bucketProfilesListStart = () => {
  return {
    type: actionTypes.BUCKET_PROFILES_LIST_START
  };
};

export const bucketProfilesListSuccess = (data) => {
  return {
    type: actionTypes.BUCKET_PROFILES_LIST_SUCCESS,
    data: data
  };
};

export const bucketProfilesListFailure = (error) => {
  return {
    type: actionTypes.BUCKET_PROFILES_LIST_FAILURE,
    error: error
  };
};

export const bucketProfilesPatch = (bucketProfileId, data, successCallback) => {
  return dispatch => {
    dispatch(bucketProfilesPatchStart());
    
    data = {
      data: {
        attributes: data
      }
    };
    
    axios.patch(`/bucket-profiles/${bucketProfileId}`, data)
      .then(response => {
        dispatch(bucketProfilesPatchSuccess(response.data.data));
        dispatch(bucketProfilesList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketProfilesPatchFailure', error);
        dispatch(bucketProfilesPatchFailure(error));
      });
  };
};

export const bucketProfilesPatchStart = () => {
  return {
    type: actionTypes.BUCKET_PROFILES_PATCH_START
  };
};

export const bucketProfilesPatchSuccess = (data) => {
  return {
    type: actionTypes.BUCKET_PROFILES_PATCH_SUCCESS,
    data: data
  };
};

export const bucketProfilesPatchFailure = (error) => {
  return {
    type: actionTypes.BUCKET_PROFILES_PATCH_FAILURE,
    error: error
  };
};

export const bucketProfilesPost = (data, successCallback) => {
  return dispatch => {
    dispatch(bucketProfilesPostStart());
    
    data = {
      data: {
        attributes: data
      }
    };
    
    axios.post(`/bucket-profiles`, data)
      .then(response => {
        dispatch(bucketProfilesPostSuccess(response.data.data));
        dispatch(bucketProfilesList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('bucketProfilesPatchFailure', error);
        dispatch(bucketProfilesPostFailure(error));
      });
  };
};

export const bucketProfilesPostStart = () => {
  return {
    type: actionTypes.BUCKET_PROFILES_POST_START
  };
};

export const bucketProfilesPostSuccess = (data) => {
  return {
    type: actionTypes.BUCKET_PROFILES_POST_SUCCESS,
    data: data
  };
};

export const bucketProfilesPostFailure = (error) => {
  return {
    type: actionTypes.BUCKET_PROFILES_POST_FAILURE,
    error: error
  };
};



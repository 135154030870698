import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap';
import PropTypes from "prop-types";
import ErrorAlert from "./ErrorAlert";

const confirmModal = (props) => {
  let loading = null;
  let error = null;
  
  // handle loading state
  if (props.loading === true) {
    loading = <Spinner size="sm" color="primary"/>;

    // handle error
  } else if (props.error) {
    error = <ErrorAlert error={props.error}/>;
  }

  return (
    <Modal isOpen={true} backdrop="static" toggle={props.buttonCancelHandler} className={props.className}>
      <ModalHeader toggle={props.buttonCancelHandler}>{props.title}</ModalHeader>
      <ModalBody>
        {error}
        {props.body}
        {props.children}
      </ModalBody>
      <ModalFooter>
        {loading}
        <Button color={props.buttonCancelColor}
                disabled={props.loading}
                onClick={props.buttonCancelHandler}>{props.buttonCancelLabel}</Button>
        <Button color={props.buttonConfirmColor}
                disabled={props.loading}
                onClick={props.buttonConfirmHandler}>{props.buttonConfirmLabel}</Button>{' '}
      </ModalFooter>
    </Modal>
  );
};


confirmModal.defaultProps = {
  body: null,
  title: 'Confirm',
  buttonConfirmLabel: 'Yes',
  buttonCancelLabel: 'No',
  buttonConfirmHandler: PropTypes.func.isRequired,
  buttonCancelHandler: PropTypes.func.isRequired,
  buttonConfirmColor: 'primary',
  buttonCancelColor: 'secondary',
  error: null,
  loading: false
};


confirmModal.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  buttonConfirmLabel: PropTypes.string,
  buttonCancelLabel: PropTypes.string,
  buttonConfirmHandler: PropTypes.func.isRequired,
  buttonCancelHandler: PropTypes.func,
  error: PropTypes.object,
  loading: PropTypes.bool
};

export default confirmModal;
import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const bucketsActionsPost = (data) => {
  return dispatch => {
    dispatch(bucketsActionsPostStart());

    data = {
      data: {
        attributes: data
      }
    };

    axios.post(`/buckets/actions`, data)
      .then(response => {
        dispatch(bucketsActionsPostSuccess(response.data.data));
        //dispatch(bucketsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('bucketsActionsPostFailure', error);
        dispatch(bucketsActionsPostFailure(error));
      });
  };
};

export const bucketsActionsPostStart = () => {
  return {
    type: actionTypes.BUCKETS_ACTIONS_POST_START
  };
};

export const bucketsActionsPostSuccess = (data) => {
  return {
    type: actionTypes.BUCKETS_ACTIONS_POST_SUCCESS,
    data: data
  };
};

export const bucketsActionsPostFailure = (error) => {
  return {
    type: actionTypes.BUCKETS_ACTIONS_POST_FAILURE,
    error: error
  };
};



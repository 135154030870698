import React from 'react';
import {Input, FormGroup, Label, FormText, InputGroupAddon, Button, InputGroup} from 'reactstrap';
import PreQuestionLabel from "./Fields/PreQuestionLabel";
import PreQuestionHelpText from "./Fields/PreQuestionHelpText";
import Required from "./Fields/Required";
import StorageType from "./Fields/StorageType";
import {MdDelete} from 'react-icons/md';
import MergeTagsHelp from '../../../common/MergeTagsHelp';
import FormFeedback from 'reactstrap/es/FormFeedback';
import { cloneDeep } from 'lodash';

const fieldTypes = {
  '':{
    label: 'Choose a field type',
    hasOptions: false,
    disabled: true
  },
  'company-name' : {
    label: 'Company Name (Autocomplete)',
    hasOptions: false
  },
  'checkbox-multi-select' : {
    label: 'Checkboxes (Multiple Selections)',
    hasOptions: true
  },
  'checkbox': {
    label: 'Checkbox',
    hasOptions: false
  },
  'text': {
    label: 'Text',
    hasOptions: false
  },
  'textarea': {
    label: 'Long Text',
    hasOptions: false
  },
  'select-radio': {
    label: 'Radio',
    hasOptions: true
  },
  'integer': {
    label: 'Number',
    hasOptions: false
  },
  'attachment': {
    label: 'File Attachment',
    hasOptions: false
  },
  'rating-radio': {
    label: 'Rating Radio',
    hasOptions: false
  }
};

// this should be pulled in from api


const formSurvey = (props) => {
  let filteredFieldTypes = cloneDeep(fieldTypes);
  if (props.surveyType !== 'request') {
    // filter out company-name field type
    delete filteredFieldTypes['company-name'];
  }

  const fieldTypeOptions = Object.keys(filteredFieldTypes).map(key => (
    <option key={key} value={key} disabled={fieldTypes[key].disabled}>{fieldTypes[key].label}</option>
  ));

  const preQuestionFields = (
    <div>
      <PreQuestionLabel field={props.field} changeHandler={props.changeHandler}/>
      <PreQuestionHelpText field={props.field} changeHandler={props.changeHandler}/>
    </div>
  );

  const options = Object.keys(props.field.options).map(key => (
    <InputGroup key={key}>
      <Input
        invalid={props.field.options[key].touched && !props.field.options[key].valid}
        value={props.field.options[key].value}
        valid={props.field.options[key].touched && props.field.options[key].valid}
        onChange={e => props.changeHandler(`options.${key}`, e.target.value)}/>
      <InputGroupAddon addonType="append">
        <Button outline size="sm" onClick={e => props.deleteOptionHandler(key)}><MdDelete/></Button>
      </InputGroupAddon>
    </InputGroup>
  ));

  const showOptions = fieldTypes[props.field.fieldType.value].hasOptions;

  return (
    <section>
      <StorageType field={props.field} changeHandler={props.changeHandler} surveyType={props.surveyType}/>
      <FormGroup>
        <Label for="fieldType">Field Type</Label>
        <Input id="fieldType"
               invalid={props.field.fieldType.touched && !props.field.fieldType.valid}
               onChange={e => props.changeHandler('fieldType', e.target.value)}
               type="select"
               valid={props.field.fieldType.touched && props.field.fieldType.valid}
               value={props.field.fieldType.value}>
          {fieldTypeOptions}
        </Input>
        <FormText color="muted">
          What type of data are you trying to collect?
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="label">Question</Label>
        <Input id="label"
               invalid={props.field.fieldLabel.touched && !props.field.fieldLabel.valid}
               onChange={e => props.changeHandler('fieldLabel', e.target.value)}
               placeholder="What are your three goals for the next quarter?"
               type="text"
               valid={props.field.fieldLabel.touched && props.field.fieldLabel.valid}
               value={props.field.fieldLabel.value}/>
        <FormText color="muted">
          The question that is displayed to the user. eg. What are your three goals for the next quarter?
          <MergeTagsHelp/>
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="key">Question Key/Name</Label>
          <Input id="key"
                 invalid={props.field.fieldKey.touched && !props.field.fieldKey.valid}
                 onChange={e => props.changeHandler('fieldKey', e.target.value)}
                 placeholder="three-goals"
                 type="text"
                 valid={props.field.fieldKey.touched && props.field.fieldKey.valid}
                 value={props.field.fieldKey.value}/>
        <FormText color="muted">
          The value you want for your column heading when viewing a spreadsheet. Letters, Numbers and Dashes only.
          Please separate words with dashes! eg. vc-ready or funding-heading
        </FormText>
        <FormFeedback>Make sure you use a unique key/name.</FormFeedback>
      </FormGroup>

      {
        showOptions
        ? <FormGroup>
            <Label for="helpText">Options</Label>
            <FormText color="muted">
              Use the button below to add selection options to this form element.
            </FormText>
            {options}
            <Button outline size="sm" onClick={props.addOptionHandler}>Add item</Button>
          </FormGroup>
        : null
      }

      <FormGroup>
        <Label for="helpText">Help text</Label>
        <Input id="helpText"
               invalid={props.field.helpText.touched && !props.field.helpText.valid}
               onChange={e => props.changeHandler('helpText', e.target.value)}
               placeholder="Please be descriptive in adding your 3 goals."
               type="textarea"
               valid={props.field.helpText.touched && props.field.helpText.valid}
               value={props.field.helpText.value || ''}/>
        <FormText color="muted">
          A short description that shows under the question. eg. Please be descriptive in adding your 3 goals.
          <MergeTagsHelp/>
        </FormText>
      </FormGroup>

      <Required field={props.field} changeHandler={props.changeHandler}/>

      {!props.field.validation.required.value ? preQuestionFields : null}

    </section>

  );
};


export default formSurvey;
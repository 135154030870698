import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Table, Alert } from 'reactstrap';
import CsvReader from '../../common/CsvReader';
import { emailIsValid, filter } from '../../../utility';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../common/ConfirmModal';

class Recipients extends Component
{
  state = {
    contacts: [],
    csvMessage: '',
    csvColor: '',
    filter: '',
    showSaveCsvModal: false
  };

  handleData = data => {
    const expectedNumberOfColumns = 5;

    console.log('CSV Data', data);
    // make sure we have at least 2 lines in our file, header + data row
    if (data.length < 2) {
      this.setState({
        csvMessage: 'The uploaded CSV file is too short. The first line of the CSV file should contain column headings and should contain at least 1 row of data.',
        csvColor: 'danger'
      });
      return;
    }
    data = data.slice(1);  // remove header line

    let error = false;
    const contacts = Object.keys(data).map(key => {
      if (data[key].length !== expectedNumberOfColumns) {
        const row = parseInt(key, 10) + 1;
        error = true;
        this.setState({
          csvMessage: `Row ${row} in the CSV file is too short. Each row should contain ${expectedNumberOfColumns} columns. The CSV file must have the following columns Company ID, Company Name, Recipient First Name, Recipient Last Name, and Recipient Email.`,
          csvColor: 'danger'
        });
        return null;
      }

      // make sure all the columns contain data
      data[key].map((column, index) => {
        if (!column) {
          error = true;
          const row = parseInt(key, 10) + 1;
          this.setState({
            csvMessage: `Row ${row}, column ${index} in the CSV file is empty. Not cool.`,
            csvColor: 'danger'
          });
        }
        return null;
      });

      // trim each cell in csv data
      data[key] = data[key].map((column, index) => {
        return column.trim();
      });

      // make sure all email addresses are valid
      if (!emailIsValid(data[key][4])) {
        error = true;
        const row = parseInt(key, 10) + 1;
        this.setState({
          csvMessage: `Row ${row} looks like it contains an invalid email. Are you sure "${data[key][4]}" is a valid email address?`,
          csvColor: 'danger'
        });
        return null;
      }

      return {
        companyId: data[key][0],
        companyName: data[key][1],
        personFirstName: data[key][2],
        personLastName: data[key][3],
        personEmail: data[key][4]
      }
    });

    if (error === false) {
      this.setState({
        contacts: contacts,
        csvMessage: 'So far it looks like your CSV file contains good data but please double check the imported data below. Now is the time to make changes.',
        csvColor: 'warning'
      });
    }
  };

  errorHandler = (error) => {
    console.log(error);
    this.setState({csvMessage: 'Could not parse CSV file. Please double check your CSV file and try again.'});
  };

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  saveHandler = () => {
    this.props.surveysRecipientsListPost(
      this.props.surveyId,
      {contacts: this.state.contacts},
      () => {
        this.toggleSaveCsvModal();
        this.setState({contacts: [], csvMessage: 'Your recipients have been saved.', csvColor: 'success'});
      });
  };

  toggleSaveCsvModal = () => {
    this.setState((prevState) => ({
      showSaveCsvModal: !prevState.showSaveCsvModal
    }));
    this.props.post.error = null; // clear error message
  };

  toggleCancelCsv = () => {
    this.setState({
      contacts: [],
      csvMessage: ''
    });
  };

  render() {
    let items = null;

    if (this.state.contacts.length > 0) {
      items = cloneDeep(this.state.contacts);
      items = filter(items, this.state.filter);
      items = Object.keys(items).map(key => (
        <tr key={key}>
          <td>{items[key].companyId}</td>
          <td>{items[key].companyName}</td>
          <td>{items[key].personFirstName}</td>
          <td>{items[key].personLastName}</td>
          <td>{items[key].personEmail}</td>
        </tr>
      ));
      if (items.length === 0) {
        items = <tr><td colSpan={4} className="text-center">No items found</td></tr>;
      }
    } else if (this.state.error) {
      items = <tr><td colSpan={4} className="text-center">Could not parse CSV file</td></tr>;
    } else {
      items = <tr><td colSpan={4} className="text-center">No contacts loaded</td></tr>;
    }

    return (
      <section>
        <h2>Recipients</h2>
        <p>Use the form below to upload a CSV file of people this survey should be sent to.</p>
        <p>If you already have uploaded and saved a list of recipients this list will be added to your existing list.</p>
        <p>CSV file should be in the following format: [Company ID],[Company Name],[Recipient First Name],[Recipient Last Name],[Recipient Email]</p>
        <CsvReader
          cssClass="form-group"
          cssInputClass="form-control-file"
          label="Select CSV of contacts"
          onFileLoaded={this.handleData}
          onError={this.errorHandler}
          inputId="csv-reader"
        />
        {this.state.csvMessage ? <Alert color={this.state.csvColor}>{this.state.csvMessage}</Alert> : null}
        {
          this.state.contacts.length > 0
          ? <React.Fragment>
              <div className="mt-3 mb-3">
                <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
              </div>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Company ID</th>
                    <th>Company Name</th>
                    <th>Recipient First Name</th>
                    <th>Recipient Last Name</th>
                    <th>Recipient Email</th>
                  </tr>
                </thead>
                <tbody>
                  {items}
                </tbody>
              </Table>
              <Button color="secondary" onClick={this.toggleCancelCsv}>Cancel</Button>{' '}
              <Button color="primary" disabled={this.props.post.loading} onClick={this.toggleSaveCsvModal}>Save</Button>
              {this.state.showSaveCsvModal ?
                <ConfirmModal title="Save recipients?"
                              buttonConfirmLabel="Save"
                              buttonConfirmColor="primary"
                              buttonCancelLabel="Cancel"
                              buttonConfirmHandler={this.saveHandler}
                              buttonCancelHandler={this.toggleSaveCsvModal}
                              loading={this.props.post.loading}
                              error={this.props.post.error}>
              <span>
                Are you sure you want to save these recipients?
              </span>
                </ConfirmModal> : null
              }
            </React.Fragment>
          : <React.Fragment>
              <p>See who this survey will go out to or who it has been sent to already.</p>
              <Button color="primary" tag={Link} to={`/surveys/${this.props.surveyId}/recipients`}>View Recipients</Button>
            </React.Fragment>
        }
      </section>
    );
  }
}

Recipients.propTypes = {
  surveyId: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    post: {
      data: state.surveysRecipientsListPost.data,
      error: state.surveysRecipientsListPost.error,
      loading: state.surveysRecipientsListPost.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    surveysRecipientsListPost: (surveyId, data, callback) => dispatch(actions.surveysRecipientsListPost(surveyId, data, callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recipients);

import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { MdMoreVert } from 'react-icons/md';

const feedbackListItem = (props) => {

  const createdId  = uniqueId('created-');
  const createdId2  = uniqueId('created2-');
  const modifiedId  = uniqueId('modified-');

  const htmlContent = () => {
    return {__html: props.item.content}
  };

  return (
    <Card>
      <CardHeader>
        <strong>{props.item.user.firstName} {props.item.user.lastName}</strong> {' '}
        <span id={createdId}>{moment.utc(props.item.created).local().fromNow()}</span>
        <UncontrolledTooltip placement="right" target={createdId}>
          {moment.utc(props.item.created).local().format('lll')}
        </UncontrolledTooltip>

        <span className="float-right align-middle">
          <UncontrolledDropdown size="sm">
            <DropdownToggle outline>
              <MdMoreVert/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => props.editHandler(props.item.id)}>Edit</DropdownItem>
              <DropdownItem onClick={() => props.deleteHandler(props.item)}>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </span>
      </CardHeader>
      <CardBody>
        <p dangerouslySetInnerHTML={htmlContent()}/>
        <p><small><em>
          Created by {props.item.user.firstName} {props.item.user.lastName}{' '}
          <span id={createdId2}>{moment.utc(props.item.created).local().fromNow()}</span>.{' '}
          <UncontrolledTooltip placement="right" target={createdId2}>
            {moment.utc(props.item.created).local().format('lll')}
          </UncontrolledTooltip>
          {
            props.item.created !== props.item.modified ?
              <React.Fragment>
                  Modified <span id={modifiedId}>{moment.utc(props.item.modified).local().fromNow()}</span>
                <UncontrolledTooltip placement="right" target={modifiedId}>
                  {moment.utc(props.item.modified).local().format('lll')}
                </UncontrolledTooltip>
              </React.Fragment>
            : null
          }
        </em></small></p>
      </CardBody>
    </Card>
  );
};

feedbackListItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default feedbackListItem;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketProfilesDeleteClear = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: false,
    data: []
  });
};

const bucketProfilesDeleteStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketProfilesDeleteSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketProfilesDeleteFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKET_PROFILES_DELETE_CLEAR: return bucketProfilesDeleteClear(state, action);
    case actionTypes.BUCKET_PROFILES_DELETE_START: return bucketProfilesDeleteStart(state, action);
    case actionTypes.BUCKET_PROFILES_DELETE_SUCCESS: return bucketProfilesDeleteSuccess(state, action);
    case actionTypes.BUCKET_PROFILES_DELETE_FAILURE: return bucketProfilesDeleteFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketsPatchStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketsPatchSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketsPatchFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKETS_PATCH_START: return bucketsPatchStart(state, action);
    case actionTypes.BUCKETS_PATCH_SUCCESS: return bucketsPatchSuccess(state, action);
    case actionTypes.BUCKETS_PATCH_FAILURE: return bucketsPatchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
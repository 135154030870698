import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import { Spinner, UncontrolledTooltip, Button } from 'reactstrap';
import ErrorAlert from '../../common/ErrorAlert';
import {isEmpty} from 'lodash';
import moment from 'moment';

class SubmissionInfoBasic extends Component
{
  state = {
    showTechnicalInfo: false
  };

  componentDidMount() {
    if (isEmpty(this.props.data) || this.props.data.id !== this.props.submissionId) {
      this.props.submissionsGet(this.props.submissionId);
    }
  }

  toggleTechnicalInfo = () => {
    this.setState(prevState => {
      return {showTechnicalInfo: !prevState.showTechnicalInfo}
    });
  };

  render() {
    let surveyInfo = null;
    if (this.props.loading) {
      surveyInfo = <Spinner size="sm" color="primary"/>;
    } else if (this.props.error) {
      surveyInfo = <ErrorAlert error={this.props.error}/>;
    } else if (!isEmpty(this.props.data)) {
      surveyInfo = (
        <dl className="row mb-0">
          <dt className="col-md-4">Bucket</dt>
          <dd className="col-md-8">{this.props.data.attributes.bucket}</dd>
          {
            this.props.data.attributes.communitechCompany
            ? <React.Fragment>
                <dt className="col-md-4">Company</dt>
                <dd className="col-md-8">{this.props.data.attributes.communitechCompany.name}</dd>
              </React.Fragment>
            : null
          }
          {
            this.props.data.attributes.salesforcePeople
            ? <React.Fragment>
                <dt className="col-md-4">Person</dt>
                <dd className="col-md-8">{this.props.data.attributes.salesforcePeople.firstName} {this.props.data.attributes.salesforcePeople.lastName}</dd>
              </React.Fragment>
            : null
          }
          {
            this.props.data.attributes.bucket === 'approved' || this.props.data.attributes.bucket === 'declined'
              ? <React.Fragment>
                <dt className="col-md-4">Decision Note</dt>
                <dd className="col-md-8">{this.props.data.attributes.decisionNote}</dd>
              </React.Fragment>
              : null
          }
          {
            this.props.data.attributes.bucket === 'declined'
            ? <React.Fragment>
                <dt className="col-md-4">Decision Reason</dt>
                <dd className="col-md-8">{this.props.data.attributes.decisionReason}</dd>
              </React.Fragment>
            : null
          }

          <dt className="col-md-4">Created</dt>
          <dd className="col-md-8">
            <span
              id="submission-info-basic-created">{moment.utc(this.props.data.attributes.created).local().fromNow()}</span>
            <UncontrolledTooltip placement="right" target="submission-info-basic-created">
              {moment.utc(this.props.data.attributes.created).local().format('lll')}
            </UncontrolledTooltip>
          </dd>

          <dt className="col-md-4">Last modified</dt>
          <dd className="col-md-8">
            <span
              id="submission-info-basic-modified">{moment.utc(this.props.data.attributes.modified).local().fromNow()}</span>{' '}
            <UncontrolledTooltip placement="right" target="submission-info-basic-modified">
              {moment.utc(this.props.data.attributes.modified).local().format('lll')}
            </UncontrolledTooltip>
          </dd>
          <dt className="col-md-4">&nbsp;</dt>
          <dd className="col-md-8">
            <Button size="sm" outline onClick={this.toggleTechnicalInfo}>{this.state.showTechnicalInfo ? 'Hide' : 'Show'} Technical Info</Button>
          </dd>
          {
            this.state.showTechnicalInfo === true
              ? <React.Fragment>
                <dt className="col-md-4">IP Address</dt>
                <dd className="col-md-8">{this.props.data.attributes.ipAddress}</dd>
                <dt className="col-md-4">User Agent</dt>
                <dd className="col-md-8">{this.props.data.attributes.userAgent}</dd>
              </React.Fragment>
            : null
          }

        </dl>
      );
    }

    return (
      <section>
        {surveyInfo}
      </section>
    );
  }
}

SubmissionInfoBasic.propTypes = {
  submissionId: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    data: state.submissionsGet.data,
    error: state.submissionsGet.error,
    loading: state.submissionsGet.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submissionsGet: (submissionId) => dispatch(actions.submissionsGet(submissionId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionInfoBasic);


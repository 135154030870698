import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';


import App from './App';
import * as serviceWorker from './serviceWorker';

// import our reducers
import authReducer from './store/reducers/auth';
import bucketProfilesDeleteReducer from './store/reducers/bucketProfilesDelete';
import bucketProfilesListReducer from './store/reducers/bucketProfilesList';
import bucketProfilesPatchReducer from './store/reducers/bucketProfilesPatch';
import bucketProfilesPostReducer from './store/reducers/bucketProfilesPost';
import bucketsDeleteReducer from './store/reducers/bucketsDelete';
import bucketsListReducer from './store/reducers/bucketsList';
import bucketsPatchReducer from './store/reducers/bucketsPatch';
import bucketsPostReducer from './store/reducers/bucketsPost';
import bucketsActionsPostReducer from './store/reducers/bucketsActionsPost';
import listsListReducer from './store/reducers/listsList';
import mailEventsListReducer from './store/reducers/mailEventsList';
import messagesDeleteReducer from './store/reducers/messagesDelete';
import messagesGetReducer from './store/reducers/messagesGet';
import messagesListReducer from './store/reducers/messagesList';
import messagesPatchReducer from './store/reducers/messagesPatch';
import messagesPostReducer from './store/reducers/messagesPost';
import submissionsGetReducer from './store/reducers/submissionsGet';
import submissionsListReducer from './store/reducers/submissionsList';
import submissionsPatchReducer from './store/reducers/submissionsPatch';
import submissionsActionsPostReducer from './store/reducers/submissionsActionsPost';
import submissionsFeedbackDeleteReducer from './store/reducers/submissionsFeedbackDelete';
import submissionsFeedbackGetReducer from './store/reducers/submissionsFeedbackGet';
import submissionsFeedbackListReducer from './store/reducers/submissionsFeedbackList';
import submissionsFeedbackPatchReducer from './store/reducers/submissionsFeedbackPatch';
import submissionsFeedbackPostReducer from './store/reducers/submissionsFeedbackPost';
import submissionsRatingsListReducer from './store/reducers/submissionsRatingsList';
import submissionsRatingsPostReducer from './store/reducers/submissionsRatingsPost';
import submissionsResponsesListReducer from './store/reducers/submissionsResponsesList';
import surveysDeleteReducer from './store/reducers/surveysDelete';
import surveysListReducer from './store/reducers/surveysList';
import surveysGetReducer from './store/reducers/surveysGet';
import surveysPatchReducer from './store/reducers/surveysPatch';
import surveysPostReducer from './store/reducers/surveysPost';
import surveysActionsPostReducer from './store/reducers/surveysActionsPost';
import surveysFieldsDeleteReducer from './store/reducers/surveysFieldsDelete';
import surveysFieldsGetReducer from './store/reducers/surveysFieldsGet';
import surveysFieldsListReducer from './store/reducers/surveysFieldsList';
import surveysRecipientsDeleteReducer from './store/reducers/surveysRecipientsDelete';
import surveysRecipientsListReducer from './store/reducers/surveysRecipientsList';
import surveysRecipientsListPostReducer from './store/reducers/surveysRecipientsListPost';
import surveysFieldsPostReducer from './store/reducers/surveysFieldsPost';
import surveysFieldsPutReducer from './store/reducers/surveysFieldsPut';
import usersDeleteReducer from './store/reducers/usersDelete';
import usersListReducer from './store/reducers/usersList';
import usersPostReducer from './store/reducers/usersPost';

// import global css
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// setup reducers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  bucketProfilesDelete: bucketProfilesDeleteReducer,
  bucketProfilesList: bucketProfilesListReducer,
  bucketProfilesPatch: bucketProfilesPatchReducer,
  bucketProfilesPost: bucketProfilesPostReducer,
  bucketsDelete: bucketsDeleteReducer,
  bucketsList: bucketsListReducer,
  bucketsPatch: bucketsPatchReducer,
  bucketsPost: bucketsPostReducer,
  bucketsActionsPost: bucketsActionsPostReducer,
  listsList: listsListReducer,
  mailEventsList: mailEventsListReducer,
  messagesDelete: messagesDeleteReducer,
  messagesGet: messagesGetReducer,
  messagesList: messagesListReducer,
  messagesPatch: messagesPatchReducer,
  messagesPost: messagesPostReducer,
  submissionsGet: submissionsGetReducer,
  submissionsList: submissionsListReducer,
  submissionsPatch: submissionsPatchReducer,
  submissionsActionsPost: submissionsActionsPostReducer,
  submissionsFeedbackDelete: submissionsFeedbackDeleteReducer,
  submissionsFeedbackGet: submissionsFeedbackGetReducer,
  submissionsFeedbackList: submissionsFeedbackListReducer,
  submissionsFeedbackPatch: submissionsFeedbackPatchReducer,
  submissionsFeedbackPost: submissionsFeedbackPostReducer,
  submissionsRatingsList: submissionsRatingsListReducer,
  submissionsRatingsPost: submissionsRatingsPostReducer,
  submissionsResponsesList: submissionsResponsesListReducer,
  surveysDelete: surveysDeleteReducer,
  surveysList: surveysListReducer,
  surveysGet: surveysGetReducer,
  surveysPatch: surveysPatchReducer,
  surveysPost: surveysPostReducer,
  surveysActionsPost: surveysActionsPostReducer,
  surveysFieldsDelete: surveysFieldsDeleteReducer,
  surveysFieldsGet: surveysFieldsGetReducer,
  surveysFieldsList: surveysFieldsListReducer,
  surveysFieldsPost: surveysFieldsPostReducer,
  surveysFieldsPut: surveysFieldsPutReducer,
  surveysRecipientsDelete: surveysRecipientsDeleteReducer,
  surveysRecipientsList: surveysRecipientsListReducer,
  surveysRecipientsListPost: surveysRecipientsListPostReducer,
  usersDelete: usersDeleteReducer,
  usersList: usersListReducer,
  usersPost: usersPostReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';
import Navigation from '../common/Navigation';

class Dashboard extends Component {
  render() {
    return (
      <section className="dashboard">
        <Navigation/>
        <div className="container-fluid">
          This is the dashboard
        </div>
      </section>
    );
  }
}

export default Dashboard;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false,
  request: {}
};

const messagesListStart = (state, action) => {
  return updateObject(state, {
    data: [],
    error: null,
    loading: true
  });
};

const messagesListSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false,
    request: action.request
  } );
};

const messagesListFailure = (state, action) => {
  return updateObject( state, {
    data: [],
    error: action.error,
    loading: false,
    request: action.request
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MESSAGES_LIST_START: return messagesListStart(state, action);
    case actionTypes.MESSAGES_LIST_SUCCESS: return messagesListSuccess(state, action);
    case actionTypes.MESSAGES_LIST_FAILURE: return messagesListFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
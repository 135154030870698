import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, InputGroup, InputGroupAddon, FormText, Spinner } from 'reactstrap';
import { MdEdit, MdDone, MdClose } from 'react-icons/md';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import striptags from 'striptags';
import Dotdotdot from "react-dotdotdot";
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import moment from 'moment';
import { uniqueId, isEmpty } from 'lodash';


class Editable extends Component
{
  
  constructor(props) {
    super(props);
    this.state = {
      value: props.value === null ? '' : props.value,
      originalValue: props.value === null ? '' : props.value,
      editing: false
    };
  }

  changed = (event) => {
    this.setState({value: event.target.value});
  };

  save = () => {
    //console.log('VALUE', this.state.value);
    this.props.onSave(this.state.value);
    this.toggleEditing();
  };

  cancel = () => {
    this.setState({
      editing: false,
      value: this.state.originalValue
    })
  };

  toggleEditing = () => {
    let value = this.state.value;
    // if we don't have a value and the field we are setting is a select
    if (!this.state.value && this.props.type === 'select') {
      value = Object.values(this.props.options)[0];
    }
    if (this.props.type === 'datetime' && isEmpty(this.state.value)) {
      value = moment.utc().set({minute: 0, second: 0}).format('YYYY-MM-DD HH:mm:ss');
      //value = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    }
    this.setState({
      editing: !this.state.editing,
      value: value
    })
  };
  test(date) {
    this.setState({
      startDate: date
    });
  }
  render() {
    let displayValue;
    displayValue = this.props.displayValueIfEmpty && !this.props.value ? this.props.displayValueIfEmpty : this.props.value;
    // if the field type is select then use the option label instead of the label
    displayValue = this.props.type === 'select' ? this.props.options[this.props.value] : displayValue;

    displayValue = this.props.type === 'datetime' && this.props.value !== null ? moment.utc(this.props.value).local().format('MMM D, YYYY - h:mm a') : displayValue;

    // how we display the value when not in editing mode
    const value = (
      <div>
        {this.props.type === 'wysiwyg' ? <Dotdotdot clamp={2}>{striptags(this.props.value)}</Dotdotdot> : displayValue}
        {' '}
        <Button color="primary" outline onClick={this.toggleEditing} size="sm">
          <MdEdit/>
        </Button>
      </div>
    );

    // What the actual input field will look like
    let input = null;
    if (this.state.editing === true) {
      switch (this.props.type) {
        case 'date':
          input = (
            <DatePicker date={{value: this.state.value, touched: false, valid: true}}
                        id={uniqueId('date-picker-')}
                        onChange={(value) => this.setState({value: moment(value).format('YYYY-MM-DD')})}
                        selected={moment(this.state.value).toDate()}/>
          );
          break;
        case 'datetime':
          input = (
            <DateTimePicker date={{value: this.state.value, touched: false, valid: true}}
                            id={uniqueId('datetime-picker-')}
                            onChange={(value) => this.setState({value: moment.utc(value).format('YYYY-MM-DD HH:mm:ss')})}
                            selected={this.state.value}/>
          );
          break;
        case 'wysiwyg':
          input = (
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  'heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'mediaEmbed', 'undo', 'redo'
                ]
              }}
              data={this.state.value}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
              }}
              onChange={(event, editor) => {
                this.setState({value: editor.getData()});
                //console.log( { event, editor, data } );
              }}
            />);
          break;
        case 'select':
          input = <Input onChange={this.changed}
                         type={this.props.type}
                         value={this.state.value}>
            <option disabled>Choose an option</option>
            {Object.keys(this.props.options).map(key => <option value={key}
                                                                key={key}>{this.props.options[key]}</option>)}
          </Input>;
          break;
        default:
          input = <Input onChange={this.changed}
                         type={this.props.type}
                         value={this.state.value}/>;
          break;
      }
    }

    const form = (
      <React.Fragment>
        <InputGroup>
          {input}
          <InputGroupAddon addonType="append">
            <Button color="success" outline onClick={this.save} size="sm">
              <MdDone/>
            </Button>
            <Button outline onClick={this.cancel} size="sm">
              <MdClose/>
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {this.props.helpText ? <FormText color="muted">{this.props.helpText}</FormText> : null}
      </React.Fragment>
    );

    return (
      <div>
        {this.state.editing === false ? this.props.loading ? <Spinner size="sm"/> : value : form}
      </div>
    );
  }
}

Editable.propTypes = {
  displayValueIfEmpty: PropTypes.string,
  helpText: PropTypes.string,
  loading: PropTypes.bool,
  saveKeys: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  options: PropTypes.object
};

Editable.defaultProps = {
  helpText: null,
  type: 'text',
  value: '',
  options: {},
  saveKeys: false
};

export default Editable;


import React, { Component } from 'react'
import BucketListItem from './BucketListItem'

import {Droppable} from "react-beautiful-dnd";

class Column extends Component {
  render() {
    //console.log('FIELDS', this.props.fields);
    return (
      <React.Fragment>
        <Droppable droppableId={'buckets'}>
          {provided => (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {this.props.bucketsOrder.map((key, index) => {
                const item = this.props.buckets[key].attributes;
                return (
                      <BucketListItem created={item.created}
                                      description={item.description}
                                      key={item.id}
                                      labelPastTense={item.labelPastTense}
                                      labelPresentTense={item.labelPresentTense}
                                      name={item.name}
                                      id={item.id}
                                      index={index}
                                      lastName={item.lastName}
                                      modified={item.modified}
                                      toggleDeleteModalHandler={this.props.toggleDeleteModalHandler}
                                      toggleEditModalHandler={this.props.toggleEditModalHandler}
                                      user={item.user}/>

                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </React.Fragment>
    )
  }
}

export default Column;

const storageTypes = {
  salesforce: {
    key: 'salesforce',
    label: 'Salesforce',
    color: 'success'
  },
  'survey': {
    key: 'survey',
    label: 'Survey Only',
    color: 'warning'
  },
  display: {
    key: 'display',
    label: 'Display',
    color: 'secondary'
  }
};

export default storageTypes;
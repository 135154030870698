import React from 'react';
import { string, func, element, oneOfType } from 'prop-types';
import PapaParse from 'papaparse';

const CsvReader = ({
                     cssClass = 'csv-reader-input',
                     cssInputClass = 'csv-input',
                     label,
                     onFileLoaded,
                     onError,
                     inputId = null,
                     inputStyle = {},
                     parserOptions = {skipEmptyLines: 'greedy'}
                   }) => {
  const handleChangeFile = e => {
    let reader = new FileReader();
    const filename = e.target.files[0].name;

    reader.onload = event => {
      const csvData = PapaParse.parse(
        event.target.result,
        Object.assign(parserOptions, {
          error: onError
        })
      );
      //console.log(event.target);

      onFileLoaded(csvData.data, filename);

    };


    reader.readAsText(e.target.files[0]);
    e.target.value = '';
  };

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept=".csv, text/csv"
        onChange={e => handleChangeFile(e)}
      />
    </div>
  );
};

CsvReader.propTypes = {
  cssClass: string,
  cssInputClass: string,
  label: oneOfType([string, element]),
  onFileLoaded: func.isRequired,
  onError: func,
  inputId: string
};

export default CsvReader;
import React from 'react';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';

const storageType = (props) => {
  return (
    <FormGroup>
      <Label>Where should this field be stored?</Label>
      <Row>
        {
          props.surveyType !== 'request' ?
            <Col md={4}>
              <FormGroup check>
                <Input id="storage-salesforce"
                       invalid={props.field.storageType.touched && !props.field.storageType.valid}
                       onChange={e => props.changeHandler('storageType', 'salesforce')}
                       type="radio"
                       valid={props.field.storageType.touched && props.field.storageType.valid}
                       value="salesforce"
                       checked={props.field.storageType.value === 'salesforce'}/>
                <Label check for="storage-salesforce">Salesforce</Label>
                <FormText color="muted">
                  Data will be viewable in this interface and will also be stored and viewable in Salesforce
                </FormText>
              </FormGroup>
            </Col>
            : null
        }
        <Col md={4}>
          <FormGroup check>
            <Input id="storage-survey-only"
                   invalid={props.field.storageType.touched && !props.field.storageType.valid}
                   onChange={e => props.changeHandler('storageType', 'survey')}
                   type="radio"
                   checked={props.field.storageType.value === 'survey'}
                   valid={props.field.storageType.touched && props.field.storageType.valid}
                   value="survey"/>
            <Label check for="storage-survey-only">Survey Only</Label>
            <FormText color="muted">Data is only available in this interface</FormText>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup check>
            <Input id="storage-display-only"
                   invalid={props.field.storageType.touched && !props.field.storageType.valid}
                   onChange={e => props.changeHandler('storageType', 'display')}
                   type="radio"
                   checked={props.field.storageType.value === 'display'}
                   valid={props.field.storageType.touched && props.field.storageType.valid}
                   value="display"/>
            <Label check for="storage-display-only">Display Only</Label>
            <FormText color="muted">No data will be stored. This field will affect the form display only.</FormText>
          </FormGroup>
        </Col>
      </Row>
    </FormGroup>
  );
};

export default storageType;
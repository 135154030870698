export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';

export const BUCKETS_ACTIONS_POST_START = 'BUCKETS_ACTIONS_POST_START';
export const BUCKETS_ACTIONS_POST_SUCCESS = 'BUCKETS_ACTIONS_POST_SUCCESS';
export const BUCKETS_ACTIONS_POST_FAILURE = 'BUCKETS_ACTIONS_POST_FAILURE';

export const BUCKETS_DELETE_CLEAR = 'BUCKETS_DELETE_CLEAR';
export const BUCKETS_DELETE_START = 'BUCKETS_DELETE_START';
export const BUCKETS_DELETE_SUCCESS = 'BUCKETS_DELETE_SUCCESS';
export const BUCKETS_DELETE_FAILURE = 'BUCKETS_DELETE_FAILURE';

export const BUCKETS_LIST_START = 'BUCKETS_LIST_START';
export const BUCKETS_LIST_SUCCESS = 'BUCKETS_LIST_SUCCESS';
export const BUCKETS_LIST_FAILURE = 'BUCKETS_LIST_FAILURE';

export const BUCKETS_PATCH_START = 'BUCKETS_PATCH_START';
export const BUCKETS_PATCH_SUCCESS = 'BUCKETS_PATCH_SUCCESS';
export const BUCKETS_PATCH_FAILURE = 'BUCKETS_PATCH_FAILURE';

export const BUCKETS_POST_START = 'BUCKETS_POST_START';
export const BUCKETS_POST_SUCCESS = 'BUCKETS_POST_SUCCESS';
export const BUCKETS_POST_FAILURE = 'BUCKETS_POST_FAILURE';

export const BUCKET_PROFILES_ACTIONS_POST_START = 'BUCKET_PROFILES_ACTIONS_POST_START';
export const BUCKET_PROFILES_ACTIONS_POST_SUCCESS = 'BUCKET_PROFILES_ACTIONS_POST_SUCCESS';
export const BUCKET_PROFILES_ACTIONS_POST_FAILURE = 'BUCKET_PROFILES_ACTIONS_POST_FAILURE';

export const BUCKET_PROFILES_DELETE_CLEAR = 'BUCKET_PROFILES_DELETE_CLEAR';
export const BUCKET_PROFILES_DELETE_START = 'BUCKET_PROFILES_DELETE_START';
export const BUCKET_PROFILES_DELETE_SUCCESS = 'BUCKET_PROFILES_DELETE_SUCCESS';
export const BUCKET_PROFILES_DELETE_FAILURE = 'BUCKET_PROFILES_DELETE_FAILURE';

export const BUCKET_PROFILES_LIST_START = 'BUCKET_PROFILES_LIST_START';
export const BUCKET_PROFILES_LIST_SUCCESS = 'BUCKET_PROFILES_LIST_SUCCESS';
export const BUCKET_PROFILES_LIST_FAILURE = 'BUCKET_PROFILES_LIST_FAILURE';

export const BUCKET_PROFILES_PATCH_START = 'BUCKET_PROFILES_PATCH_START';
export const BUCKET_PROFILES_PATCH_SUCCESS = 'BUCKET_PROFILES_PATCH_SUCCESS';
export const BUCKET_PROFILES_PATCH_FAILURE = 'BUCKET_PROFILES_PATCH_FAILURE';

export const BUCKET_PROFILES_POST_START = 'BUCKET_PROFILES_POST_START';
export const BUCKET_PROFILES_POST_SUCCESS = 'BUCKET_PROFILES_POST_SUCCESS';
export const BUCKET_PROFILES_POST_FAILURE = 'BUCKET_PROFILES_POST_FAILURE';

export const MAIL_EVENTS_LIST_START = 'MAIL_EVENTS_LIST_START';
export const MAIL_EVENTS_LIST_SUCCESS = 'MAIL_EVENTS_LIST_SUCCESS';
export const MAIL_EVENTS_LIST_FAILURE = 'MAIL_EVENTS_LIST_FAILURE';

export const LISTS_LIST_START = 'LISTS_LIST_START';
export const LISTS_LIST_SUCCESS = 'LISTS_LIST_SUCCESS';
export const LISTS_LIST_FAILURE = 'LISTS_LIST_FAILURE';

export const MESSAGES_DELETE_START = 'MESSAGES_DELETE_START';
export const MESSAGES_DELETE_SUCCESS = 'MESSAGES_DELETE_SUCCESS';
export const MESSAGES_DELETE_FAILURE = 'MESSAGES_DELETE_FAILURE';

export const MESSAGES_GET_START = 'MESSAGES_GET_START';
export const MESSAGES_GET_SUCCESS = 'MESSAGES_GET_SUCCESS';
export const MESSAGES_GET_FAILURE = 'MESSAGES_GET_FAILURE';

export const MESSAGES_LIST_START = 'MESSAGES_LIST_START';
export const MESSAGES_LIST_SUCCESS = 'MESSAGES_LIST_SUCCESS';
export const MESSAGES_LIST_FAILURE = 'MESSAGES_LIST_FAILURE';

export const MESSAGES_PATCH_START = 'MESSAGES_PATCH_START';
export const MESSAGES_PATCH_SUCCESS = 'MESSAGES_PATCH_SUCCESS';
export const MESSAGES_PATCH_FAILURE = 'MESSAGES_PATCH_FAILURE';

export const MESSAGES_POST_START = 'MESSAGES_POST_START';
export const MESSAGES_POST_SUCCESS = 'MESSAGES_POST_SUCCESS';
export const MESSAGES_POST_FAILURE = 'MESSAGES_POST_FAILURE';

export const SUBMISSIONS_ACTIONS_POST_START = 'SUBMISSIONS_GET_START';
export const SUBMISSIONS_ACTIONS_POST_SUCCESS = 'SUBMISSIONS_GET_SUCCESS';
export const SUBMISSIONS_ACTIONS_POST_FAILURE = 'SUBMISSIONS_GET_FAILURE';

export const SUBMISSIONS_GET_START = 'SUBMISSIONS_GET_START';
export const SUBMISSIONS_GET_SUCCESS = 'SUBMISSIONS_GET_SUCCESS';
export const SUBMISSIONS_GET_FAILURE = 'SUBMISSIONS_GET_FAILURE';

export const SUBMISSIONS_LIST_START = 'SUBMISSIONS_LIST_START';
export const SUBMISSIONS_LIST_SUCCESS = 'SUBMISSIONS_LIST_SUCCESS';
export const SUBMISSIONS_LIST_FAILURE = 'SUBMISSIONS_LIST_FAILURE';

export const SUBMISSIONS_PATCH_START = 'SUBMISSIONS_PATCH_START';
export const SUBMISSIONS_PATCH_SUCCESS = 'SUBMISSIONS_PATCH_SUCCESS';
export const SUBMISSIONS_PATCH_FAILURE = 'SUBMISSIONS_PATCH_FAILURE';

export const SUBMISSIONS_FEEDBACK_DELETE_START = 'SUBMISSIONS_FEEDBACK_DELETE_START';
export const SUBMISSIONS_FEEDBACK_DELETE_SUCCESS = 'SUBMISSIONS_FEEDBACK_DELETE_SUCCESS';
export const SUBMISSIONS_FEEDBACK_DELETE_FAILURE = 'SUBMISSIONS_FEEDBACK_DELETE_FAILURE';

export const SUBMISSIONS_FEEDBACK_GET_START = 'SUBMISSIONS_FEEDBACK_GET_START';
export const SUBMISSIONS_FEEDBACK_GET_SUCCESS = 'SUBMISSIONS_FEEDBACK_GET_SUCCESS';
export const SUBMISSIONS_FEEDBACK_GET_FAILURE = 'SUBMISSIONS_FEEDBACK_GET_FAILURE';

export const SUBMISSIONS_FEEDBACK_LIST_START = 'SUBMISSIONS_FEEDBACK_LIST_START';
export const SUBMISSIONS_FEEDBACK_LIST_SUCCESS = 'SUBMISSIONS_FEEDBACK_LIST_SUCCESS';
export const SUBMISSIONS_FEEDBACK_LIST_FAILURE = 'SUBMISSIONS_FEEDBACK_LIST_FAILURE';

export const SUBMISSIONS_FEEDBACK_PATCH_START = 'SUBMISSIONS_FEEDBACK_PATCH_START';
export const SUBMISSIONS_FEEDBACK_PATCH_SUCCESS = 'SUBMISSIONS_FEEDBACK_PATCH_SUCCESS';
export const SUBMISSIONS_FEEDBACK_PATCH_FAILURE = 'SUBMISSIONS_FEEDBACK_PATCH_FAILURE';

export const SUBMISSIONS_FEEDBACK_POST_START = 'SUBMISSIONS_FEEDBACK_POST_START';
export const SUBMISSIONS_FEEDBACK_POST_SUCCESS = 'SUBMISSIONS_FEEDBACK_POST_SUCCESS';
export const SUBMISSIONS_FEEDBACK_POST_FAILURE = 'SUBMISSIONS_FEEDBACK_POST_FAILURE';

export const SUBMISSIONS_RATINGS_GET_START = 'SUBMISSIONS_RATINGS_GET_START';
export const SUBMISSIONS_RATINGS_GET_SUCCESS = 'SUBMISSIONS_RATINGS_GET_SUCCESS';
export const SUBMISSIONS_RATINGS_GET_FAILURE = 'SUBMISSIONS_RATINGS_GET_FAILURE';

export const SUBMISSIONS_RATINGS_LIST_START = 'SUBMISSIONS_RATINGS_LIST_START';
export const SUBMISSIONS_RATINGS_LIST_SUCCESS = 'SUBMISSIONS_RATINGS_LIST_SUCCESS';
export const SUBMISSIONS_RATINGS_LIST_FAILURE = 'SUBMISSIONS_RATINGS_LIST_FAILURE';

export const SUBMISSIONS_RATINGS_POST_START = 'SUBMISSIONS_RATINGS_POST_START';
export const SUBMISSIONS_RATINGS_POST_SUCCESS = 'SUBMISSIONS_RATINGS_POST_SUCCESS';
export const SUBMISSIONS_RATINGS_POST_FAILURE = 'SUBMISSIONS_RATINGS_POST_FAILURE';

export const SUBMISSIONS_RESPONSES_LIST_START = 'SUBMISSIONS_RESPONSES_LIST_START';
export const SUBMISSIONS_RESPONSES_LIST_SUCCESS = 'SUBMISSIONS_RESPONSES_LIST_SUCCESS';
export const SUBMISSIONS_RESPONSES_LIST_FAILURE = 'SUBMISSIONS_RESPONSES_LIST_FAILURE';

export const SURVEYS_DELETE_START = 'SURVEYS_DELETE_START';
export const SURVEYS_DELETE_SUCCESS = 'SURVEYS_DELETE_SUCCESS';
export const SURVEYS_DELETE_FAILURE = 'SURVEYS_DELETE_FAILURE';

export const SURVEYS_LIST_START = 'SURVEYS_LIST_START';
export const SURVEYS_LIST_SUCCESS = 'SURVEYS_LIST_SUCCESS';
export const SURVEYS_LIST_FAILURE = 'SURVEYS_LIST_FAILURE';

export const SURVEYS_GET_START = 'SURVEYS_GET_START';
export const SURVEYS_GET_SUCCESS = 'SURVEYS_GET_SUCCESS';
export const SURVEYS_GET_FAILURE = 'SURVEYS_GET_FAILURE';

export const SURVEYS_PATCH_START = 'SURVEYS_PATCH_START';
export const SURVEYS_PATCH_SUCCESS = 'SURVEYS_PATCH_SUCCESS';
export const SURVEYS_PATCH_FAILURE = 'SURVEYS_PATCH_FAILURE';

export const SURVEYS_POST_START = 'SURVEYS_POST_START';
export const SURVEYS_POST_SUCCESS = 'SURVEYS_POST_SUCCESS';
export const SURVEYS_POST_FAILURE = 'SURVEYS_POST_FAILURE';

export const SURVEYS_ACTIONS_POST_START = 'SURVEYS_ACTIONS_POST_START';
export const SURVEYS_ACTIONS_POST_SUCCESS = 'SURVEYS_ACTIONS_POST_SUCCESS';
export const SURVEYS_ACTIONS_POST_FAILURE = 'SURVEYS_ACTIONS_POST_FAILURE';

export const SURVEYS_FIELDS_DELETE_START = 'SURVEYS_FIELDS_DELETE_START';
export const SURVEYS_FIELDS_DELETE_SUCCESS = 'SURVEYS_FIELDS_DELETE_SUCCESS';
export const SURVEYS_FIELDS_DELETE_FAILURE = 'SURVEYS_FIELDS_DELETE_FAILURE';

export const SURVEYS_FIELDS_GET_START = 'SURVEYS_FIELDS_GET_START';
export const SURVEYS_FIELDS_GET_SUCCESS = 'SURVEYS_FIELDS_GET_SUCCESS';
export const SURVEYS_FIELDS_GET_FAILURE = 'SURVEYS_FIELDS_GET_FAILURE';

export const SURVEYS_FIELDS_LIST_START = 'SURVEYS_FIELDS_LIST_START';
export const SURVEYS_FIELDS_LIST_SUCCESS = 'SURVEYS_FIELDS_LIST_SUCCESS';
export const SURVEYS_FIELDS_LIST_FAILURE = 'SURVEYS_FIELDS_LIST_FAILURE';

export const SURVEYS_FIELDS_POST_START = 'SURVEYS_FIELDS_POST_START';
export const SURVEYS_FIELDS_POST_SUCCESS = 'SURVEYS_FIELDS_POST_SUCCESS';
export const SURVEYS_FIELDS_POST_FAILURE = 'SURVEYS_FIELDS_POST_FAILURE';

export const SURVEYS_FIELDS_PUT_START = 'SURVEYS_FIELDS_PUT_START';
export const SURVEYS_FIELDS_PUT_SUCCESS = 'SURVEYS_FIELDS_PUT_SUCCESS';
export const SURVEYS_FIELDS_PUT_FAILURE = 'SURVEYS_FIELDS_PUT_FAILURE';

export const SURVEYS_RECIPIENTS_LIST_START = 'SURVEYS_RECIPIENTS_LIST_START';
export const SURVEYS_RECIPIENTS_LIST_SUCCESS = 'SURVEYS_RECIPIENTS_LIST_SUCCESS';
export const SURVEYS_RECIPIENTS_LIST_FAILURE = 'SURVEYS_RECIPIENTS_LIST_FAILURE';

export const SURVEYS_RECIPIENTS_DELETE_START = 'SURVEYS_RECIPIENTS_DELETE_START';
export const SURVEYS_RECIPIENTS_DELETE_SUCCESS = 'SURVEYS_RECIPIENTS_DELETE_SUCCESS';
export const SURVEYS_RECIPIENTS_DELETE_FAILURE = 'SURVEYS_RECIPIENTS_DELETE_FAILURE';

export const SURVEYS_RECIPIENTS_LIST_POST_START = 'SURVEYS_RECIPIENTS_LIST_POST_START';
export const SURVEYS_RECIPIENTS_LIST_POST_SUCCESS = 'SURVEYS_RECIPIENTS_LIST_POST_SUCCESS';
export const SURVEYS_RECIPIENTS_LIST_POST_FAILURE = 'SURVEYS_RECIPIENTS_LIST_POST_FAILURE';

export const USERS_DELETE_START = 'USERS_DELETE_START';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAILURE = 'USERS_DELETE_FAILURE';

export const USERS_LIST_START = 'USERS_LIST_START';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_FAILURE = 'USERS_LIST_FAILURE';

export const USERS_POST_START = 'USERS_POST_START';
export const USERS_POST_SUCCESS = 'USERS_POST_SUCCESS';
export const USERS_POST_FAILURE = 'USERS_POST_FAILURE';

// CREATE, UPDATE, LIST, GET, DELETE

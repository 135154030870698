import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Draggable } from 'react-beautiful-dnd';
import { MdEdit, MdDelete } from 'react-icons/md';
import Badge from "reactstrap/es/Badge";
import ButtonGroup from 'reactstrap/es/ButtonGroup';
import PropTypes from 'prop-types';

class Field extends Component
{


  render() {
    let storageType = null;

    switch (this.props.field.storageType) {
      case 'salesforce':
        storageType = <Badge color="primary">Salesforce</Badge>;
        break;
      case 'survey':
        storageType = <Badge color="secondary">Survey Only</Badge>;
        break;
      case 'display':
        storageType = <Badge color="warning">Display Only</Badge>;
        break;
      default:
        throw Error('Invalid value in field storageType');
    }
    const deleteDisabled = ['street','street2','city','province','country','postalCode'].includes(this.props.field.fieldKey);

    return (
      <Draggable draggableId={'field-' + this.props.field.id} index={this.props.index}>
        {(provided) => (
          <tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <td>
              {this.props.field.fieldLabel}
              {/*<span className="muted"><Dotdotdot clamp={3}>{this.props.field.helpText}</Dotdotdot></span>*/}
            </td>
            <td>{storageType}</td>
            {/*<td>{this.props.field.fieldKey}</td>*/}
            <td>
              {this.props.field.storageType === 'salesforce'
               && this.props.field.fieldKey !== 'company-profile-address-autocomplete'
                ? <span>
                    <strong>Object:</strong> {this.props.field.dataType}<br/>
                    <strong>Field:</strong> {this.props.field.fieldType}
                  </span>
                : 'N/A'
              }
            </td>
            <td>{this.props.field.validation.required ? 'Yes' : 'No'}</td>
            <td style={{whiteSpace: 'nowrap'}}>
              <ButtonGroup>
                <Button outline size="sm" color="primary" onClick={() => this.props.editFieldHandler(this.props.field)}><MdEdit/></Button>
                <Button disabled={deleteDisabled}
                        outline
                        size="sm"
                        color="danger"
                        onClick={() => this.props.confirmDeleteHandler(this.props.field)}><MdDelete/></Button>
              </ButtonGroup>
            </td>

          </tr>
        )}
      </Draggable>

    );
  }
}

Field.propTypes = {
  field: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  confirmDeleteHandler: PropTypes.func.isRequired
};

export default Field;
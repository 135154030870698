import React from 'react';
import { Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';

const required = (props) => {
  return (
    <FormGroup>
      <Label>Is this field required?</Label>
      <Row>
        <Col md={6}>
          <FormGroup check>
            <Input id="required-required"
                   invalid={props.field.validation.required.touched && !props.field.validation.required.valid}
                   onChange={e => props.changeHandler('validation.required', true)}
                   type="radio"
                   checked={props.field.validation.required.value}
                   valid={props.field.validation.required.touched && props.field.validation.required.valid}
                   value="required"/>
            <Label check for="required-required">Required</Label>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup check>
            <Input id="required-optional"
                   invalid={props.field.validation.required.touched && !props.field.validation.required.valid}
                   onChange={e => props.changeHandler('validation.required', false)}
                   type="radio"
                   checked={!props.field.validation.required.value}
                   valid={props.field.validation.required.touched && props.field.validation.required.valid}
                   value="required-optional"/>
            <Label check for="required-optional">Optional</Label>
          </FormGroup>
        </Col>
      </Row>
      <FormText color="muted">
        Do you require an answer? ie. optional or required. If you make a checkbox field required then they have to select it.
      </FormText>
    </FormGroup>
  );
};

export default required;
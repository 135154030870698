import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const bucketsListStart = ( state, action ) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const bucketsListSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const bucketsListFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUCKETS_LIST_START: return bucketsListStart(state, action);
    case actionTypes.BUCKETS_LIST_SUCCESS: return bucketsListSuccess(state, action);
    case actionTypes.BUCKETS_LIST_FAILURE: return bucketsListFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const submissionsRatingsPostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const submissionsRatingsPostSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    error: null,
    loading: false
  });
};

const submissionsRatingsPostFailure = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMISSIONS_RATINGS_POST_START: return submissionsRatingsPostStart(state, action);
    case actionTypes.SUBMISSIONS_RATINGS_POST_SUCCESS: return submissionsRatingsPostSuccess(state, action);
    case actionTypes.SUBMISSIONS_RATINGS_POST_FAILURE: return submissionsRatingsPostFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
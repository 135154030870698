import axios from '../../axios';
import { surveysGetSuccess } from './surveys'
import * as actionTypes from './actionTypes';

export const surveysActionsPost = (surveyId, data, successCallback) => {
  return dispatch => {
    dispatch(surveysActionsPostStart());

    // put data into correct format
    data = {
      data: {
        type: 'survey-action',
        id: null,
        attributes: data
      }
    };

    axios.post(`/surveys/${surveyId}/actions`, data)
      .then(response => {
        dispatch(surveysActionsPostSuccess(response.data.data));
        dispatch(surveysGetSuccess(response.data.data));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysActionsPostFailure', error);
        dispatch(surveysActionsPostFailure(error));
      });
  };
};

export const surveysActionsPostStart = () => {
  return {
    type: actionTypes.SURVEYS_ACTIONS_POST_START
  };
};

export const surveysActionsPostSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_ACTIONS_POST_SUCCESS,
    data: data
  };
};

export const surveysActionsPostFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_ACTIONS_POST_FAILURE,
    error: error
  };
};

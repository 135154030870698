import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysFieldsPostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const surveysFieldsPostSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  });
};

const surveysFieldsPostFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_FIELDS_POST_START: return surveysFieldsPostStart(state, action);
    case actionTypes.SURVEYS_FIELDS_POST_SUCCESS: return surveysFieldsPostSuccess(state, action);
    case actionTypes.SURVEYS_FIELDS_POST_FAILURE: return surveysFieldsPostFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false,
  request: {}
};

const messagesGetStart = (state, action) => {
  return updateObject(state, {
    data: {},
    error: null,
    loading: true
  });
};

const messagesGetSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false,
    request: action.request
  } );
};

const messagesGetFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false,
    request: action.request
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MESSAGES_GET_START: return messagesGetStart(state, action);
    case actionTypes.MESSAGES_GET_SUCCESS: return messagesGetSuccess(state, action);
    case actionTypes.MESSAGES_GET_FAILURE: return messagesGetFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from 'reactstrap';
import ErrorAlert from '../../common/ErrorAlert';
import PropTypes from 'prop-types';

class Decision extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        decisionNote: '',
        decisionReason: '',
        bucket: props.initialBucket
      }
    };
  }


  changeHandler = (key, value) => {
    const formData = {...this.state.formData};
    formData[key] = value;
    this.setState({formData: formData});
  };

  submit = (event) => {
    event.preventDefault();
    this.props.saveHandler(this.state.formData);
  };

  render() {
    let loading = null;
    let error = null;

    // handle loading state
    if (this.props.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;

    // handle error
    } else if (this.props.error) {
      error = <ErrorAlert error={this.props.error}/>;
    }

    // if we initiated a save request and the post request is done and it did not return an error
    if (this.props.loading === false && this.props.error === null && this.state.saving === true) {
      // clear the saving status
      this.setState({saving: false});
      // close the modal
      this.close();
    }

    const currentBucket = this.props.bucketList.data.filter(bucket => bucket.attributes.name === this.state.formData.bucket)[0];

    return (
      <div>
        <Modal isOpen={true} toggle={this.props.closeHandler} className={this.props.className}>
          <ModalHeader toggle={this.props.closeHandler}>Submission Decision</ModalHeader>
          <ModalBody>
            {error}
            <Label>
              Are you sure you want to move this submission to the{' '}
              <strong>{currentBucket.labelPastTense}</strong> bucket?
            </Label>
            <Form onSubmit={this.submit}>
              {
                this.state.formData.bucket === 'declined' || this.state.formData.bucket === 'approved'
                ? <FormGroup>
                    <Label for="decisionNote">Notes</Label>
                    <Input type="textarea"
                           onChange={(e) => this.changeHandler('decisionNote', e.target.value)}
                           id="decisionNote"
                           placeholder="" />
                    <FormText color="muted">
                      Anything you would like to add about this decision?
                    </FormText>
                  </FormGroup>
                : null
              }
              {
                this.state.formData.bucket === 'declined'
                ? <FormGroup>
                    <Label for="decisionReason">Reason</Label>
                    <Input type="select"
                           onChange={(e) => this.changeHandler('decisionReason', e.target.value)}
                           id="decisionReason"
                           placeholder="">
                      <option value="Not a fit">Not a fit</option>
                      <option value="Startup Visa">Startup Visa</option>
                      <option value="Out of Province">Out of Province</option>
                      <option value="Referred">Referred</option>
                      <option value="Client did not engage">Client did not engage</option>
                    </Input>
                    <FormText color="muted">
                      Why are you declining this submission?
                    </FormText>
                  </FormGroup>
                : null
              }
            </Form>
          </ModalBody>
          <ModalFooter>
            {loading}
            <Button color="secondary" onClick={this.props.closeHandler}>Cancel</Button>
            <Button color="primary" onClick={() => this.props.saveHandler(this.state.formData)}>Save</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    patch: {
      data: state.submissionsPatch.data,
      error: state.submissionsPatch.error,
      loading: state.submissionsPatch.loading
    },
    bucketList: {
      data: state.bucketsList.data,
      error: state.bucketsList.error,
      loading: state.bucketsList.loading
    }
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     usersPost: (data) => dispatch(actions.usersPost(data))
//   };
// };

Decision.defaultProps = {
};

Decision.propTypes = {
  initialBucket: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(Decision);


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysPostStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const surveysPostSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysPostFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_POST_START: return surveysPostStart(state, action);
    case actionTypes.SURVEYS_POST_SUCCESS: return surveysPostSuccess(state, action);
    case actionTypes.SURVEYS_POST_FAILURE: return surveysPostFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
  Form,
  FormGroup,
  Label
} from 'reactstrap';
import ErrorAlert from '../../common/ErrorAlert';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-react'

class FeedbackCreate extends Component
{
  state = {
    dataLoaded: false,
    formData: {
      content: {value: '', touched: false, valid: false}
    },
    formValid: false,
    originalData: {}
  };

  componentDidMount() {
    //console.log('feedbackId', this.props.feedbackId);
    if (this.props.feedbackId) {
      this.props.getFeedback(this.props.submissionId, this.props.feedbackId);
    }
  }

  changeHandler = (key, value) => {
    const formData = cloneDeep(this.state.formData);
    formData[key].value = value;
    formData[key].touched = true;
    // if (key !== 'private' ) {
    formData[key].valid = value.length > 0;
    // } else {
    //   formData[key].valid = true;
    // }

    this.setState({
      formData: formData,
      formValid: this.validateForm(formData)
    });
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {...state};
    //console.log('getDerived', state.formData.fieldLabel.value);
    // if we have received the feedback item from the api
    if (!isEmpty(props.get.data.attributes) && state.dataLoaded === false && props.feedbackId !== null) {
      if (props.feedbackId !== props.get.data.attributes.id) {
        return null;
      }
      newState.originalData = props.get.data.attributes;
      //console.log('getDerived', 'dataLoaded', state.dataLoaded, 'feedbackId', props.feedbackId, 'empty attr', props.get.data.attributes);

      // clear formData in new state, we'll rebuild it using previous state and new data
      newState.formData = {};

      // loop over each formData element we will set, we use existing state formData so we don't
      // have to map id or created fields
      Object.keys(state.formData).map(key => {
          if (newState.originalData[key] === 'undefined') {
            return null;
          }

          // normal value
          newState.formData[key] = {
            touched: false,
            valid: false,
            value: newState.originalData[key]
          };

        return null;
      });
      newState.dataLoaded = true;
    }

    return newState;
  }

  validateForm = (formData) => {
      return formData.content.value.length > 0;
  };

  submit = (event) => {
    event.preventDefault();
    this.props.saveHandler(this.state.formData);
  };

  render() {
    let loading = null;
    let error = null;
    let item = null;

//console.log(this.props.get.data, this.props.feedbackId);
    if (!isEmpty(this.props.get.data) || this.props.feedbackId === null) {
      item = <Form onSubmit={this.submit}>
        <FormGroup>
          <Label>Feedback</Label>
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                'heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'mediaEmbed', 'undo', 'redo'
              ]
            }}
            onInit={editor => {
              // You can store the "editor" and use when it is needed.
              //console.log( 'Editor is ready to use!', editor );
            }}
            onChange={(event, editor) => {
              //this.setState({value: editor.getData()});
              this.changeHandler('content', editor.getData())
              //console.log( { event, editor, data } );
            }}
            data={this.state.formData.content.value}
          />
          {/*<Input id="content"*/}
          {/*       onChange={e => this.changeHandler('content', e.target.value)}*/}
          {/*       type="textarea"*/}
          {/*       style={{height: '150px'}}*/}
          {/*       invalid={this.state.formData.content.touched && !this.state.formData.content.valid}*/}
          {/*       placeholder="This company is fantastic!"*/}
          {/*       valid={this.state.formData.content.touched && this.state.formData.content.valid}*/}
          {/*       value={this.state.formData.content.value}/>*/}
          {/*<Label check for="content">Feedback</Label>*/}
          {/*<FormText color="muted">*/}
          {/*  This survey will NOT be sent to anyone automatically.*/}
          {/*</FormText>*/}
        </FormGroup>
      </Form>;
    } else if (this.props.get.loading) {
      item = <Spinner size="sm" color="primary"/>;
    } else if (this.props.get.error) {
      item = <ErrorAlert error={this.props.get.error}/>;
    }

    if (this.props.post.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;
    } else if (this.props.post.error) {
      error = <ErrorAlert error={this.props.post.error}/>;
    }

    if (this.props.patch.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;
    } else if (this.props.patch.error) {
      error = <ErrorAlert error={this.props.patch.error}/>;
    }

    return (
      <div>
        <Modal isOpen={true} toggle={this.props.closeHandler} className={this.props.className} size="lg">
          <ModalHeader toggle={this.props.closeHandler}>{this.props.feedbackId ? 'Edit' : 'Create'} Feedback</ModalHeader>
          <ModalBody>
            {error}
            {item}
          </ModalBody>
          <ModalFooter>
            {loading}
            <Button color="secondary" onClick={this.props.closeHandler}>Cancel</Button>
            <Button color="primary"
                    disabled={this.props.patch.loading || this.props.post.loading || !this.state.formValid}
                    onClick={() => this.props.saveHandler(this.state.formData, this.props.feedbackId)}>Save</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    get: {
      data: state.submissionsFeedbackGet.data,
      error: state.submissionsFeedbackGet.error,
      loading: state.submissionsFeedbackGet.loading
    },
    post: {
      data: state.submissionsFeedbackPost.data,
      error: state.submissionsFeedbackPost.error,
      loading: state.submissionsFeedbackPost.loading
    },
    patch: {
      data: state.submissionsFeedbackPatch.data,
      error: state.submissionsFeedbackPatch.error,
      loading: state.submissionsFeedbackPatch.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFeedback: (submissionId, feedbackId) => dispatch(actions.submissionsFeedbackGet(submissionId, feedbackId))
  };
};

FeedbackCreate.defaultProps = {
  feedbackId: null
};

FeedbackCreate.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  feedbackId: PropTypes.number,
  saveHandler: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackCreate);


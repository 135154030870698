import React, { Component } from 'react'
import Field from './Field'

import { Droppable } from 'react-beautiful-dnd';

class Column extends Component {
  render() {
    //console.log('FIELDS', this.props.fields);
    return (
      <React.Fragment>
        <Droppable droppableId={'survey-fields'}>
          {provided => (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {this.props.fieldsOrder.map((key, index) => {
                const field = this.props.fields[key].attributes;
                return (
                  <Field confirmDeleteHandler={this.props.confirmDeleteHandler}
                         editFieldHandler={this.props.editFieldHandler}
                         field={field}
                         fieldKeys={this.props.fieldKeys}
                         index={index}
                         key={key}
                         surveyId={this.props.surveyId}/>
                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </React.Fragment>
    )
  }
}

export default Column;

import React from 'react';
import { isEqual } from 'lodash';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import PropTypes from 'prop-types';

const orderByIcon = (props) => {
  let icon = null;
  // if we are currently sorting on this field
  if (isEqual(props.orderBy, props.currentOrderBy)) {
    // show the appropriate arrow based on direction
    if (props.currentOrderByDirection === 'asc') {
      icon = <FaSortUp/>;
    } else {
      icon = <FaSortDown/>
    }
  } else {
    icon = <FaSort/>;
  }
  return icon;
};

orderByIcon.propTypes = {
  currentOrderBy: PropTypes.array.isRequired,
  currentOrderByDirection: PropTypes.string.isRequired,
  orderBy: PropTypes.array.isRequired
};

export default orderByIcon;
import * as actions from '../../../store/actions';
import BackButton from '../../common/BackButton';
import Decision from './Decision';
import ErrorAlert from '../../common/ErrorAlert';
import FeedbackList from './FeedbackList';
import Footer from '../../common/Footer';
import Navigation from '../../common/Navigation';
import Rate from './Rate';
import Rating from './Rating';
import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import ResponseRow from './ResponseRow';
import SurveyInfoBasic from '../../common/SurveyInfoBasic';
import SubmissionInfoBasic from './SubmissionInfoBasic';
import { Button, ButtonGroup, Col, Container, Input, Row, Spinner, Table, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import { filter } from '../../../utility';
import { cloneDeep, isEmpty } from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class SubmissionResponses extends Component
{
  state = {
    filter: '',
    showDecisionModal: false,
    bucket: null
  };

  componentDidMount() {
    this.props.bucketsList({surveyId: parseInt(this.props.match.params.surveyId)});
    this.props.submissionsResponsesList(this.props.match.params.submissionId);
    this.props.getDataLists();
  }

  exportCsvHandler = (data) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'export',
      showTitle: false,
      //title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['Title', 'Value']
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  toggleDecisionModal = (bucket) => {
    this.setState((prevState) => ({
      showDecisionModal: !prevState.showDecisionModal,
      bucket: bucket
    }));
  };
  
  closeDecisionModal = () => {
    this.setState({showDecisionModal: false});
  };

  saveHandler = (data) => {
    this.props.submissionsPatch(this.props.match.params.submissionId, data, this.closeDecisionModal);
    //this.setState({patching: true});
  };

  render() {

    let items = null;
    let data = [];

    // load survey parent data
    // handle loading state
    if (this.props.list.loading === true) {
      items = <tr><td colSpan={3}><Spinner size="sm" color="primary"/></td></tr>;

    // handle error
    } else if (this.props.list.error) {
      items = <tr><td colSpan={3}><ErrorAlert error={this.props.list.error}/></td></tr>;

    // when we have data
    } else if (!isEmpty(this.props.list.data) && !isEmpty(this.props.dataList.data)) {
      items = cloneDeep(this.props.list.data);
      items = filter(items, this.state.filter);
      //console.log(items);
      items = Object.keys(items).map(key => {
        // strip out keys that are not actually tied to values
        if (items[key].attributes.fieldKey ==='company-profile-address-autocomplete') {
          return null;
        }
        return <ResponseRow key={key}
                            lists={this.props.dataList.data}
                            response={items[key].attributes}/>;
      });
      data = Object.keys(this.props.list.data).map(key => {
        //const row = {...this.props.list.data[key].attributes};
        const row = {...this.props.list.data[key].attributes};
        //row.fieldLabel = row.surveyField ? row.surveyField.fieldLabel : row.fieldKey;
        return {
          fieldLabel: row.surveyField ? row.surveyField.fieldLabel : row.fieldKey,
          fieldValue: typeof row.fieldValue === 'object' ? JSON.stringify(row.fieldValue) : row.fieldValue  // convert JSON to string if needed
        };
      });
      if (items.length === 0) {
        items = <tr><td colSpan={3} className="text-center">No items found</td></tr>;
      }
    } else if (isEmpty(this.props.list.data)) {
      items = <tr><td colSpan={3} className="text-center">This response is empty</td></tr>
    }

    const submissionBucket = !isEmpty(this.props.submissionGet.data) ? this.props.submissionGet.data.attributes.bucket : null;

    let actionButtons = null;
    let actionButtonTooltips = null;
    if (this.props.bucketList.loading) {
      actionButtons = <Spinner size="sm"/>;
    } else if (this.props.bucketList.error) {
      actionButtons = <ErrorAlert error={this.props.bucketList.error}/>
    } else {
      actionButtons = Object.keys(this.props.bucketList.data).map((key) => (
        <Button key={key}
                id={'action-' + key}
                outline={submissionBucket ? this.props.bucketList.data[key].attributes.name !== submissionBucket : true}
                disabled={submissionBucket ? this.props.bucketList.data[key].attributes.name === submissionBucket : false}
                onClick={() => this.toggleDecisionModal(this.props.bucketList.data[key].attributes.name)}
        >{this.props.bucketList.data[key].attributes.labelPresentTense}</Button>
      ));
      actionButtonTooltips = Object.keys(this.props.bucketList.data).map(key => {
        return this.props.bucketList.data[key].attributes.description
          ? <UncontrolledTooltip key={key} placement="top" target={'action-' + key}>
              {this.props.bucketList.data[key].attributes.description}
            </UncontrolledTooltip>
          : null;
      });
    }

    return (
      <section>
        <Navigation/>
        <Container fluid>
          <BackButton goBack={this.props.history.goBack}/>
          <h1>Survey Response</h1>

          <Row className="mb-3">
            <Col md={6}>
              <h4>Submission Info</h4>
              <SubmissionInfoBasic submissionId={parseInt(this.props.match.params.submissionId)}/>
            </Col>
            <Col md={6}>
              <h4>Survey Info</h4>
              <SurveyInfoBasic surveyId={parseInt(this.props.match.params.surveyId)}/>
            </Col>
          </Row>

          <h4>Actions</h4>
          <ButtonGroup className="mb-3">
            {actionButtons}
          </ButtonGroup>
          {actionButtonTooltips}

          <h4>Responses</h4>
          <div className="mt-3 mb-3">
            <ButtonGroup className="mb-3">
              <ExcelFile element={<Button outline size="sm" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>Excel</Button>}>
                <ExcelSheet data={data} name="Data" >
                  <ExcelColumn label="Title" value="fieldLabel"/>
                  <ExcelColumn label="Value" value="fieldValue"/>
                </ExcelSheet>
              </ExcelFile>
              <Button outline size="sm" onClick={() => this.exportCsvHandler(data)}>CSV</Button>
            </ButtonGroup>
            <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
          </div>

          <Table striped>
            <thead>
              <tr>
                <th>Storage</th>
                <th>Title</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {items}
            </tbody>
          </Table>
          <hr/>


          <Row>
            <Col md={6}>
              <h4>Your Rating</h4>
              <Rate submissionId={parseInt(this.props.match.params.submissionId)}
                    successCallback={() => this.props.submissionsGet(this.props.match.params.submissionId)}/>
            </Col>
            <Col md={6}>
              <h4>Group Rating</h4>
              <Rating submissionId={parseInt(this.props.match.params.submissionId)}/>
            </Col>
          </Row>


          <hr/>
          <h4>Feedback</h4>
          <FeedbackList submissionId={this.props.match.params.submissionId}/>

        </Container>
        <Footer/>
        {this.state.showDecisionModal ? <Decision closeHandler={this.toggleDecisionModal}
                                                  initialBucket={this.state.bucket}
                                                  saveHandler={this.saveHandler}/> : null}
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    bucketList: {
      data: state.bucketsList.data,
      error: state.bucketsList.error,
      loading: state.bucketsList.loading
    },
    list: {
      data: state.submissionsResponsesList.data,
      error: state.submissionsResponsesList.error,
      loading: state.submissionsResponsesList.loading
    },
    submissionGet: {
      data: state.submissionsGet.data,
      error: state.submissionsGet.error,
      loading: state.submissionsGet.loading
    },
    dataList: {
      data: state.listsList.data,
      error: state.listsList.error,
      loading: state.listsList.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submissionsResponsesList: (submissionId) => dispatch(actions.submissionsResponsesList(submissionId)),
    submissionsPatch: (submissionId, data, success) => dispatch(actions.submissionsPatch(submissionId, data, success)),
    bucketsList: (params) => dispatch(actions.bucketsList(params)),
    submissionsGet: (submissionId) => dispatch(actions.submissionsGet(submissionId)),
    getDataLists: () => dispatch(actions.listsList())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionResponses);


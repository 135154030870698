import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Container, Table, Spinner, Nav, NavLink, NavItem, Button, Input} from 'reactstrap';
import * as actions from '../../store/actions';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';
import {cloneDeep, isEmpty, isEqual} from 'lodash';
import {filter, orderBy} from '../../utility';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import SurveyCreate from "./SurveyCreate";
import SurveyListItemSurvey from './SurveyListItemSurvey';
import SurveyListItemIntake from './SurveyListItemIntake';
import ConfirmModal from '../common/ConfirmModal';
import TableHeadingColumn from '../common/TableHeadingColumn';

class SurveyList extends Component
{
  state = {
    filter: '',
    type: 'intake',
    orderBy: ['name'],
    orderByDirection: 'asc',
    showCreateSurveyModal: false,
    showDeleteSurveyModal: false
  };

  componentDidMount() {
    this.props.surveyList(this.state.type);
  }

  orderBy = (fields) => {
    // if we are already sorting on this field
    if (isEqual(this.state.orderBy, fields)) {
      // reverse direction
      const newDirection = this.state.orderByDirection === 'asc' ? 'desc' : 'asc';
      this.setState({orderByDirection: newDirection});
    } else {
      // new sort fields
      this.setState({orderBy: fields});
    }
  };

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  toggle(type) {
    if (this.state.type !== type) {
      this.setState({
        type: type
      },() => this.props.surveyList(this.state.type));
    }
  }

  toggleCreateSurveyModal = () => {
    this.setState((prevState) => ({
      showCreateSurveyModal: !prevState.showCreateSurveyModal
    }));
  };

  saveHandler = (data) => {
    this.props.surveyPost({
      name: data['name'].value,
      type: data['type'].value
    });
    this.setState({posting: true});
  };

  cloneHandler = (surveyId) => {
    this.props.surveysActionsPost(surveyId, 'clone',
    () => this.props.surveyList(this.state.type))
  };

  toggleDeleteSurveyModal = () => {
    this.setState((prevState) => ({
      showDeleteSurveyModal: !prevState.showDeleteSurveyModal
    }));
  };

  deleteSurveyHandler = (surveyId, surveyName) => {
    this.setState({
      surveyToDelete: {id: surveyId, name: surveyName}
    }, () => this.toggleDeleteSurveyModal());
  };

  deleteSurveyConfirmHandler = () => {
    this.props.surveysDelete(
      this.state.surveyToDelete.id,
      () => {
        this.props.surveyList(this.state.type);
        this.toggleDeleteSurveyModal();
      });
  };

  render() {

    if (this.state.posting && !isEmpty(this.props.post.data) && this.props.post.error === null) {
      const redirectUrl = `/surveys/${this.props.post.data.id}/manage`;
      console.log('Redirecting to ', redirectUrl);
      return <Redirect to={redirectUrl} />;
    }

    let items = null;

    // handle loading state
    if (this.props.list.loading === true) {
      items = <tr><td colSpan={this.state.type === 'intake' ? 9 : 7} className="text-center"><Spinner size="sm" color="primary"/></td></tr>;

    // handle error
    } else if (this.props.list.error) {
      items = <tr><td colSpan={this.state.type === 'intake' ? 9 : 7} className="text-center">{this.props.list.error}</td></tr>;

    // when we have data
    } else if (this.props.list.data.length > 0) {
      items = cloneDeep(this.props.list.data);
      items = filter(items, this.state.filter);
      items = orderBy(items, this.state.orderBy, this.state.orderByDirection);

      switch (this.state.type) {
        case 'survey':
          items = items
            .map(item => {
              item = item.attributes;
              return (
                <SurveyListItemSurvey cloneHandler={this.cloneHandler}
                                      created={item.created}
                                      deleteHandler={this.deleteSurveyHandler}
                                      expires={item.expires}
                                      id={item.id}
                                      key={item.id}
                                      modified={item.modified}
                                      name={item.name}
                                      sentDate={item.sentDate}
                                      sentFlag={item.sentFlag}
                                      user={item.user}
                                      userModified={item.userModified}
                                      url={item.url}
                                      type={item.type}/>
              )
            });
          break;
        case 'intake':
          /* Falls through */
        case 'request':
          items = items
            .map(item => {
              item = item.attributes;
              return (
                <SurveyListItemIntake cloneHandler={this.cloneHandler}
                                      created={item.created}
                                      deleteHandler={this.deleteSurveyHandler}
                                      expires={item.expires}
                                      fundingBucket={item.fundingBucket}
                                      id={item.id}
                                      key={item.id}
                                      modified={item.modified}
                                      name={item.name}
                                      primaryProgram={item.primaryProgram}
                                      sentDate={item.sentDate}
                                      sentFlag={item.sentFlag}
                                      user={item.user}
                                      userModified={item.userModified}
                                      url={item.url}
                                      type={item.type}/>
              )
            });
          break;
        default:
      }

      if (items.length === 0) {
        items = <tr><td colSpan={this.state.type !== 'survey' ? 9 : 7} className="text-center">No items found</td></tr>;
      }
    } else {
      items = <tr><td colSpan={this.state.type !== 'survey' ? 9 : 7} className="text-center">No surveys found</td></tr>;
    }

    let header = null;
    switch (this.state.type) {
      case 'survey':
        header = (
          <tr>
            <TableHeadingColumn orderBy={['name']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Name</TableHeadingColumn>
            <TableHeadingColumn orderBy={['sentFlag', 'sentDate']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Status</TableHeadingColumn>
            <TableHeadingColumn orderBy={['created']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Created</TableHeadingColumn>
            <TableHeadingColumn orderBy={['modified']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Modified</TableHeadingColumn>
            <TableHeadingColumn orderBy={['userModified.lastName', 'userModified.firstName']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>User</TableHeadingColumn>
            <th>Actions</th>
          </tr>
        );
        break;
      case 'intake':
        /* falls through */
      case 'request':
        header = (
          <tr>
            <TableHeadingColumn orderBy={['name']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Name</TableHeadingColumn>
            <TableHeadingColumn orderBy={['fundingBucket']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Funding Bucket</TableHeadingColumn>
            <TableHeadingColumn orderBy={['primaryProgram']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Program</TableHeadingColumn>
            <TableHeadingColumn orderBy={['sentFlag']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Status</TableHeadingColumn>
            <TableHeadingColumn orderBy={['created']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Created</TableHeadingColumn>
            <TableHeadingColumn orderBy={['modified']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>Modified</TableHeadingColumn>
            <TableHeadingColumn orderBy={['userModified.lastName', 'userModified.firstName']}
                                orderByHandler={this.orderBy}
                                currentOrderBy={this.state.orderBy}
                                currentOrderByDirection={this.state.orderByDirection}>User</TableHeadingColumn>
            <th>Actions</th>
          </tr>
        );
        break;
      default:
    }

    return (
      <section className="list">
        <Navigation/>
        <Container fluid>
          <h1>Survey List</h1>
          <p>
            Intake surveys can be used to collect information from a company applying to a Communitech program.
          </p>

          <div className="mt-3 mb-3">
            <Button color="primary" onClick={this.toggleCreateSurveyModal}>Create New Survey</Button>
            <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
          </div>

          <Nav tabs className="mb-3">
            <NavItem>
              <NavLink
                href="#"
                className={classNames({ active: this.state.type === 'intake' })}
                onClick={() => { this.toggle('intake'); }}
              >Intake Surveys</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classNames({ active: this.state.type === 'survey' })}
                onClick={() => { this.toggle('survey'); }}
              >
                Company Surveys
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classNames({ active: this.state.type === 'request' })}
                onClick={() => { this.toggle('request'); }}
              >
                Request Forms
              </NavLink>
            </NavItem>
          </Nav>

          <Table striped>
            <thead>{header}</thead>
            <tbody>{items}</tbody>
          </Table>

        </Container>
        <Footer/>

        {this.state.showCreateSurveyModal ? <SurveyCreate closeHandler={this.toggleCreateSurveyModal}
                                                          initialSurveyType={this.state.type}
                                                          saveHandler={this.saveHandler}/> : null}
        {this.state.showDeleteSurveyModal ?
          <ConfirmModal title="Delete survey?"
                        buttonConfirmLabel="Delete"
                        buttonConfirmColor="danger"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteSurveyConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteSurveyModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
            <span>Are you sure you want to delete the survey <strong>{this.state.surveyToDelete.name}</strong>?</span>
          </ConfirmModal> : null
        }
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    delete: {
      data: state.surveysDelete.data,
      error: state.surveysDelete.error,
      loading: state.surveysDelete.loading
    },
    list: {
      data: state.surveysList.data,
      error: state.surveysList.error,
      loading: state.surveysList.loading
    },
    post: {
      data: state.surveysPost.data,
      error: state.surveysPost.error,
      loading: state.surveysPost.loading
    },
    action: {
      data: state.surveysActionsPost.data,
      error: state.surveysActionsPost.error,
      loading: state.surveysActionsPost.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    surveysDelete: (surveyId, callback) => dispatch(actions.surveysDelete(surveyId, callback)),
    surveyList: (type) => dispatch(actions.surveysList(type)),
    surveyPost: (data) => dispatch(actions.surveysPost(data)),
    surveysActionsPost: (surveyId, action, callback) => dispatch(
      actions.surveysActionsPost(surveyId, {action: action}, callback)
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner, Button, Input, Table } from 'reactstrap';
import * as actions from '../../../store/actions';
import { isEmpty, cloneDeep } from 'lodash';
import ErrorAlert from '../../common/ErrorAlert';
import { filter } from '../../../utility';
import EmailBucket from './EmailBucket';
import ConfirmModal from '../../common/ConfirmModal';
import MessagesListItem from './MessagesListItem';
import PropTypes from 'prop-types';

class MessagesList extends Component
{
  state = {
    filter: '',
    showEmailBucketModal: false,
    showDeleteMessageModal: false,
    messageId: null
  };

  componentDidMount() {
    this.props.messagesList(this.props.surveyId, this.props.bucket);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.bucket !== prevProps.bucket) {
      this.props.messagesList(this.props.surveyId, this.props.bucket);
    }
  }

  saveHandler = (data, messageId) => {
    //this.props.submissionsPatch(this.props.match.params.submissionId, data, this.closeDecisionModal);
    const formData = {};
    Object.keys(data).map(key => {
      formData[key] = data[key].value;
      return null;
    });
    formData.surveyId = this.props.surveyId;
    formData.bucket = this.props.bucket;
    //this.props.submissionsFeedbackPost(this.props.submissionId, formData);
    if (messageId) {
      this.props.messagesPatch(messageId, formData, () => {
        this.toggleEmailBucketModal();
        this.props.messagesList(this.props.surveyId, this.props.bucket);
      });
    } else {
      this.props.messagesPost(formData, formData.surveyId, formData.bucket, () => {
        this.toggleEmailBucketModal();
        this.props.messagesList(this.props.surveyId, this.props.bucket);
      });
    }
  };

  toggleEmailBucketModal = (messageId) => {
    //console.log('toggle feedbackId', feedbackId);
    messageId = typeof messageId === 'undefined' || messageId === null ? null : parseInt(messageId);
    this.setState((prevState) => ({
      showEmailBucketModal: !prevState.showEmailBucketModal,
      messageId: messageId
    }));
  };

  toggleDeleteMessageModal = () => {
    this.props.delete.error = null
    this.setState((prevState) => ({
      showDeleteMessageModal: !prevState.showDeleteMessageModal
    }));
  };

  deleteMessageHandler = (message) => {
    this.setState({
      messageToDelete: message
    }, () => this.toggleDeleteMessageModal());
  };

  deleteMessageConfirmHandler = () => {
    this.props.messagesDelete(
      this.state.messageToDelete.id,
      () => {
        this.toggleDeleteMessageModal();
        this.props.messagesList(this.props.surveyId, this.props.bucket);
      }
    );
  };

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  render() {
    let items = null;

    // load survey parent data
    // handle loading state
    if (this.props.list.loading === true) {
      items = <tr><td colSpan={6}><Spinner size="sm" color="primary"/></td></tr>;

    // handle error
    } else if (this.props.list.error) {
      items = <tr><td colSpan={6}><ErrorAlert error={this.props.list.error}/></td></tr>;

    // when we have data
    } else if (!isEmpty(this.props.list.data)) {
      items = cloneDeep(this.props.list.data);
      items = filter(items, this.state.filter);
      items = Object.keys(items).map(key => {
        // strip out keys that are not actually tied to values
        if (items[key].attributes.fieldKey ==='company-profile-address-autocomplete') {
          return null;
        }
        return <MessagesListItem key={key}
                                 editHandler={this.toggleEmailBucketModal}
                                 deleteHandler={this.deleteMessageHandler}
                                 item={items[key].attributes}/>;
      });
      //items = {items};

      if (items.length === 0) {
        items = <tr><td className="text-center" colSpan={6}>No items found</td></tr>;
      }
    } else if (isEmpty(this.props.list.data)) {
      items = <tr><td className="text-center" colSpan={6}>There are no messages in this bucket</td></tr>
    }

    return (
      <section>
        <div className="mt-3 mb-3">
          <Button color="primary" className="mb-3" onClick={() => this.toggleEmailBucketModal(null)}>Email {this.props.bucket} submissions</Button>
          <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
        </div>
        <h2>Messages for bucket: {this.props.bucket}</h2>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Subject</th>
              <th>Status</th>
              <th>Created</th>
              <th>Modified</th>
              <th>User</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>

        {
          this.state.showEmailBucketModal ?
          <EmailBucket bucket={this.props.bucket}
                       messageId={this.state.messageId}
                       closeHandler={this.toggleEmailBucketModal}
                       surveyId={this.props.surveyId}
                       saveHandler={this.saveHandler}/>
          : null
        }
        {this.state.showDeleteMessageModal ?
          <ConfirmModal title="Delete message?"
                        buttonConfirmLabel="Delete"
                        buttonConfirmColor="danger"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteMessageConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteMessageModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
              <span>
                Are you sure you want to delete this message?
              </span>
          </ConfirmModal> : null
        }
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    delete: {
      data: state.messagesDelete.data,
      error: state.messagesDelete.error,
      loading: state.messagesDelete.loading
    },
    get: {
      data: state.messagesGet.data,
      error: state.messagesGet.error,
      loading: state.messagesGet.loading
    },
    list: {
      data: state.messagesList.data,
      error: state.messagesList.error,
      loading: state.messagesList.loading
    },
    post: {
      data: state.messagesPost.data,
      error: state.messagesPost.error,
      loading: state.messagesPost.loading
    },
    patch: {
      data: state.messagesPatch.data,
      error: state.messagesPatch.error,
      loading: state.messagesPatch.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    messagesList: (surveyId, bucket) => dispatch(actions.messagesList(surveyId, bucket)),
    messagesPost: (data, surveyId, bucket, successCallback) => dispatch(actions.messagesPost(data, surveyId, bucket, successCallback)),
    messagesPatch:
      (messageId, data, successCallback) => dispatch(actions.messagesPatch(messageId, data, successCallback)),
    messagesDelete:
      (messageId, successCallback) => dispatch(actions.messagesDelete(messageId, successCallback))
  };
};

MessagesList.propTypes = {
  surveyId: PropTypes.number.isRequired,
  bucket: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesList);


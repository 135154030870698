import React, { Component } from 'react';
import { connect } from 'react-redux';
import {CardColumns, Spinner, Button, Input} from 'reactstrap';
import * as actions from '../../../store/actions';
import { isEmpty, cloneDeep } from 'lodash';
import ErrorAlert from '../../common/ErrorAlert';
import FeedbackListItem from './FeedbackListItem';
import { filter } from '../../../utility';
import FeedbackCreate from './FeedbackCreate';
import ConfirmModal from '../../common/ConfirmModal';

class FeedbackList extends Component
{
  state = {
    filter: '',
    showCreateFeedbackModal: false,
    showDeleteFeedbackModal: false,
    feedbackId: null
  };

  componentDidMount() {
    this.props.submissionsFeedbackList(this.props.submissionId);
  }

  saveHandler = (data, feedbackId) => {
    //this.props.submissionsPatch(this.props.match.params.submissionId, data, this.closeDecisionModal);
    const formData = {};
    Object.keys(data).map(key => {
      formData[key] = data[key].value;
      return null;
    });

    //this.props.submissionsFeedbackPost(this.props.submissionId, formData);
    if (feedbackId) {
      this.props.submissionsFeedbackPatch(this.props.submissionId, feedbackId, formData, this.toggleCreateFeedbackModal);
    } else {
      this.props.submissionsFeedbackPost(this.props.submissionId, formData, this.toggleCreateFeedbackModal);
    }
  };

  toggleCreateFeedbackModal = (feedbackId) => {
    //console.log('toggle feedbackId', feedbackId);
    feedbackId = typeof feedbackId === 'undefined' || feedbackId === null ? null : parseInt(feedbackId);
    this.setState((prevState) => ({
      showCreateFeedbackModal: !prevState.showCreateFeedbackModal,
      feedbackId: feedbackId
    }));
  };

  toggleDeleteFeedbackModal = () => {
    this.setState((prevState) => ({
      showDeleteFeedbackModal: !prevState.showDeleteFeedbackModal
    }));
  };

  deleteFeedbackHandler = (feedback) => {
    this.setState({
      feedbackToDelete: feedback
    }, () => this.toggleDeleteFeedbackModal());
  };

  deleteFeedbackConfirmHandler = () => {
    this.props.submissionsFeedbackDelete(
      this.props.submissionId,
      this.state.feedbackToDelete.id,
      () => this.toggleDeleteFeedbackModal()
    );
  };

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  render() {
    let items = null;

    // load survey parent data
    // handle loading state
    if (this.props.list.loading === true) {
      items = <div><Spinner size="sm" color="primary"/></div>;

    // handle error
    } else if (this.props.list.error) {
      items = <ErrorAlert error={this.props.list.error}/>;

    // when we have data
    } else if (!isEmpty(this.props.list.data)) {
      items = cloneDeep(this.props.list.data);
      items = filter(items, this.state.filter);
      items = Object.keys(items).map(key => {
        // strip out keys that are not actually tied to values
        if (items[key].attributes.fieldKey ==='company-profile-address-autocomplete') {
          return null;
        }
        return <FeedbackListItem key={key}
                                 editHandler={this.toggleCreateFeedbackModal}
                                 deleteHandler={this.deleteFeedbackHandler}
                                 item={items[key].attributes}/>;
      });
      items = <CardColumns>{items}</CardColumns>;

      if (items.length === 0) {
        items = <p className="text-center">No items found</p>;
      }
    } else if (isEmpty(this.props.list.data)) {
      items = <p className="text-center">This response is empty</p>
    }

    return (
      <section>
        <div className="mt-3 mb-3">
          <Button color="primary" className="mb-3" onClick={() => this.toggleCreateFeedbackModal(null)}>Add Feedback</Button>
          <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
        </div>

        {items}
        {
          this.state.showCreateFeedbackModal ?
          <FeedbackCreate feedbackId={this.state.feedbackId}
                          closeHandler={this.toggleCreateFeedbackModal}
                          submissionId={this.props.submissionId}
                          saveHandler={this.saveHandler}/>
          : null
        }
        {this.state.showDeleteFeedbackModal ?
          <ConfirmModal title="Delete feedback?"
                        buttonConfirmLabel="Delete"
                        buttonConfirmColor="danger"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.deleteFeedbackConfirmHandler}
                        buttonCancelHandler={this.toggleDeleteFeedbackModal}
                        loading={this.props.delete.loading}
                        error={this.props.delete.error}>
              <span>
                Are you sure you want to delete this feedback item?
              </span>
          </ConfirmModal> : null
        }
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    delete: {
      data: state.submissionsFeedbackDelete.data,
      error: state.submissionsFeedbackDelete.error,
      loading: state.submissionsFeedbackDelete.loading
    },
    get: {
      data: state.submissionsFeedbackGet.data,
      error: state.submissionsFeedbackGet.error,
      loading: state.submissionsFeedbackGet.loading
    },
    list: {
      data: state.submissionsFeedbackList.data,
      error: state.submissionsFeedbackList.error,
      loading: state.submissionsFeedbackList.loading
    },
    post: {
      data: state.submissionsFeedbackPost.data,
      error: state.submissionsFeedbackPost.error,
      loading: state.submissionsFeedbackPost.loading
    },
    patch: {
      data: state.submissionsFeedbackPatch.data,
      error: state.submissionsFeedbackPatch.error,
      loading: state.submissionsFeedbackPatch.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submissionsFeedbackList: (submissionId) => dispatch(actions.submissionsFeedbackList(submissionId)),
    submissionsFeedbackPost: (submissionId, data, successCallback) => dispatch(actions.submissionsFeedbackPost(submissionId, data, successCallback)),
    submissionsFeedbackPatch:
      (submissionId, feedbackId, data, successCallback) => dispatch(actions.submissionsFeedbackPatch(submissionId, feedbackId, data, successCallback)),
    submissionsFeedbackDelete:
      (submissionId, feedbackId, successCallback) => dispatch(actions.submissionsFeedbackDelete(submissionId, feedbackId, successCallback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackList);


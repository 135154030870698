import React from 'react';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

const preQuestionHelpText = (props) => {
  return (
    <FormGroup>
      <Label for="preQuestionHelpText">Pre-question help text <span className="text-muted">(optional)</span></Label>
      <Input id="preQuestionHelpText"
             invalid={props.field.preQuestionHelpText.touched && !props.field.preQuestionHelpText.valid}
             onChange={(e) => props.changeHandler('preQuestionHelpText', e.target.value)}
             placeholder="You can skip this question if you want"
             type="textarea"
             valid={props.field.preQuestionHelpText.touched && props.field.preQuestionHelpText.valid}
             value={props.field.preQuestionHelpText.value === null ? '' : props.field.preQuestionHelpText.value}/>
      <FormText color="muted">
        A short description that shows under the pre-question.
      </FormText>
    </FormGroup>
  );
};

export default preQuestionHelpText;
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysRecipientsDeleteStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: {}
  });
};

const surveysRecipientsDeleteSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysRecipientsDeleteFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_RECIPIENTS_DELETE_START: return surveysRecipientsDeleteStart(state, action);
    case actionTypes.SURVEYS_RECIPIENTS_DELETE_SUCCESS: return surveysRecipientsDeleteSuccess(state, action);
    case actionTypes.SURVEYS_RECIPIENTS_DELETE_FAILURE: return surveysRecipientsDeleteFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
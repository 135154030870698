import React, { Component } from 'react';
import Navigation from '../common/Navigation';
import {
  Container,
  Card,
  NavItem,
  NavLink,
  Nav,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu, DropdownItem, Spinner, UncontrolledTooltip, Col, Row
} from 'reactstrap';
import Bucket from './SurveySubmissions/Bucket';
import classNames from 'classnames';
import SurveyInfoBasic from '../common/SurveyInfoBasic';
import ErrorAlert from '../common/ErrorAlert';
import Footer from '../common/Footer';
import BackButton from '../common/BackButton';
import axios from '../../axios';
import { connect } from 'react-redux';
import { MdMoreVert } from 'react-icons/md';
import * as actions from '../../store/actions';
import { Link } from 'react-router-dom';
import { uniq } from 'lodash';
import MessagesList from './SurveySubmissions/MessagesList';
import { Element, scroller } from 'react-scroll';

class SurveySubmissions extends Component {

  state = {
    filter: '',
    bucket: 'submitted', //default bucket
    buildCsv: {
      loading: false
    },
    bulkSelection: [],
    hideRatings: true,
    showEmailBucketModal: false
  };

  componentDidMount() {
    this.props.getListOfBuckets({surveyId: this.props.match.params.surveyId}, () => {
      // create an array of all bucket names for this survey
      const buckets = Object.keys(this.props.bucketsList.data).map(key => {
        return this.props.bucketsList.data[key].attributes.name;
      });
      // if the correctly selected bucket is not in the survey bucket names list then toggle the first bucket
      // that is allowed
      if (buckets.indexOf(this.state.bucket) === -1) {
        this.setState({bucket: buckets[0]});
      }
    });
    if (this.props.match.params.bucket) {
      this.toggle(this.props.match.params.bucket);
    }
  }

  bulkSelectChangeHandler = (newId) => {
    //console.log('newId', newId);
    let bulkSelection = [...this.state.bulkSelection];
    if (bulkSelection.indexOf(newId) > -1) {
      bulkSelection = bulkSelection.filter(id => id !== newId)
    } else {
      bulkSelection.push(newId);
    }
    bulkSelection = uniq(bulkSelection);
    //console.log(bulkSelection);
    this.setState({bulkSelection: bulkSelection})
  };

  moveHandler = (bucket) => {
    //console.log(bucket);
    //console.log(this.state.bulkSelection);
    this.props.moveSubmissions(this.state.bulkSelection, bucket, {
        surveyId: parseInt(this.props.match.params.surveyId),
        bucket: this.state.bucket
      },() =>
        this.setState({bulkSelection: []})
    );
  };

  toggle(bucket) {
    if (this.state.bucket !== bucket) {
      this.setState({
        bucket: bucket
      });
    }
  }

  toggleRatings = () => {
    this.setState((prevState) => ({
      hideRatings: !prevState.hideRatings
    }));
  };

  toggleEmailBucketModal = () => {
    this.setState((prevState) => ({
      showEmailBucketModal: !prevState.showEmailBucketModal
    }));
  };

  filterHandler = (e) => {
    this.setState({filter: e.target.value.toLowerCase()});
  };

  buildCsv = () => {
    this.setState({'buildCsv': {loading: true}});
    axios({
      url: `/submissions?surveyId=${parseInt(this.props.match.params.surveyId)}&includeResponses=true`,
      method: 'GET',
      headers: {'Accept': 'text/csv'},
      responseType: 'blob', // important
    }).then((response) => {
      this.setState({'buildCsv': {loading: false}});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'submissions.csv');
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      this.setState({'buildCsv': {loading: false}});
    });
  };

  scrollToMessages() {
    scroller.scrollTo('messages', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  render() {
    let navItems = null;
    let bucket = null;
    let moveBucketList = [];

    if (this.props.bucketsList.loading) {
      bucket = <Spinner size="sm"/>;
      navItems = <Spinner size="sm"/>;
    } else if (this.props.bucketsList.error) {
      bucket = <ErrorAlert error={this.props.bucketsList.error}/>
    } else if (this.props.bucketsList.data.length > 0) {
      navItems = Object.keys(this.props.bucketsList.data).map(key => {
        const bucketName = this.props.bucketsList.data[key].attributes.name;
        return (
          <NavItem key={key}>
            <NavLink
              className={classNames({active: this.state.bucket === bucketName})}
              id={'nav-' + key}
              tag={Link}
              onClick={() => {
                this.toggle(bucketName);
              }}
              to={`/surveys/${this.props.match.params.surveyId}/submissions/${bucketName}`}
            >{this.props.bucketsList.data[key].attributes.labelPastTense}</NavLink>
            {
              this.props.bucketsList.data[key].attributes.description
                ? <UncontrolledTooltip placement="top" target={'nav-' + key}>
                  {this.props.bucketsList.data[key].attributes.description}
                </UncontrolledTooltip>
                : null
            }
          </NavItem>
        );
      });
      navItems.push(
        <span key="nav" className="ml-auto text-nowrap border border-top-0 border-left-0 border-right-0">
          <UncontrolledDropdown size="sm">
            <DropdownToggle outline>
              <MdMoreVert/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={Link} to="/buckets">Manage Buckets</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </span>
      );

      bucket = <Bucket bucket={this.state.bucket}
                       bulkSelection={this.state.bulkSelection}
                       bulkSelectChangeHandler={this.bulkSelectChangeHandler}
                       filter={this.state.filter}
                       filterHandler={this.filterHandler}
                       hideRatings={this.state.hideRatings}
                       surveyId={parseInt(this.props.match.params.surveyId)}/>;

      moveBucketList = Object.keys(this.props.bucketsList.data).map(key => {
        const bucketName = this.props.bucketsList.data[key].attributes.name;
        const bucketLabel = this.props.bucketsList.data[key].attributes.labelPastTense;
        return <DropdownItem key={key} onClick={() => this.moveHandler(bucketName)}>{bucketLabel}</DropdownItem>;
      });
    }

    return (
      <section>
        <Navigation/>
        <Container fluid>
          <BackButton goBack={this.props.history.goBack}/>
          <h1>Survey Submissions</h1>

          <Card body>
            <SurveyInfoBasic surveyId={parseInt(this.props.match.params.surveyId)}/>
          </Card>

          <div className="mt-3 mb-3">
            <Button outline disabled={this.state.buildCsv.loading} size="sm" onClick={this.buildCsv}>Export</Button>{' '}
            <Button outline size="sm" onClick={this.toggleRatings}>{this.state.hideRatings ? 'Show' : 'Hide'} Ratings</Button>{' '}
            <Button outline size="sm" onClick={this.scrollToMessages}>Messages</Button>{' '}
            {this.state.buildCsv.loading ? <Spinner size="sm"/> : null }
            <Input className="col-md-6 float-right" type="text" onChange={this.filterHandler} placeholder="Filter"/>
          </div>

          <Nav tabs className="mb-3 mt-3">
            {navItems}
          </Nav>
          <Row>
            <Col md={6}>
              <UncontrolledDropdown size="sm">
                <DropdownToggle outline disabled={this.state.bulkSelection.length === 0}>
                  Move
                </DropdownToggle>
                <DropdownMenu right>
                  {moveBucketList}
                </DropdownMenu>{' '}
              </UncontrolledDropdown>
            </Col>
            <Col md={6}>
              <p className="float-right">Total submissions in this bucket: <strong>{this.props.list.data.length}</strong></p>
            </Col>
          </Row>
          {bucket}
          <Element name="messages"/>
          <MessagesList bucket={this.state.bucket}
                        surveyId={parseInt(this.props.match.params.surveyId)}/>
        </Container>
        <Footer/>
      </section>
    );
  }
}


const mapStateToProps = state => {
  return {
    list: {
      data: state.submissionsList.data,
      error: state.submissionsList.error,
      loading: state.submissionsList.loading
    },
    bucketsList: {
      data: state.bucketsList.data,
      error: state.bucketsList.error,
      loading: state.bucketsList.loading
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListOfBuckets: (params, successCallback) => dispatch(actions.bucketsList(params, successCallback)),
    moveSubmissions: (submissionIds, bucket, listParams, successCallback) => dispatch(actions.submissionsActionsPost('move', submissionIds, bucket, listParams, successCallback)),
    messagesPost: (data, successCallback) => dispatch(actions.messagesPost(data, successCallback)),
    messagesPatch: (data, messageId, successCallback) => dispatch(actions.messagesPatch(messageId, data, successCallback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySubmissions);

import React from 'react';
import { Button, Input, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import Rating from './Rating';

const bucketRow = (props) => {
  return (
    <tr>
      <td>
        <FormGroup check>
          <Input checked={props.bulkSelection.indexOf(props.submission.id) > -1}
                 onChange={() => props.bulkSelectChangeHandler(props.submission.id)}
                 type="checkbox"/>{' '}
        </FormGroup>
      </td>
      <td>{props.submission.communitechCompany ? props.submission.communitechCompany.name : 'Unknown'}</td>
      <td>{moment.utc(props.submission.created).local().format('lll')}</td>
      <td>{props.submission.url}</td>
      {
        props.hideRatings
        ? null :
        <td><Rating allowToggle={false}
                    submissionId={props.submission.id}
                    ratings={props.submission.ratings}
                    minimal={true}/></td>
      }
      <td>{props.submission.decisionDate ? moment(props.submission.decisionDate).format('ll') : 'No date'}</td>
      <td>{props.submission.decisionReason}</td>
      <td>
        <Button outline size="sm" color="secondary" tag={Link} to={`/surveys/${props.surveyId}/submissions/${props.submission.id}/responses`}>View</Button>
      </td>
    </tr>
  );
};

bucketRow.propTypes = {
  submission: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  hideRatings: PropTypes.bool
};

bucketRow.defaultProps = {
  hideRatings: true
};

export default bucketRow;
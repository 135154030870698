import React from 'react';
import {Alert} from 'reactstrap';
import PropTypes from "prop-types";

const errorAlert = (props) => {

  let title = null;
  let detail = null;
  let supportCode = null;

  if (typeof props.error !== 'undefined' && typeof props.error.response !== 'undefined') {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    //console.log(props.error.response.data);
    //console.log(props.error.response.status);
    //console.log(props.error.response.headers);

    const errorResponse = props.error.response.data;
    title = typeof errorResponse.errors[0].title !== 'undefined' ? errorResponse.errors[0].title : null;
    detail = typeof errorResponse.errors[0].detail !== 'undefined' ? errorResponse.errors[0].detail : null;
    supportCode = typeof errorResponse.errors[0].code !== 'undefined' ? errorResponse.errors[0].code : null;
    if (detail === null) {
      detail = title;
      title = null;
    }
  } else if (typeof props.error !== 'undefined' && typeof props.error.request !== 'undefined') {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    //console.log(props.error.request);
    detail = props.error.message;
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', props.error);
    if (typeof props.error !== 'undefined') {
      detail = props.error.message;
    } else {
      detail = 'Invalid error object';
    }
  }
  //console.log(props.error.config);

  return (
    <Alert color="danger">
      {title ? <strong>{title}: </strong> : null}
      {detail}<br/>
      {supportCode ? <span>Support Code: <strong><code>{supportCode}</code></strong></span> : null}
    </Alert>
  );
};

errorAlert.propTypes = {
  error: PropTypes.object.isRequired
};

export default errorAlert;
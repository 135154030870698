import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText
} from 'reactstrap';
import ErrorAlert from '../common/ErrorAlert';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

class SurveyCreate extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: {value: '', touched: false, valid: false},
        type: {value: props.initialSurveyType, touched: false, valid: false}
      }
    };
  }


  changeHandler = (key, value) => {
    const formData = cloneDeep(this.state.formData);
    formData[key].value = value;
    formData[key].touched = true;
    formData[key].valid = value.length > 0;

    this.setState({formData: formData});
  };

  submit = (event) => {
    event.preventDefault();
    this.props.saveHandler(this.state.formData);
  };

  render() {
    let loading = null;
    let error = null;

    // handle loading state
    if (this.props.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;

    // handle error
    } else if (this.props.error) {
      error = <ErrorAlert error={this.props.error}/>;
    }

    // if we initiated a save request and the post request is done and it did not return an error
    if (this.props.loading === false && this.props.error === null && this.state.saving === true) {
      // clear the saving status
      this.setState({saving: false});
      // close the modal
      this.close();
    }

    let placeholder;
    switch (this.state.formData.type.value) {
      case 'intake':
        placeholder = 'Apply to a Communitech Program';
        break;
      case 'survey':
        placeholder = 'October 2018 to December 2018 Startup Survey';
        break;
      case 'request':
        placeholder = 'Data Growth Coaching Request';
        break;
      default:
        throw new Error('Invalid survey type: ' + this.state.formData.type.value);
    }

    return (
      <div>
        <Modal isOpen={true} toggle={this.props.closeHandler} className={this.props.className} size="lg">
          <ModalHeader toggle={this.props.closeHandler}>Create Survey</ModalHeader>
          <ModalBody>
            {error}
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label>What type of survey do you want to create?</Label>
                <Row>
                  <Col md={4}>
                    <FormGroup check>
                      <Input id="type-intake"
                             onChange={e => this.changeHandler('type', 'intake')}
                             type="radio"
                             value="intake"
                             checked={this.state.formData.type.value === 'intake'}/>
                      <Label check for="type-intake">Intake Survey</Label>
                      <FormText color="muted">
                        Use this survey for program intake (i.e. when a company applies to a program)
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup check>
                      <Input id="type-survey"
                             onChange={e => this.changeHandler('type', 'survey')}
                             type="radio"
                             checked={this.state.formData.type.value === 'survey'}
                             value="survey"/>
                      <Label check for="type-survey">Company Survey</Label>
                      <FormText color="muted">This survey is used to collect company data on their progress (i.e. traction) and a survey will automatically be sent to the companies when published.
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup check>
                      <Input id="type-request"
                             onChange={e => this.changeHandler('type', 'request')}
                             type="radio"
                             value="request"
                             checked={this.state.formData.type.value === 'request'}/>
                      <Label check for="type-request">Request Form</Label>
                      <FormText color="muted">
                        This survey is gathering data from companies for a request (i.e. Cloud credits, Passport
                        Program, Data Growth Coaching request etc.) This survey form does not require email
                        confirmation if they are an existing company.
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text"
                       onChange={(e) => this.changeHandler('name', e.target.value)}
                       id="name"
                       invalid={this.state.formData.name.touched && !this.state.formData.name.valid}
                       placeholder={placeholder}
                       valid={this.state.formData.name.touched && this.state.formData.name.valid}/>
                <FormText color="muted">
                  The name will be displayed at the top of the survey.
                </FormText>
              </FormGroup>

            </Form>
          </ModalBody>
          <ModalFooter>
            {loading}
            <Button color="secondary" onClick={this.props.closeHandler}>Cancel</Button>
            <Button color="primary" disabled={this.props.loading || !this.state.formData.name.valid} onClick={() => this.props.saveHandler(this.state.formData)}>Save and continue</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.surveysPost.data,
    error: state.surveysPost.error,
    loading: state.surveysPost.loading
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     usersPost: (data) => dispatch(actions.usersPost(data))
//   };
// };

SurveyCreate.defaultProps = {
  type: 'intake'
};

SurveyCreate.propTypes = {
  type: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(SurveyCreate);


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const submissionsFeedbackPostStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const submissionsFeedbackPostSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const submissionsFeedbackPostFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMISSIONS_FEEDBACK_POST_START: return submissionsFeedbackPostStart(state, action);
    case actionTypes.SUBMISSIONS_FEEDBACK_POST_SUCCESS: return submissionsFeedbackPostSuccess(state, action);
    case actionTypes.SUBMISSIONS_FEEDBACK_POST_FAILURE: return submissionsFeedbackPostFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
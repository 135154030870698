import axios from '../../axios';

import * as actionTypes from './actionTypes';

// POST /surveys/1/recipients
export const surveysRecipientsDelete = (surveyId, recipientId, successCallback) => {
  return dispatch => {
    dispatch(surveysRecipientsDeleteStart());

    axios.delete(`/surveys/${surveyId}/recipients/${recipientId}`)
      .then(response => {
        dispatch(surveysRecipientsDeleteSuccess(response.data.data));
        dispatch(surveysRecipientsList());
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysRecipientsDeleteFailure', error);
        dispatch(surveysRecipientsDeleteFailure(error));
      });
  };
};

export const surveysRecipientsDeleteStart = () => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_DELETE_START
  };
};

export const surveysRecipientsDeleteSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_DELETE_SUCCESS,
    data: data
  };
};

export const surveysRecipientsDeleteFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_DELETE_FAILURE,
    error: error
  };
};

export const surveysRecipientsList = (surveyId, params) => {
  return dispatch => {
    dispatch(surveysRecipientsListStart());

    axios.get(`/surveys/${surveyId}/recipients`, {params: params})
      .then(response => {
        dispatch(surveysRecipientsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('surveysRecipientsListFailure', error);
        dispatch(surveysRecipientsListFailure(error));
      });
  };
};

export const surveysRecipientsListStart = () => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_LIST_START
  };
};

export const surveysRecipientsListSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_LIST_SUCCESS,
    data: data
  };
};

export const surveysRecipientsListFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_LIST_FAILURE,
    error: error
  };
};

// POST /surveys/1/recipients/list
export const surveysRecipientsListPost = (surveyId, data, successCallback) => {
  return dispatch => {
    dispatch(surveysRecipientsListPostStart());

    data = {
      data: {
        type: 'survey-recipients-list',
        id: null,
        attributes: data
      }
    };

    axios.post(`/surveys/${surveyId}/recipients/list`, data)
      .then(response => {
        dispatch(surveysRecipientsListPostSuccess(response.data.data));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysRecipientsListPostFailure', error);
        dispatch(surveysRecipientsListPostFailure(error));
      });
  };
};

export const surveysRecipientsListPostStart = () => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_LIST_POST_START
  };
};

export const surveysRecipientsListPostSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_LIST_POST_SUCCESS,
    data: data
  };
};

export const surveysRecipientsListPostFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_RECIPIENTS_LIST_POST_FAILURE,
    error: error
  };
};

import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const listsList = (type) => {
  return dispatch => {
    dispatch(listsListStart());

    axios.get('/lists', {params: {type: type}})
      .then(response => {
        dispatch(listsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('listsListFailure', error);
        dispatch(listsListFailure(error));
      });
  };
};

export const listsListStart = () => {
  return {
    type: actionTypes.LISTS_LIST_START
  };
};

export const listsListSuccess = (data) => {
  return {
    type: actionTypes.LISTS_LIST_SUCCESS,
    data: data
  };
};

export const listsListFailure = (error) => {
  return {
    type: actionTypes.LISTS_LIST_FAILURE,
    error: error
  };
};


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const surveysRecipientsListPostStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    data: []
  });
};

const surveysRecipientsListPostSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysRecipientsListPostFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_RECIPIENTS_LIST_POST_START: return surveysRecipientsListPostStart(state, action);
    case actionTypes.SURVEYS_RECIPIENTS_LIST_POST_SUCCESS: return surveysRecipientsListPostSuccess(state, action);
    case actionTypes.SURVEYS_RECIPIENTS_LIST_POST_FAILURE: return surveysRecipientsListPostFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
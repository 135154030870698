import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const mailEventsList = (filter) => {
  return dispatch => {
    dispatch(mailEventsListStart());

    axios.get('/mail-events', {params: filter})
      .then(response => {
        dispatch(mailEventsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('mailEventsListFailure', error);
        dispatch(mailEventsListFailure(error));
      });
  };
};

export const mailEventsListStart = () => {
  return {
    type: actionTypes.MAIL_EVENTS_LIST_START
  };
};

export const mailEventsListSuccess = (data) => {
  return {
    type: actionTypes.MAIL_EVENTS_LIST_SUCCESS,
    data: data
  };
};

export const mailEventsListFailure = (error) => {
  return {
    type: actionTypes.MAIL_EVENTS_LIST_FAILURE,
    error: error
  };
};


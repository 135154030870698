import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysFieldsGetStart = ( state, action ) => {
  return updateObject(state,
    {
      error: null,
      loading: true,
      data: {}
    }
  );
};

const surveysFieldsGetSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysFieldsGetFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_FIELDS_GET_START: return surveysFieldsGetStart(state, action);
    case actionTypes.SURVEYS_FIELDS_GET_SUCCESS: return surveysFieldsGetSuccess(state, action);
    case actionTypes.SURVEYS_FIELDS_GET_FAILURE: return surveysFieldsGetFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
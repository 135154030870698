import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../store/actions';
import {Alert, Button, Card, FormGroup, Input, Label} from 'reactstrap';
import './Auth.css';

class Auth extends Component
{

  state = {
    formData: {
      email: {touched: false, valid: true, value: ''},
      password: {touched: false, valid: false, value: ''},
    },
    formIsValid: false
  };

  loginSuccess = (googleUser) => {
    // we have successfully logged in with Google and should have an ID token from Google
    // we now need to authenticate (or reauthenticate) with our API using the ID token.
    console.log('User has google auth', googleUser.profileObj.email);
    console.log('Response from Google', googleUser);
    this.setState({
      googleUser: googleUser,
    });
    this.accessToken = googleUser.tokenObj.access_token;
    //this.setRefreshTimeout(googleUser.tokenObj.expires_at);
    this.setRefreshTimeout(1554141630);
    console.log('Using Google Token ID to create session on API.');
    this.props.onAuth(googleUser.tokenId);

  };

  loginFailure = (response) => {
    // this.setState({
    //   googleUser: googleUser,
    // });
    // this.accessToken = googleUser.tokenObj.access_token;
    // //this.setRefreshTimeout(googleUser.tokenObj.expires_at);
    // this.setRefreshTimeout(1554141630);
    console.log('ERROR', response);
  };

  // Keeping the token out of "state" means that the automatic refresh of an
  // access token will not cause the entire application's component to re-render.
  tokenGetter = () => {
    return this.accessToken;
  };

  // setRefreshTimeout = (expiresAt) => {
  //   // Either 5 minutes before the deadline, or 5 minutes from now. This
  //   // should prevent thrashing while keeping the credentials fresh.
  //   const oneMin = 60 * 1000;
  //   var refreshDeadline =  Math.max(
  //     5*oneMin,
  //     expiresAt - Date.now() - (5*oneMin));
  //   console.log("Refreshing credentials in "
  //     + Math.floor(refreshDeadline/oneMin).toString()
  //     + " minutes");
  //   setTimeout(this.reloadAuthToken, refreshDeadline);
  // };

  changeHandler = (key, value) => {
    const formData = {...this.state.formData};
    formData[key].value = value;
    formData[key].touched = true;
    formData[key].valid = String(formData[key].value).length > 0;


    this.setState({formData: formData});
  };

  reloadAuthToken = () => {
    this.state.googleUser.reloadAuthResponse().then(
      // success handler.
      (authResponse) => {
        console.log('ReloadedAuthToken')
        console.log(new Date(), authResponse);
        // The GoogleUser is mutated in-place, this callback updates component state.
        this.accessToken = authResponse.access_token;
        this.setRefreshTimeout(authResponse.expires_at);
        this.props.onAuth(authResponse.id_token);
      },
      // fail handler.
      (failResponse) => {
        this.accessToken = "";
        console.log("Could not refresh token");
        console.log(failResponse);
      }
    );
  };

  saveHandler = (data) => {

    data = {
      email: data.email.value,
      password: data.password.value,
    };

    this.props.onAuth(data, () => console.log('auth success'));

  };


  render() {
    // get the users original destination path before auth captured them
    let { from } = this.props.location.state || { from: { pathname: "/" } };

    // if we are not logged in (according to our API) then redirect to the users original destination
    if (this.props.isAuthenticated) {
      console.log('Redirecting to ', from.pathname);
      return <Redirect to={from} />;
    }

    return (
      <section className="auth d-flex h-100">
        <div className="m-auto col-md-6">
          <Card body className="text-center">
            <h1>Communitech Forms</h1>
            {this.props.error && <Alert color={'danger'}>{this.props.error}</Alert>}
            <div className="m-auto col-md-6">
              {/*<GoogleLogin*/}
              {/*  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}*/}
              {/*  buttonText="Sign in with Google"*/}
              {/*  onSuccess={this.loginSuccess}*/}
              {/*  onFailure={this.loginFailure}*/}
              {/*  isSignedIn={true}*/}
              {/*/>*/}
              <FormGroup>
                <Label for="label-email">Email</Label>
                <Input id="label-email"
                       invalid={this.state.formData.email.touched && !this.state.formData.email.valid}
                       onChange={(e) => this.changeHandler('email', e.target.value)}
                       placeholder=""
                       type="email"
                       value={this.state.formData.email.value}
                       valid={this.state.formData.email.touched && this.state.formData.email.valid}/>
              </FormGroup>
              <FormGroup>
                <Label for="label-password">Password</Label>
                <Input id="label-password"
                       invalid={this.state.formData.password.touched && !this.state.formData.password.valid}
                       onChange={(e) => this.changeHandler('password', e.target.value)}
                       type="password"
                       value={this.state.formData.password.value}
                       valid={this.state.formData.password.touched && this.state.formData.password.valid}/>
              </FormGroup>
              <Button color="primary"
                      //disabled={!this.state.formIsValid}
                      onClick={() => this.saveHandler(this.state.formData)}>Sign in</Button>{' '}
            </div>
          </Card>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (data) => dispatch(actions.auth(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);

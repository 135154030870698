import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const submissionsResponsesListStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const submissionsResponsesListSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const submissionsResponsesListFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMISSIONS_RESPONSES_LIST_START: return submissionsResponsesListStart(state, action);
    case actionTypes.SUBMISSIONS_RESPONSES_LIST_SUCCESS: return submissionsResponsesListSuccess(state, action);
    case actionTypes.SUBMISSIONS_RESPONSES_LIST_FAILURE: return submissionsResponsesListFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_MANAGE_API_BASE_URL,
    // auth: {
    //     username: '',
    //     password: ''
    // },
    withCredentials: true
});

export default instance;
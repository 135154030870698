import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: {},
  error: null,
  loading: false  
};

const surveysFieldsPutStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const surveysFieldsPutSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const surveysFieldsPutFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SURVEYS_FIELDS_PUT_START: return surveysFieldsPutStart(state, action);
    case actionTypes.SURVEYS_FIELDS_PUT_SUCCESS: return surveysFieldsPutSuccess(state, action);
    case actionTypes.SURVEYS_FIELDS_PUT_FAILURE: return surveysFieldsPutFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React from 'react';
import {Input, FormGroup, Label, FormText } from 'reactstrap';
import PreQuestionLabel from './Fields/PreQuestionLabel';
import PreQuestionHelpText from './Fields/PreQuestionHelpText';
import StorageType from './Fields/StorageType';
import Required from './Fields/Required';
import { dataTypes } from '../../../../types/dataTypes';
import { fieldTypes } from '../../../../types/fieldTypes';
import MergeTagsHelp from '../../../common/MergeTagsHelp';

const formSalesforce = (props) => {

  const dataTypesFiltered = Object.keys(dataTypes)
    .filter(key => props.surveyType !== 'survey' || key !== 'company-profile-name')
    .reduce((res, key) => {
      res[key] = dataTypes[key];
      return res;
    }, {});
  const dataTypeOptions = Object.keys(dataTypesFiltered).map(key => (
    <option key={key} value={key}>{dataTypes[key]}</option>
  ));

  let fieldTypeOptions = null;

  // if (props.field.dataType.value === 'tag') {
  //   fieldTypes['tag'] = props.lists.structuredTags;
  //   // fieldTypeOptions = Object.keys(props.lists.structuredTags).map(key => (
  //   //   <option key={key} value={props.lists.structuredTags[key]}>{props.lists.structuredTags[key].label}</option>
  //   // ));
  // }

  if (props.field.dataType.value && fieldTypes[props.field.dataType.value] !== undefined) {
    fieldTypeOptions = Object.keys(fieldTypes[props.field.dataType.value]).map(key => (
      <option key={key} value={key}>{fieldTypes[props.field.dataType.value][key]}</option>
    ));
  }

  const preQuestionFields = (
    <div>
      <PreQuestionLabel field={props.field} changeHandler={props.changeHandler}/>
      <PreQuestionHelpText field={props.field} changeHandler={props.changeHandler}/>
    </div>
  );

  //console.log('props.field', props.field);
  return (
    <section>
      <StorageType field={props.field} changeHandler={props.changeHandler} surveyType={props.surveyType}/>

      <FormGroup>
        <Label for="dataType">Salesforce Object</Label>
        <Input id="dataType"
               onChange={e => props.changeHandler('dataType', e.target.value)}
               type="select"
               value={props.field.dataType.value}>
          <option value="" disabled>Choose a Salesforce object</option>
          {dataTypeOptions}
        </Input>
        <FormText color="muted">
          Which Salesforce object should this field be stored?
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="fieldType">Salesforce Field</Label>
        {
          typeof fieldTypes[props.field.dataType.value] !== 'undefined'
          ? <Input id="fieldType"
                   onChange={e => props.changeHandler('fieldType', e.target.value)}
                   type="select"
                   disabled={!props.field.dataType.value}
                   value={props.field.fieldType.value}>
              {
                props.field.dataType.value
                ? <option value="" disabled>Choose a field type</option>
                : <option value="" disabled>Choose a Salesforce object first</option>
              }
              {fieldTypeOptions}
            </Input>
          : <Input disabled={true} value={props.field.dataType.value}/>
        }
        <FormText color="muted">
          Which Salesforce field do you want to include?
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="label">Salesforce Field Name</Label>
        <Input id="key"
               onChange={e => props.changeHandler('fieldKey', e.target.value)}
               type="text"
               readOnly
               value={props.field.fieldKey.value}/>
        <FormText color="muted">
          This is defined in Salesforce and is only displayed here to make sure you know which field will be updated
          in Salesforce.
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="label">Question</Label>
        <Input id="label"
               onChange={e => props.changeHandler('fieldLabel', e.target.value)}
               placeholder="What are your three goals for the next quarter?"
               type="text"
               value={props.field.fieldLabel.value}/>
        <FormText color="muted">
          The question that is displayed to the user. eg. What are your three goals for the next quarter?
          <MergeTagsHelp/>
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="helpText">Help text <span className="text-muted">(optional)</span></Label>
        <Input id="helpText"
               invalid={props.field.helpText.touched && !props.field.helpText.valid}
               onChange={e => props.changeHandler('helpText', e.target.value)}
               placeholder="Please be descriptive in adding your 3 goals."
               type="textarea"
               valid={props.field.helpText.touched && props.field.helpText.valid}
               value={props.field.helpText.value || ''}/>
        <FormText color="muted">
          A short description that shows under the question. eg. Please be descriptive in adding your 3 goals.
          <MergeTagsHelp/>
        </FormText>
      </FormGroup>

      <Required field={props.field} changeHandler={props.changeHandler}/>


      {!props.field.validation.required.value ? preQuestionFields : null}


    </section>

  );
};

export default formSalesforce;

const recipientStatusTypes = {
  pending: {
    title: 'Pending',
    helpText: 'Survey has NOT been sent yet. Status will change to queued once the survey has been marked for sending. '
  },
  queued: {
    title: 'Queued',
    helpText: 'In the mailing queue waiting to be sent out.'
  },
  sent: {
    title: 'Sent',
    helpText: 'Survey has been sent to the recipient. Check the mail events log for further details.'
  },
  completed: {
    title: 'Completed',
    helpText: 'Survey has been completed by the recipient'
  },
  failed: {
    title: 'Failed',
    helpText: 'We attempted to send the survey to the recipient but something failed. Check the mail events log for further details.'
  }
};


export default recipientStatusTypes;
import React from 'react';
import {Input, FormGroup, Label, FormText} from 'reactstrap';
import StorageType from './Fields/StorageType';
import MergeTagsHelp from '../../../common/MergeTagsHelp';

const dataTypes = {
  'heading': 'Section Heading'
};

const formDisplay = (props) => {

  const dataTypeOptions = Object.keys(dataTypes).map(key => (
    <option key={key} value={key}>{dataTypes[key]}</option>
  ));

  return (
    <section>
      <StorageType field={props.field} changeHandler={props.changeHandler} surveyType={props.surveyType}/>
      <FormGroup>
        <Label for="dataType">Display Element</Label>
        <Input id="dataType"
               invalid={props.field.dataType.touched && !props.field.dataType.valid}
               onChange={e => props.changeHandler('dataType', e.target.value)}
               type="select"
               valid={props.field.dataType.touched && props.field.dataType.valid}
               value={props.field.dataType.value}>
          <option value="" disabled>Choose a display element type</option>
          {dataTypeOptions}
        </Input>
        <FormText color="muted">
          What type of element do you want to display on the form?
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="label">Text</Label>
        <Input id="label"
               invalid={props.field.fieldLabel.touched && !props.field.fieldLabel.valid}
               onChange={e => props.changeHandler('fieldLabel', e.target.value)}
               placeholder="A title perhaps?"
               valid={props.field.fieldLabel.touched && props.field.fieldLabel.valid}
               value={props.field.fieldLabel.value}/>
        <FormText color="muted">
          Text to display for this element.
          <MergeTagsHelp/>
        </FormText>
      </FormGroup>

      <FormGroup>
        <Label for="helpText">Subtext <span className="text-muted">(optional)</span></Label>
        <Input id="helpText"
               invalid={props.field.helpText.touched && !props.field.helpText.valid}
               onChange={e => props.changeHandler('helpText', e.target.value)}
               placeholder="This is going to be a very fun section to complete!"
               type="textarea"
               valid={props.field.helpText.touched && props.field.helpText.valid}
               value={props.field.helpText.value || ''}/>
        <FormText color="muted">
          Additional text that will appear underneath the display element.
          <MergeTagsHelp/>
        </FormText>
      </FormGroup>
    </section>
  );
};

export default formDisplay;
import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from 'reactstrap';
import storageTypes from '../../../types/storageTypes';
import { isEmpty } from 'lodash';

const responseRow = (props) => {

  let value = props.response.fieldValue;
  const surveyField = props.response.surveyField;
  const fieldType = props.response.surveyField.fieldType;

  if (typeof value === 'undefined' || value === null) {
    value = '';
  }

  const htmlContentValue = () => {
    return {__html: value};
  };

  //console.log('VALUE', value, ' type: ', surveyField);
  if (typeof value === 'object') {
    const valueItems = Object.keys(value).map(index => {
        // if value is not an array then
        if (value[index].length === undefined) {
          return (
            <p key={index}>
              {
                fieldType !== 'attachment'
                  ? Object.keys(value[index]).map(key => (
                      <span key={key}><strong>{key}:</strong> {value[index][key]}<br/></span>
                    ))
                  : <span>
                    <strong>File name:</strong> {value[index].fileName}<br/>
                    <strong>Content type:</strong> {value[index].contentType}<br/>
                    <Button size="sm"
                            outline
                            target="_blank"
                            href={`${process.env.REACT_APP_MANAGE_API_BASE_URL}/attachments/${value[index].fileId}`}>Download</Button>
                  </span>
              }
            </p>
          )
        } else {
          //const options = surveyField.options;
          // return <span key={index}>{options[value[index]]}<br/></span>
          return <span key={index}>{value[index]}<br/></span>
        }
      });
    value = valueItems;
  } else if (surveyField && surveyField.dataType === 'tag' && value === true) {
    value = <strong>Yes</strong>;
  } else if (surveyField && surveyField.dataType === 'tag' && isEmpty(value)) {
    value = 'No';
  } else if (surveyField && surveyField.fieldType === 'checkbox' && value === true) {
    value = <strong>Yes</strong>
  } else if (surveyField && surveyField.fieldType === 'checkbox' && isEmpty(value)) {
    value = 'No';
  } else if (surveyField &&  surveyField.dataType === 'cdmn' && surveyField.fieldType === 'connectingAccount') {
    value = props.lists.attributes.cdmnHubs[value];
  } else if (surveyField && surveyField.fieldType === 'select-radio') {
    // value is already displayable
  } else if (surveyField && surveyField.fieldType === 'textarea') {
    value = <section dangerouslySetInnerHTML={htmlContentValue()}/>
  }


  let title = surveyField ? surveyField.fieldLabel : props.response.fieldKey;
  if (surveyField && surveyField.dataType === 'heading') {
    title = <h4>{surveyField.fieldLabel}</h4>;
    value = null;
  }

  switch (title) {
    case 'traction-employment':
      title = 'Traction - Employment';
      break;
    case 'traction-investment':
      title = 'Traction - Investment';
      break;
    case 'traction-ip':
      title = 'Traction - IP';
      break;
    case 'traction-revenue':
      title = 'Traction - Revenue';
      break;
    case 'traction-stage':
      title = 'Traction - Stage';
      break;
    default:
  }

  return (
    <tr>
      <td>
        {!surveyField ? <Badge color="danger" title="Could not look up related survey field">Invalid Field</Badge> : null}
        {
          props.response.surveyField
            ? <Badge color={storageTypes[props.response.surveyField.storageType].color}
            title={props.response.fieldKey}>{storageTypes[props.response.surveyField.storageType].label}</Badge>
            : null
        }
      </td>
      <td>{title}</td>
      <td>
        {value}
      </td>
    </tr>
  );
};

responseRow.propTypes = {
  response: PropTypes.object.isRequired
};

export default responseRow;
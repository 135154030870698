import React, {Component} from 'react';
import {Button, UncontrolledTooltip} from 'reactstrap';
import PropTypes from 'prop-types';
import { MdDelete, MdEdit } from 'react-icons/md';
import moment from 'moment';
import { Draggable } from 'react-beautiful-dnd';

class BucketListItem extends Component {
  render () {
    return (
      <Draggable draggableId={'bucket-' + this.props.id} index={this.props.index}>
        {(provided) => (
          <tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <td>
              {this.props.name}
            </td>
            <td>
              {this.props.labelPresentTense}
            </td>
            <td>
              {this.props.labelPastTense}
            </td>
            <td>
              <span id={'created' + this.props.id}>{moment.utc(this.props.created).local().fromNow()}</span>
              <UncontrolledTooltip placement="right" target={'created' + this.props.id}>
                {moment.utc(this.props.created).local().format('lll')}
              </UncontrolledTooltip>
            </td>
            <td>
              <span id={'modified' + this.props.id}>{moment.utc(this.props.modified).local().fromNow()}</span>
              <UncontrolledTooltip placement="right" target={'modified' + this.props.id}>
                {moment.utc(this.props.modified).local().format('lll')}
              </UncontrolledTooltip>
            </td>
            <td>{this.props.user ? <span>{this.props.user.firstName} {this.props.user.lastName}</span> : 'Unknown'}</td>
            <td>
              <Button outline size="sm" color="primary"
                      onClick={() => this.props.toggleEditModalHandler(this.props)}><MdEdit/></Button>{' '}
              <Button color="danger" size="sm"
                      onClick={() => this.props.toggleDeleteModalHandler(this.props)} outline><MdDelete/></Button>
            </td>
          </tr>
        )}
      </Draggable>
    );
  }
}

BucketListItem.propTypes = {
  created: PropTypes.string.isRequired,
  description: PropTypes.string,
  labelPastTense: PropTypes.string.isRequired,
  labelPresentTense: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  modified: PropTypes.string.isRequired,
  toggleDeleteModalHandler: PropTypes.func.isRequired,
  toggleEditModalHandler: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default BucketListItem;

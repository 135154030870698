import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const surveysDelete = (surveyId, successCallback) => {
  return dispatch => {
    dispatch(surveysDeleteStart());

    axios.delete('/surveys/' + surveyId)
      .then(response => {
        dispatch(surveysDeleteSuccess(response.data.data));
        // since patch returns a complete survey object we may as well update the ui
        //dispatch(surveysGetSuccess(response.data.data));

        if (successCallback !== undefined) {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysDeleteFailure', error);
        dispatch(surveysDeleteFailure(error));
      });
  };
};

export const surveysDeleteStart = () => {
  return {
    type: actionTypes.SURVEYS_DELETE_START
  };
};

export const surveysDeleteSuccess = (data, request) => {
  return {
    type: actionTypes.SURVEYS_DELETE_SUCCESS,
    data: data,
    request: request
  };
};

export const surveysDeleteFailure = (error, request) => {
  return {
    type: actionTypes.SURVEYS_DELETE_FAILURE,
    error: error,
    request: request
  };
};

export const surveysList = (type) => {
  return dispatch => {
    dispatch(surveysListStart());

    axios.get('/surveys', {params: {type: type}})
      .then(response => {
        dispatch(surveysListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('surveysListFailure', error);
        dispatch(surveysListFailure(error));
      });
  };
};

export const surveysListStart = () => {
  return {
    type: actionTypes.SURVEYS_LIST_START
  };
};

export const surveysListSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_LIST_SUCCESS,
    data: data
  };
};

export const surveysListFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_LIST_FAILURE,
    error: error
  };
};

export const surveysGet = (surveyId) => {
  return dispatch => {
    dispatch(surveysGetStart());

    axios.get('/surveys/' + surveyId)
      .then(response => {
        dispatch(surveysGetSuccess(response.data.data));
      })
      .catch(error => {
        console.error('surveysGetFailure', error);
        dispatch(surveysGetFailure(error));
      });
  };
};

export const surveysGetStart = () => {
  return {
    type: actionTypes.SURVEYS_GET_START
  };
};

export const surveysGetSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_GET_SUCCESS,
    data: data
  };
};

export const surveysGetFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_GET_FAILURE,
    error: error
  };
};

export const surveysPatch = (surveyId, data, successCallback) => {
  return dispatch => {
    dispatch(surveysPatchStart());
    
    // put data into correct format
    data = {
      data: {
        type: 'survey',
        id: surveyId,
        attributes: data
      }
    };
    
    axios.patch('/surveys/' + surveyId, data)
      .then(response => {
        dispatch(surveysPatchSuccess(response.data.data, data.data.attributes));
        // since patch returns a complete survey object we may as well update the ui
        dispatch(surveysGetSuccess(response.data.data));

        if (successCallback !== undefined) {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysPatchFailure', error);
        dispatch(surveysPatchFailure(error, data.data.attributes));
      });
  };
};

export const surveysPatchStart = () => {
  return {
    type: actionTypes.SURVEYS_PATCH_START
  };
};

export const surveysPatchSuccess = (data, request) => {
  return {
    type: actionTypes.SURVEYS_PATCH_SUCCESS,
    data: data,
    request: request
  };
};

export const surveysPatchFailure = (error, request) => {
  return {
    type: actionTypes.SURVEYS_PATCH_FAILURE,
    error: error,
    request: request
  };
};

export const surveysPost = (data) => {
  return dispatch => {
    dispatch(surveysPostStart());

    // put data into correct format
    data = {
      data: {
        type: 'survey',
        id: null,
        attributes: data
      }
    };

    axios.post('/surveys', data)
      .then(response => {
        dispatch(surveysPostSuccess(response.data.data));
        // since post returns a complete survey object we may as well update the ui
        dispatch(surveysGetSuccess(response.data.data));
      })
      .catch(error => {
        console.error('surveysPostFailure', error);
        dispatch(surveysPostFailure(error));
      });
  };
};

export const surveysPostStart = () => {
  return {
    type: actionTypes.SURVEYS_POST_START
  };
};

export const surveysPostSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_POST_SUCCESS,
    data: data
  };
};

export const surveysPostFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_POST_FAILURE,
    error: error
  };
};

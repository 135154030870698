import React from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {MdOpenInNew} from 'react-icons/md';

const surveyListItemSurvey = (props) => {
  return (
    <tr className="survey-list__item">

      <td>{props.name}</td>
      <td>
        {props.sentFlag === true
          ? <React.Fragment>
              <Badge id={`sentDate-${props.id}`} color="success">Sent</Badge>
              <UncontrolledTooltip placement="right" target={`sentDate-${props.id}`}>
                {moment.utc(props.sentDate).local().fromNow()} on {moment.utc(props.sentDate).local().format('lll')}
              </UncontrolledTooltip>
            </React.Fragment>
          : <Badge color="secondary">Not Sent</Badge>}
        {props.expires !== null && moment.utc(props.expires) <= moment.utc()
          ? <React.Fragment>
            <Badge color="info" id={`expires-${props.id}`}>Expired</Badge>
            <UncontrolledTooltip placement="right" target={`expires-${props.id}`}>
              {moment.utc(props.expires).local().format('lll')}
            </UncontrolledTooltip>
          </React.Fragment>
          : null
        }
      </td>
      <td>
        <span id={`created-${props.id}`}>{moment.utc(props.created).local().fromNow()}</span>
        <UncontrolledTooltip placement="right" target={`created-${props.id}`}>
          {moment.utc(props.created).local().format('lll')}
        </UncontrolledTooltip>
      </td>
      <td>
        <span id={`modified-${props.id}`}>{moment.utc(props.modified).local().fromNow()}</span>
        <UncontrolledTooltip placement="right" target={`modified-${props.id}`}>
          {moment.utc(props.modified).local().format('lll')}
        </UncontrolledTooltip>
      </td>
      <td>
        <span id={`created-user-${props.id}`}>{props.userModified.firstName} {props.userModified.lastName}</span>
        <UncontrolledTooltip placement="right" target={`created-user-${props.id}`}>
          Created by: <strong>{props.user.firstName} {props.user.lastName}</strong>
        </UncontrolledTooltip>
      </td>
      <td>
        <UncontrolledDropdown>
          <DropdownToggle caret size="sm" outline>
            Actions
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag={Link} to={`/surveys/${props.id}/manage`}>Manage/Edit</DropdownItem>
            <DropdownItem target="_blank" href={`${props.url}/preview`}>Preview <MdOpenInNew/></DropdownItem>
            <DropdownItem tag={Link} to={`/surveys/${props.id}/submissions`}>View Submissions</DropdownItem>
            <DropdownItem tag={Link} to={`/surveys/${props.id}/recipients`}>View Recipients</DropdownItem>
            <DropdownItem onClick={() => props.deleteHandler(props.id, props.name)}>Delete</DropdownItem>
            <DropdownItem onClick={() => props.cloneHandler(props.id)}>Clone</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};
surveyListItemSurvey.defaultProps = {
  expires: null
};
surveyListItemSurvey.propTypes = {
  cloneHandler: PropTypes.func,
  created: PropTypes.string.isRequired,
  deleteHandler: PropTypes.func,
  expires: PropTypes.string,
  //fundingBucket: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  //message: PropTypes.object
  modified: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  //notify: PropTypes.object
  //periodEnd: PropTypes.object
  //periodStart: PropTypes.object
  //primaryProgram: PropTypes.object
  sentDate: PropTypes.string,
  sentFlag: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  //url: PropTypes.object
  user: PropTypes.object.isRequired,
  userModified: PropTypes.object.isRequired
};

export default surveyListItemSurvey;


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const submissionsFeedbackPatchStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const submissionsFeedbackPatchSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const submissionsFeedbackPatchFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMISSIONS_FEEDBACK_PATCH_START: return submissionsFeedbackPatchStart(state, action);
    case actionTypes.SUBMISSIONS_FEEDBACK_PATCH_SUCCESS: return submissionsFeedbackPatchSuccess(state, action);
    case actionTypes.SUBMISSIONS_FEEDBACK_PATCH_FAILURE: return submissionsFeedbackPatchFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React  from 'react';
import {Badge, Button, UncontrolledTooltip} from 'reactstrap';
import PropTypes from 'prop-types';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';

const userListItem = (props) => {
  return (
    <tr>
      <td>{props.firstName} {props.lastName}</td>
      <td>{props.email}</td>
      <td>
        {props.googleUserId
          ? <Badge color="success">Linked</Badge>
          : <Badge color="secondary">Not linked</Badge>}
      </td>
      <td>
        <span id={'created' + props.id}>{moment.utc(props.created).local().fromNow()}</span>
        <UncontrolledTooltip placement="right" target={'created' + props.id}>
          {moment.utc(props.created).local().format('lll')}
        </UncontrolledTooltip>
      </td>
      <td>
        <span id={'modified' + props.id}>{moment.utc(props.modified).local().fromNow()}</span>
        <UncontrolledTooltip placement="right" target={'modified' + props.id}>
          {moment.utc(props.modified).local().format('lll')}
        </UncontrolledTooltip>
      </td>
      <td>{props.user ? <span>{props.user.firstName} {props.user.lastName}</span> : 'Unknown'}</td>
      <td><Button color="danger" size="sm" onClick={() => props.confirmDeleteHandler(props)} outline><MdDelete/></Button></td>
    </tr>
  );
};

userListItem.propTypes = {
  created: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  googleUserId: PropTypes.string,
  id: PropTypes.number.isRequired,
  lastName: PropTypes.string,
  modified: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default userListItem;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import ErrorAlert from '../common/ErrorAlert';
import { isEmpty, cloneDeep } from 'lodash';
import { PropTypes } from 'prop-types';

class BucketProfileEdit extends Component
{
  state = {
    formData: {
      label: {touched: false, valid: true, value: ''},
      bucketIds: {touched: false, valid: false, value: []}
    },
    formIsValid: false
  };

  changeHandler = (key, value, checked) => {
    const formData = {...this.state.formData};
    if (key === 'bucketIds') {
      let x = formData[key].value.indexOf(parseInt(value.replace('bucket-', '')));
      if (x > -1) {
        formData[key].value.splice(x, 1);
      } else {
        // add new value
        formData[key].value.push(parseInt(value.replace('bucket-', '')));
      }

    } else {
      formData[key].value = value;
    }


    formData[key].touched = true;
    formData[key].valid = String(formData[key].value).length > 0;

    if (key === 'label') {
      formData[key].valid = true;
    }

    let formIsValid = true;
    Object.keys(formData).map(key => {
      formData[key].valid = String(formData[key].value).length > 0;
      if (key === 'label') {
        formData[key].valid = true;
      }
      formIsValid = formIsValid && formData[key].valid;
      return null;
    });

    this.setState({formData: formData, formIsValid: formIsValid});
  };

  submit = (event) => {
    event.preventDefault();
    this.props.saveHandler(this.state.formData);
  };

  static getDerivedStateFromProps(props, state) {

    if (!isEmpty(props.bucketProfile) &&
      state.formData['label'].value === '') {

      const bucketProfile = cloneDeep(props.bucketProfile);

      state.formData['label'].value = bucketProfile.label;
      state.formData['bucketIds'].value = bucketProfile.bucketIds;

      return state;
    }
    return null;
  }

  render() {
    let loading = null;
    let error = null;

    const bucketProfileId = typeof this.props.bucketProfile.id !== 'undefined' ? this.props.bucketProfile.id : null;

    // handle loading state
    if (this.props.post.loading || this.props.patch.loading) {
      loading = <Spinner size="sm" color="primary"/>;

    // handle error
    } else if (this.props.post.error || this.props.patch.error) {
      error = <ErrorAlert error={this.props.error}/>;
    }

    // // if we initiated a save request and the post request is done and it did not return an error
    // if (this.props.loading === false && this.props.error === null && this.state.saving === true) {
    //   // clear the saving status
    //   this.setState({saving: false});
    //   // close the modal
    //   this.close();
    // }
//console.log(this.state.formData);
    let buckets = Object.keys(this.props.buckets).map(key => <FormGroup check key={key}>
      <Label check>
        <Input checked={this.state.formData.bucketIds.value.indexOf(parseInt(key.replace('bucket-', ''))) > -1}
               type="checkbox"
               invalid={this.state.formData.bucketIds.touched && !this.state.formData.bucketIds.valid}
               onChange={(e) => this.changeHandler('bucketIds', key, e.target.checked)}
               valid={this.state.formData.bucketIds.touched && this.state.formData.bucketIds.valid}
               alue={key.replace('bucket-')}/>{' '}
        {this.props.buckets[key]}
      </Label>
    </FormGroup>);

    return (
      <div>
        <Modal isOpen={true} toggle={this.props.closeHandler} className={this.props.className}>
          <ModalHeader toggle={this.props.closeHandler}>Buckets</ModalHeader>
          <ModalBody>
            {error}
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="label">Label</Label>
                <Input id="label"
                       invalid={this.state.formData.label.touched && !this.state.formData.label.valid}
                       onChange={(e) => this.changeHandler('label', e.target.value)}
                       placeholder="My Buckets"
                       type="text"
                       value={this.state.formData.label.value}
                       valid={this.state.formData.label.touched && this.state.formData.label.valid}/>
              </FormGroup>
              {buckets}
              <p>&nbsp;</p>
              <p>
                Note: It is highly recommended that you have a "submitted" bucket in your profile or it will be
                impossible to view any new submissions.
              </p>
            </Form>
          </ModalBody>
          <ModalFooter>
            {loading}
            <Button color="secondary" onClick={this.props.closeHandler}>Cancel</Button>
            <Button color="primary"
                    disabled={!this.state.formIsValid}
                    onClick={() => this.props.saveHandler(this.state.formData, bucketProfileId)}>{this.props.bucketProfile ? 'Save' : 'Add'}</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    patch: {
      data: state.bucketProfilesPatch.data,
      error: state.bucketProfilesPatch.error,
      loading: state.bucketProfilesPatch.loading
    },
    post: {
      data: state.bucketProfilesPost.data,
      error: state.bucketProfilesPost.error,
      loading: state.bucketProfilesPost.loading
    }
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     usersPost: (data) => dispatch(actions.usersPost(data))
//   };
// };

BucketProfileEdit.propTypes = {
  bucketProfile: PropTypes.object.isRequired,
  buckets: PropTypes.array.isRequired,
  saveHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired
};

export default connect(mapStateToProps, null)(BucketProfileEdit);


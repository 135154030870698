import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  data: [],
  error: null,
  loading: false  
};

const usersDeleteStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const usersDeleteSuccess = (state, action) => {
  return updateObject( state, {
    data: action.data,
    error: null,
    loading: false
  } );
};

const usersDeleteFailure = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USERS_DELETE_START: return usersDeleteStart(state, action);
    case actionTypes.USERS_DELETE_SUCCESS: return usersDeleteSuccess(state, action);
    case actionTypes.USERS_DELETE_FAILURE: return usersDeleteFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
import React, { Component } from 'react';
import Navigation from '../common/Navigation';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Footer from '../common/Footer';
import BackButton from '../common/BackButton';
import BucketList from './BucketList';
import BucketProfileList from './BucketProfileList';

class Buckets extends Component
{
  state = {};



  render() {



    return (
      <section>
        <Navigation/>
        <Container fluid>
          <BackButton goBack={this.props.history.goBack}/>
          <h1>Bucket Management</h1>
          <BucketList/>
          <BucketProfileList/>
        </Container>
        <Footer/>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buckets);


import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const surveysFieldsGet = (surveyId, fieldId, successCallback) => {
  return dispatch => {
    dispatch(surveysFieldsGetStart());

    axios.get(`/surveys/${surveyId}/fields/${fieldId}`)
      .then(response => {
        dispatch(surveysFieldsGetSuccess(response.data.data));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysFieldsGetFailure', error);
        dispatch(surveysFieldsGetFailure(error));
      });
  };
};

export const surveysFieldsGetStart = () => {
  return {
    type: actionTypes.SURVEYS_FIELDS_GET_START
  };
};

export const surveysFieldsGetSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_GET_SUCCESS,
    data: data
  };
};

export const surveysFieldsGetFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_GET_FAILURE,
    error: error
  };
};

export const surveysFieldsList = (surveyId) => {
  return dispatch => {
    dispatch(surveysFieldsListStart());

    axios.get(`/surveys/${surveyId}/fields`)
      .then(response => {
        dispatch(surveysFieldsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('surveysFieldsListFailure', error);
        dispatch(surveysFieldsListFailure(error));
      });
  };
};

export const surveysFieldsListStart = () => {
  return {
    type: actionTypes.SURVEYS_FIELDS_LIST_START
  };
};

export const surveysFieldsListSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_LIST_SUCCESS,
    data: data
  };
};

export const surveysFieldsListFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_LIST_FAILURE,
    error: error
  };
};

export const surveysFieldsPost = (surveyId, data, successCallback) => {
  return dispatch => {
    dispatch(surveysFieldsPostStart());

    // put data into correct format
    data = {
      data: {
        type: 'survey-field',
        id: null,
        attributes: data
      }
    };

    axios.post(`/surveys/${surveyId}/fields`, data)
      .then(response => {
        dispatch(surveysFieldsPostSuccess(response.data.data));
        //dispatch(surveysFieldsGetSuccess(response.data.data));
        dispatch(surveysFieldsList(surveyId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysFieldsPostFailure', error);
        dispatch(surveysFieldsPostFailure(error));
      });
  };
};

export const surveysFieldsPostStart = () => {
  return {
    type: actionTypes.SURVEYS_FIELDS_POST_START
  };
};

export const surveysFieldsPostSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_POST_SUCCESS,
    data: data
  };
};

export const surveysFieldsPostFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_POST_FAILURE,
    error: error
  };
};

export const surveysFieldsPut = (surveyId, fieldId, data, successCallback) => {
  return dispatch => {
    dispatch(surveysFieldsPutStart());
    
    // put data into correct format
    data = {
      data: {
        type: 'survey-field',
        id: fieldId,
        attributes: data
      }
    };
    
    axios.put(`/surveys/${surveyId}/fields/${fieldId}`, data)
      .then(response => {
        dispatch(surveysFieldsPutSuccess(response.data.data));
        //dispatch(surveysFieldsGetSuccess(response.data.data));
        dispatch(surveysFieldsList(surveyId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysFieldsPutFailure', error);
        dispatch(surveysFieldsPutFailure(error));
      });
  };
};

export const surveysFieldsPutStart = () => {
  return {
    type: actionTypes.SURVEYS_FIELDS_PUT_START
  };
};

export const surveysFieldsPutSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_PUT_SUCCESS,
    data: data
  };
};

export const surveysFieldsPutFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_PUT_FAILURE,
    error: error
  };
};

export const surveysFieldsDelete = (surveyId, fieldId, successCallback) => {
  return dispatch => {
    dispatch(surveysFieldsDeleteStart());
    
    axios.delete(`/surveys/${surveyId}/fields/${fieldId}`)
      .then(response => {
        dispatch(surveysFieldsDeleteSuccess(response.data.data));
        //dispatch(surveysFieldsGetSuccess(response.data.data));
        dispatch(surveysFieldsList(surveyId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('surveysFieldsDeleteFailure', error);
        dispatch(surveysFieldsDeleteFailure(error));
      });
  };
};

export const surveysFieldsDeleteStart = () => {
  return {
    type: actionTypes.SURVEYS_FIELDS_DELETE_START
  };
};

export const surveysFieldsDeleteSuccess = (data) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_DELETE_SUCCESS,
    data: data
  };
};

export const surveysFieldsDeleteFailure = (error) => {
  return {
    type: actionTypes.SURVEYS_FIELDS_DELETE_FAILURE,
    error: error
  };
};



import axios from '../../axios';

import * as actionTypes from './actionTypes';

export const submissionsRatingsList = (submissionId, params) => {
  return dispatch => {
    dispatch(submissionsRatingsListStart());

    axios.get(`/submissions/${submissionId}/ratings`, {params: params})
      .then(response => {
        dispatch(submissionsRatingsListSuccess(response.data.data));
      })
      .catch(error => {
        console.error('submissionsRatingsListFailure', error);
        dispatch(submissionsRatingsListFailure(error));
      });
  };
};

export const submissionsRatingsListStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_RATINGS_LIST_START
  };
};

export const submissionsRatingsListSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_RATINGS_LIST_SUCCESS,
    data: data
  };
};

export const submissionsRatingsListFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_RATINGS_LIST_FAILURE,
    error: error
  };
};

export const submissionsRatingsPost = (submissionId, data, params, successCallback) => {
  return dispatch => {
    dispatch(submissionsRatingsPostStart());
    data = {
      data: {
        attributes: data
      }
    };
    axios.post(`/submissions/${submissionId}/ratings`, data,{params: params})
      .then(response => {
        dispatch(submissionsRatingsPostSuccess(response.data.data));
        dispatch(submissionsRatingsList(submissionId));
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
      })
      .catch(error => {
        console.error('submissionsRatingsPostFailure', error);
        dispatch(submissionsRatingsPostFailure(error));
      });
  };
};

export const submissionsRatingsPostStart = () => {
  return {
    type: actionTypes.SUBMISSIONS_RATINGS_POST_START
  };
};

export const submissionsRatingsPostSuccess = (data) => {
  return {
    type: actionTypes.SUBMISSIONS_RATINGS_POST_SUCCESS,
    data: data
  };
};

export const submissionsRatingsPostFailure = (error) => {
  return {
    type: actionTypes.SUBMISSIONS_RATINGS_POST_FAILURE,
    error: error
  };
};

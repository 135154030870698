import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import ErrorAlert from '../common/ErrorAlert';
import { emailIsValid } from '../../utility';

class UserCreate extends Component
{
  state = {
    formData: {
      email: {touched: false, valid: false, value: ''}
    },
    formIsValid: false
  };

  changeHandler = (key, value) => {
    const formData = {...this.state.formData};
    formData[key].value = value;
    formData[key].touched = true;
    if (key === 'email') {
      formData[key].valid = emailIsValid(value) && (
        value.substr(-11) === '@jtclark.ca' ||
        value.substr(-15) === '@communitech.ca'
      );
    }

    // since this form only has one field
    const formIsValid = formData[key].valid;

    this.setState({formData: formData, formIsValid: formIsValid});
  };

  submit = (event) => {
    event.preventDefault();
    this.props.saveHandler(this.state.formData);
  };

  render() {
    let loading = null;
    let error = null;

    // handle loading state
    if (this.props.loading === true) {
      loading = <Spinner size="sm" color="primary"/>;

    // handle error
    } else if (this.props.error) {
      error = <ErrorAlert error={this.props.error}/>;
    }

    // if we initiated a save request and the post request is done and it did not return an error
    if (this.props.loading === false && this.props.error === null && this.state.saving === true) {
      // clear the saving status
      this.setState({saving: false});
      // close the modal
      this.close();
    }


    return (
      <div>
        <Modal isOpen={true} toggle={this.props.closeHandler} className={this.props.className}>
          <ModalHeader toggle={this.props.closeHandler}>Add User</ModalHeader>
          <ModalBody>
            {error}
            <p>
              Enter the email address for a user that you want to have access to manage Communitech Forms.
            </p>
            <p>The user will receive a notification email with a link to sign in.</p>
            <p>Only @communitech.ca email addresses are allowed.</p>
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input id="email"
                       invalid={this.state.formData.email.touched && !this.state.formData.email.valid}
                       onChange={(e) => this.changeHandler('email', e.target.value)}
                       placeholder="john.smith@communitech.ca"
                       type="email"
                       value={this.state.formData.email.value}
                       valid={this.state.formData.email.touched && this.state.formData.email.valid}/>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            {loading}
            <Button color="secondary" onClick={this.props.closeHandler}>Cancel</Button>
            <Button color="primary"
                    disabled={!this.state.formIsValid}
                    onClick={() => this.props.saveHandler(this.state.formData)}>Add</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.usersPost.data,
    error: state.usersPost.error,
    loading: state.usersPost.loading
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     usersPost: (data) => dispatch(actions.usersPost(data))
//   };
// };

export default connect(mapStateToProps, null)(UserCreate);


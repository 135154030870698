import React, { Component } from 'react';
import Navigation from '../common/Navigation';
import { connect } from 'react-redux';
import { Container, Spinner, Row, Col } from 'reactstrap';
import * as actions from '../../store/actions';
import { isEmpty } from 'lodash';
import FieldList from './SurveyManage/FieldList';
import SurveyInfo from './SurveyManage/SurveyInfo';
import ErrorAlert from '../common/ErrorAlert';
import Actions from './SurveyManage/Actions';
import Recipients from './SurveyManage/Recipients';
import Footer from '../common/Footer';
import BackButton from '../common/BackButton';
import ConfirmModal from '../common/ConfirmModal';

class SurveyManage extends Component
{
  state = {
    showPublishModal: false,
    showSendModal: false,
    showResendModal: false
  };

  componentDidMount() {
    this.props.getSurvey(this.props.match.params.surveyId);
  }

  publishHandler = (unpublish) => {
    const action = typeof unpublish === 'undefined' || unpublish === false ? 'publish' : 'unpublish';
    //console.log(unpublish, action);
    this.props.surveysActionsPost(this.props.match.params.surveyId, {
      action: action
    }, this.togglePublishModal)
  };

  sendHandler = () => {
    this.props.surveysActionsPost(this.props.match.params.surveyId, {
      action: 'send'
    }, this.toggleSendModal)
  };

  resendHandler = () => {
    this.props.surveysActionsPost(this.props.match.params.surveyId, {
      action: 'resend'
    }, this.toggleResendModal)
  };

  togglePublishModal = () => {
    this.setState((prevState) => ({
      showPublishModal: !prevState.showPublishModal
    }));
  };

  toggleSendModal = () => {
    this.setState((prevState) => ({
      showSendModal: !prevState.showSendModal
    }));
  };

  toggleResendModal = () => {
    this.setState((prevState) => ({
      showResendModal: !prevState.showResendModal
    }));
  };

  render() {

    let survey = null;

    // load survey parent data
    // handle loading state
    if (this.props.survey.loading === true) {
      survey = <Spinner size="sm" color="primary"/>;

    // handle error
    } else if (this.props.survey.error) {
      survey = <ErrorAlert error={this.props.survey.error}/>;

    // when we have data
    } else if (!isEmpty(this.props.survey.data)) {
      survey = <SurveyInfo survey={this.props.survey.data.attributes}/>;
    }

    const showIntakePublishConfirmModal = !isEmpty(this.props.survey.data)
      && ( this.props.survey.data.attributes.type === 'intake' || this.props.survey.data.attributes.type === 'request')
      && this.state.showPublishModal;

    const showSurveySendConfirmModal = !isEmpty(this.props.survey.data)
      && this.props.survey.data.attributes.type === 'survey'
      && this.state.showSendModal;

    const showSurveyResendConfirmModal = !isEmpty(this.props.survey.data)
      && this.props.survey.data.attributes.type === 'survey'
      && this.state.showResendModal;

    const surveyData = !isEmpty(this.props.survey.data) ? this.props.survey.data.attributes : {};
    return (
      <section className="survey-edit">
        <Navigation/>
        <Container fluid>
          <BackButton goBack={this.props.history.goBack}/>
          <h1>Manage and Edit Survey</h1>
          <Row>
            <Col md={8}>
              {survey}
            </Col>
            <Col md={4}>
              {
                !isEmpty(this.props.survey.data)
                ? <Actions onPublish={this.togglePublishModal}
                           onSend={this.toggleSendModal}
                           onResend={this.toggleResendModal}
                           survey={this.props.survey.data.attributes}/>
                : null
              }
            </Col>
          </Row>
          {
            isEmpty(this.props.survey.data) || this.props.survey.data.attributes.type !== 'survey'
            ? null
            : <Recipients surveyId={parseInt(this.props.match.params.surveyId)}/>
          }
          <hr/>
          {
            isEmpty(this.props.survey.data)
            ? null
            : <FieldList surveyId={parseInt(this.props.match.params.surveyId)}
                         surveyType={this.props.survey.data.attributes.type}/>
          }

        </Container>
        <Footer/>

        {showIntakePublishConfirmModal ?
          <ConfirmModal title={surveyData.sentFlag ? 'Unpublish' : ' Publish'}
                        buttonConfirmLabel={surveyData.sentFlag ? 'Unpublish' : ' Publish'}
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={() => this.publishHandler(surveyData.sentFlag)}
                        buttonCancelHandler={this.togglePublishModal}
                        loading={this.props.action.loading}
                        error={this.props.action.error}>
            <span>
              Are you sure you want to {surveyData.sentFlag ? 'unpublish' : 'publish'} this form? People will be
              {surveyData.sentFlag ? ' unable' : ' able'} to access this form once you
              {surveyData.sentFlag ? ' unpublish' : ' publish'} it.
            </span>
          </ConfirmModal> : null
        }
        {showSurveySendConfirmModal ?
          <ConfirmModal title="Send Survey"
                        buttonConfirmLabel="Send"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.sendHandler}
                        buttonCancelHandler={this.toggleSendModal}
                        loading={this.props.action.loading}
                        error={this.props.action.error}>
            {
              !this.props.survey.data.attributes.sentFlag
              ? <span>
                  Are you sure you want to send out this survey?<br/><br/>
                  Each person will get a unique, personalized link via email along with your email message.
                </span>
              : <span>
                  Are you sure you want to resend this survey?<br/><br/>
                  The survey will only be resent to companies who have not yet completed the survey.<br/><br/>
                  Each person will get a unique, personalized link via email along with your email message.
                </span>
            }
          </ConfirmModal> : null
        }
        {showSurveyResendConfirmModal ?
          <ConfirmModal title="Send Survey Reminder"
                        buttonConfirmLabel="Send Reminder"
                        buttonCancelLabel="Cancel"
                        buttonConfirmHandler={this.resendHandler}
                        buttonCancelHandler={this.toggleResendModal}
                        loading={this.props.action.loading}
                        error={this.props.action.error}>
           <span>
              Are you sure you want to resend this survey?<br/><br/>
              The survey will only be resent to companies who have not yet completed the survey.<br/><br/>
              Each person will get a unique, personalized link via email along with your email message.
            </span>

          </ConfirmModal> : null
        }
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    survey: {
      data: state.surveysGet.data,
      error: state.surveysGet.error,
      loading: state.surveysGet.loading
    },
    action: {
      data: state.surveysActionsPost.data,
      error: state.surveysActionsPost.error,
      loading: state.surveysActionsPost.loading
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSurvey: (surveyId) => dispatch(actions.surveysGet(surveyId)),
    surveysActionsPost: (surveyId, data, success) => dispatch(actions.surveysActionsPost(surveyId, data, success))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyManage);


import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';

const actions = (props) => {
  return (
    <section>
      <h2>Actions</h2>
      {
        props.survey.type !== 'survey'
          ? <React.Fragment>
              <p>Survey finished? Make the survey live by clicking the Publish button below.</p>
              <Button block
                      color="primary"
                      id="publish"
                      disabled={!props.survey.primaryProgram || !props.survey.fundingBucket}
                      onClick={props.onPublish}>{props.survey.sentFlag ? 'Unpublish' : 'Publish'}</Button>
              {
                !props.survey.primaryProgram || !props.survey.fundingBucket
                ? <span className="text-danger">
                    You must choose a funding bucket and a primary program before publishing.
                  </span>
                : null
              }
            </React.Fragment>
          : null
      }
      {
        props.survey.type === 'survey'
          ? <React.Fragment>
              <p>Ready to send the survey out to all companies? If you have already sent this survey out only recipients added since the last send will be receive the survey.</p>
              <Button block color="primary" onClick={props.onSend}>Send</Button>
            </React.Fragment>
          : null
      }
      <br/>
      {
        props.survey.type === 'survey' && props.survey.sentFlag === true
          ? <React.Fragment>
              <p>
                Are you not getting responses to your survey? Send a reminder to all recipients who have not
                yet completed the survey.
              </p>
              <Button block color="primary" onClick={props.onResend}>Send Reminder</Button>
            </React.Fragment>
          : null
      }
      <br/>
      <p>Not quite ready yet? See what your survey looks like.</p>
      <Button block tag="a" href={`${props.survey.url}/preview`} target="_blank">Preview <MdOpenInNew/></Button>
      <br/>
      <p>See all the wonderful submissions to this form.</p>
      <Button block color="success" tag={Link} to={`/surveys/${props.survey.id}/submissions`}>View Submissions</Button>
      {
        props.survey.type === 'survey'
          ? <React.Fragment>
              <br/>
              <p>See who has received this survey and check on their status.</p>
              <Button block color="warning" tag={Link} to={`/surveys/${props.survey.id}/recipients`}>View Recipients</Button>
            </React.Fragment>
          : null
      }
      {
        props.survey.type === 'intake'
          ? <React.Fragment>
              <br/>
              <p>See which companies have selected a company to continue their intake survey</p>
              <Button block color="warning" tag={Link} to={`/surveys/${props.survey.id}/recipients`}>View Invitations</Button>
            </React.Fragment>
          : null
      }
    </section>
  );
};

actions.propTypes = {
  onPublish: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired
};

export default actions;
